import React from "react";
import BestSeller from "../../containers/products/bestSeller";

const BestsellingPage = () => {
  return (
    <div>
        <BestSeller />
    </div>
  );
};

export default BestsellingPage;

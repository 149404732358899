import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import DashboardLayout from "../../containers/customerDashboard/DashoardLayout";
import Profile from "./profile";
import Orders from "./order";
import Refunds from "./refunds";
import Inbox from "./inbox";
import TrackOrder from "./trackOrder";
import ChangePassword from "./changePassword";
import Address from "./address";

const CustomerDashboard: React.FC = () => {
  const [active, setActive] = React.useState<number>(1);

  return (
    <DashboardLayout>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/refunds" element={<Refunds />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/address" element={<Address />} />
        </Route>
      </Routes>
    </DashboardLayout>
  );
};

export default CustomerDashboard;

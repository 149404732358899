// AttributeList.tsx
import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Person, Edit, Delete } from "@mui/icons-material";
import { ErrorToast } from "../../components/forms/toast";
import {
  useDeleteCategoryQuery,
  useGetCategoryQuery,
} from "../../query/categoryQuery";
import { ApiCategory } from "../../types/category";
import CategoryForm from "./AddCategory";

const CategoryList = () => {
  const { data: categoryData } = useGetCategoryQuery();
  const deleteCategoryMutation = useDeleteCategoryQuery();
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    categoryId: null,
  });

  const [editCategory, setEditCategory] = useState<ApiCategory | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleEdit = (categoryId: number) => {
    if (categoryData) {
      const category = categoryData.find(
        (category) => category.Id === categoryId
      );

      if (category) {
        setEditCategory(category);
        setDialogOpen(true);
      } else {
        console.error(`Category with ID ${categoryId} not found`);
      }
    } else {
      console.error("Category data is undefined");
    }
  };

  const handleDelete = (categoryId: any) => {
    setDeleteConfirmation({ open: true, categoryId });
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation({ open: false, categoryId: null });
  };

  const handleConfirmDelete = async () => {
    try {
      const categoryId = deleteConfirmation.categoryId;
      if (categoryId !== null) {
        await deleteCategoryMutation.mutateAsync(categoryId);
        await deleteCategoryMutation.reset();
      } else {
        console.error("Category ID is null");
      }
    } catch (error) {
      ErrorToast("Error deleting category");
    } finally {
      handleCloseDeleteConfirmation();
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditCategory(null);
  };

  const handleSubmit = (data: ApiCategory) => {
    console.log("Form submitted with data:", data);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <Person sx={{ color: "#D84241" }} />
          Product Categories
        </Typography>
        <Button
          onClick={() => setDialogOpen(true)}
          variant="contained"
          color="error"
          sx={{
            background: "#fce9ec",
            color: "#D84241",
            boxShadow: "none",
            "&:hover": {
              background: "#fef2f2",
              color: "#D84241",
              boxShadow: "none",
            },
          }}
        >
          Add Categories
        </Button>
      </Box>

      <Paper sx={{ boxShadow: "none", marginTop: 2 }}>
        <TableContainer component={Box}>
          <Table sx={{ border: "none" }}>
            <TableHead>
              <TableRow sx={{ border: "none" }}>
                <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoryData &&
                categoryData.map((category) => (
                  <TableRow key={category.Id}>
                    <TableCell>{category.Name}</TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleEdit(category.Id)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(category.Id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={deleteConfirmation.open}
        onClose={handleCloseDeleteConfirmation}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <CategoryForm
        open={isDialogOpen}
        onClose={handleCloseDialog}
        editCategory={editCategory}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CategoryList;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

interface AuthState {
  username: string | null;
  isAuthenticated: boolean;
  userId: string | null;
}

const storedAuthData = localStorage.getItem("authData");

const initialState: AuthState = {
  isAuthenticated: storedAuthData ? true : false,
  userId: storedAuthData ? JSON.parse(storedAuthData).userId : null,
  username: storedAuthData ? JSON.parse(storedAuthData).username : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{ userId: string; username: string }>
    ) => {
      state.isAuthenticated = true;
      state.userId = action.payload.userId;
      state.username = action.payload.username;
      localStorage.setItem(
        "authData",
        JSON.stringify({ userId: state.userId, username: state.username })
      );
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userId = null;
      state.username = null;
      localStorage.removeItem("authData");

      Cookies.remove("userId");
      Cookies.remove("username");
    },
    GetUserName: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
      localStorage.setItem(
        "authData",
        JSON.stringify({ userId: state.userId, username: action.payload })
      );
    },
  },
});

export const { login, logout, GetUserName } = authSlice.actions;
export default authSlice.reducer;
export const { reducer: authReducer, actions } = authSlice;

// productsSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiProduct } from "../../types/products";
import { getProducts as apiGetProducts } from "../../services/products";
import { addToCart } from "./cartSlice";

interface ProductsState {
  data: ApiProduct[];
  loading: boolean;
  error: string | null;
}

const initialState: ProductsState = {
  data: [],
  loading: false,
  error: null,
};

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (_, { rejectWithValue }) => {
    try {
      const data = await apiGetProducts();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to fetch products");
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getProducts.fulfilled,
        (state, action: PayloadAction<ApiProduct[]>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = "An error occurred";
      })
      .addCase(addToCart, (state, action: PayloadAction<ApiProduct>) => {
        state.data = state.data.map((product) =>
          product.Id === action.payload.Id
            ? { ...product, isInCart: true }
            : product
        );
      });
  },
});

export const { reducer: productsReducer } = productsSlice;
export { getProducts as fetchProducts };

export * from "./cartSlice";

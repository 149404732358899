import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import {
  Person as PersonIcon,
  ShoppingBag as ShoppingBagIcon,
  Receipt as ReceiptRefundIcon,
  Message as MessageIcon,
  TrackChanges as TrackChangesIcon,
  Lock as LockIcon,
  Home as HomeIcon,
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/authSlice";
import { clearCart } from "../../redux/features/cartSlice";

interface MenuItem {
  path: string;
  label: string;
  icon: React.ElementType;
}

const menuItems: MenuItem[] = [
  { path: "/profile", label: "Profile", icon: PersonIcon },
  { path: "/orders", label: "Orders", icon: ShoppingBagIcon },
  { path: "/refunds", label: "Refunds", icon: ReceiptRefundIcon },
  { path: "/inbox", label: "Inbox", icon: MessageIcon },
  { path: "/track-order", label: "Track Order", icon: TrackChangesIcon },
  { path: "/change-password", label: "Change Password", icon: LockIcon },
  { path: "/address", label: "Address", icon: HomeIcon },
];

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    navigate("/");
    window.location.reload();
  };

  const isMenuItemActive = (path: string) => {
    const isActive = location.pathname.includes(path);
    return {
      backgroundColor: isActive ? "" : "inherit",
      color: isActive ? "#D84241" : "inherit",
    };
  };
  const handleItemClick = (path: string) => {
    navigate(`/customer-dashboard${path}`);
    setDrawerOpen(false);
  };

  return (
    <div style={{ display: "flex", marginTop: "30px" }}>
      {/* Responsive Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List component="nav" sx={{ background: "#fff", width: 240 }}>
          {menuItems.map((item) => (
            <ListItem
              key={item.path}
              button
              selected={
                isMenuItemActive(item.path).backgroundColor !== "inherit"
              }
              onClick={() => handleItemClick(item.path)}
              sx={isMenuItemActive(item.path)}
            >
              <ListItemIcon sx={isMenuItemActive(item.path)}>
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">{item.label}</Typography>}
              />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Log out</Typography>}
            />
          </ListItem>
        </List>
      </Drawer>

      <div className="hidden sm:flex">
        <List component="nav" sx={{ background: "#fff", width: 240 }}>
          {menuItems.map((item) => (
            <ListItem
              button
              selected={
                isMenuItemActive(item.path).backgroundColor !== "inherit"
              }
              onClick={() => handleItemClick(item.path)}
              sx={isMenuItemActive(item.path)}
            >
              <ListItemIcon sx={isMenuItemActive(item.path)}>
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">{item.label}</Typography>}
              />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Log out</Typography>}
            />
          </ListItem>
        </List>
      </div>

      <div style={{ flexGrow: 1, padding: "16px" }}>
        <IconButton
          color="inherit"
          edge="start"
          onClick={() => setDrawerOpen(true)}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;

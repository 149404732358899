import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, Box } from "@mui/material";
import CartItem from "./cartItem";
import { RootState, AppDispatch } from "../../redux/store";
import { actions as cartActions } from "../../redux/features/cartSlice";
import ViewButton from "../../components/common/ViewButton";
import { useNavigate } from "react-router-dom";

const CartContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);

  const handleIncrease = (productId: number) => {
    dispatch(cartActions.increaseQuantity(productId));
  };

  const handleDecrease = (productId: number) => {
    dispatch(cartActions.decreaseQuantity(productId));
  };

  const handleRemove = (productId: number) => {
    dispatch(cartActions.removeFromCart(productId));
  };

  const handleClearCart = () => {
    dispatch(cartActions.clearCart());
  };

  const handleCheckout = () => {
    navigate("/checkout");
    setCartDrawerOpen(false);
  };

  const subtotal = cartItems.reduce(
    (total, item) => total + item.quantity * item.product.CostPrice,
    0
  );

  const total = subtotal;

  return (
    <Box>
      {cartItems.length === 0 ? (
        <Typography>Your cart is empty.</Typography>
      ) : (
        <Box
          sx={{
            overflowX: "scroll",
          }}
        >
          {cartItems.map((item: any) => (
            <Box key={item.product.Id}>
              <CartItem
                product={item.product}
                quantity={item.quantity}
                onIncrease={() => handleIncrease(item.product.Id)}
                onDecrease={() => handleDecrease(item.product.Id)}
                onRemove={() => handleRemove(item.product.Id)}
              />
            </Box>
          ))}
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              p: 2,
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              zIndex: 100,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 1,
              }}
            >
              <Typography variant="subtitle1">Subtotal:</Typography>
              <Typography variant="subtitle1">
                Rs.{subtotal.toFixed(2)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 1,
              }}
            >
              <Typography variant="subtitle1">Total:</Typography>
              <Typography variant="subtitle1">Rs.{total.toFixed(2)}</Typography>
            </Box>
            <Button color="error" variant="contained" onClick={handleCheckout}>
              Checkout
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CartContainer;

import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import VendorLayout from "../../containers/vendorDashboard/vendorLayout";
import ProductList from "./ProductList";
import AttributePage from "./AttributeList";
import AddProduct from "./AddProduct";
import CategoryList from "./CategoryList";
import OrderList from "./OrderList";
import EditProduct from "./EditProduct";
import { useSelector } from "react-redux";
import { selectIsVendorLoggedIn } from "../../redux/features/vendorSlice";

const VendorDashboard: React.FC = () => {
  const isLoggedIn = useSelector(selectIsVendorLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/seller-login" />;
  }
  return (
    <VendorLayout>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/product-list" element={<ProductList />} />
          <Route path="/category-list" element={<CategoryList />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/edit-product/:productId" element={<EditProduct />} />
          <Route path="/attribute-list" element={<AttributePage />} />
          <Route path="/orders-list" element={<OrderList />} />
        </Route>
      </Routes>
    </VendorLayout>
  );
};

export default VendorDashboard;

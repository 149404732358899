import React from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ProductPage from "../pages/products/productPage";
import Homepage from "../pages/home/Homepage";
import Header from "../components/layouts/Header";
import { Box } from "@mui/material";
import BestsellingPage from "../pages/bestselling/bestselling";
import ProductDetail from "../pages/products/productDetail";
import CategoryPage from "../pages/products/categoryPage";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import ProtectedRoute from "./ProtectedRoute";
import Checkout from "../pages/checkout/checkout";
import SellerPage from "../pages/seller/sellerPage";
import ShopDetail from "../pages/seller/sellerDetail";
import CustomerDashboard from "../pages/customer/customerDashboard";
import VendorRegister from "../pages/auth/vendorRegister";
import VendorLogin from "../pages/auth/vendorLogin";
import VendorDashboard from "../pages/VendorManage/vendorDashboard";
import Footer from "../components/layouts/footer";
import VendorRoute from "./VendorRoute";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";

const AppRouter: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        retryDelay: 5000,
        staleTime: 60000 * 5,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/seller-login" element={<VendorLogin />} />
          <Route path="/seller-signup" element={<VendorRegister />} />
          <Route
            path="/vendor-dashboard/*"
            element={<VendorDashboard />}
            // element={<VendorRoute element={<VendorDashboard />} />}
          />
          <Route
            path="/*"
            element={
              <>
                <Header />
                <>
                  <Box sx={{ paddingX: 4 }}>
                    <Routes>
                      <Route path="/" element={<Homepage />} />
                      <Route path="/products" element={<ProductPage />} />
                      <Route
                        path="/products/:productId"
                        element={<ProductDetail />}
                      />
                      <Route
                        path="/category/:categoryId"
                        element={<CategoryPage />}
                      />
                      <Route
                        path="/best-seller"
                        element={<BestsellingPage />}
                      />
                      <Route path="/shoplist" element={<SellerPage />} />
                      <Route path="/shops/:shopId" element={<ShopDetail />} />
                      <Route
                        path="/checkout"
                        element={<ProtectedRoute element={<Checkout />} />}
                      />
                      <Route
                        path="/customer-dashboard/*"
                        element={
                          <ProtectedRoute element={<CustomerDashboard />} />
                        }
                      />
                    </Routes>
                  </Box>
                </>
                <Footer />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default AppRouter;

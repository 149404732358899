import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, Divider } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  ApiProduct,
  MenuItemAttribute,
  MenuItemAttributeOption,
} from "../../../types/products";
import { useGetAttributeQuery } from "../../../query/productsQuery";
import { CheckBox, Clear, ClearAll, Delete } from "@mui/icons-material";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import AddProductPhotos from "./imageAdd";
import AddProductPortionImage from "./portionImage";
interface IProps {
  MenuItemPortions: any[];
  formData: ApiProduct;
  setFormData: Dispatch<SetStateAction<ApiProduct>>;
  onSubmit: any;
}
interface ILists {
  label: string;
  value: any;
  options: any;
}
const AddProductPortion: React.FC<IProps> = ({
  MenuItemPortions,
  formData,
  setFormData,
}) => {
  const { data: attributeData, error: attributeError } = useGetAttributeQuery();

  useEffect(() => {
    if (attributeError) {
      console.error("Error fetching attribute data:", attributeError);
    }
  }, [attributeError]);

  const handleAddPortion = () => {
    setFormData((prevData: ApiProduct) => {
      const newPortion = {
        Id: 0,
        Name: "",
        BestSeller: false,
        FeaturedSeller: false,
        Description: "",
        MetaDescription: "",
        ShortDescription: "",
        MenuItemPortionId: 0,
        Multiplier: 0,
        Price: 0.0,
        Discount: 0.0,
        OpeningStockRate: 0.0,
        OpeningStock: 0.0,
        OpeningStockAmount: 0.0,
        StockLimit: 0.0,
        ItemCode: "",
        Date_on_Sale_From: "",
        Date_on_Sale_To: "",
        On_Sale: false,
        Length: "",
        Height: "",
        Net_Weight: "",
        Gross_Weight: "",
        Disable_Buy_Button: false,
        Disable_Wishlist_Button: false,
        Available_For_Pre_Order: false,
        Call_for_price: false,
        MenuItemPortionPriceRanges: [
          {
            Id: 0,
            PositionId: 0,
            QtyMin: 0,
            QtyMax: 0,
            Price: 0,
          },
        ],
        MenuItemPortionAttributes: [
          {
            Id: 0,
            Name: "",
            Option: "",
            PositionId: 0,
          },
        ],

        MenuItemPhotos: [],
      };

      return {
        ...prevData,
        MenuItemPortions: [...prevData.MenuItemPortions, newPortion],
      };
    });
  };

  const handleChangePortionField = (
    index: number,
    field: string,
    value: any
  ) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.map((portion, i) =>
        i === index ? { ...portion, [field]: value } : portion
      ),
    }));
  };

  const handleChangePortionPriceField = (
    portionIndex: number,
    field: string,
    value: any,
    priceRangeIndex: number
  ) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.map((portion, i) =>
        i === portionIndex
          ? {
              ...portion,
              MenuItemPortionPriceRanges:
                portion.MenuItemPortionPriceRanges.map((range, j) =>
                  j === priceRangeIndex ? { ...range, [field]: value } : range
                ),
            }
          : portion
      ),
    }));
  };
  const handleAddPriceRange = (portionIndex: number) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.map((portion, i) =>
        i === portionIndex
          ? {
              ...portion,
              MenuItemPortionPriceRanges: [
                ...portion.MenuItemPortionPriceRanges,
                {
                  Id: 0,
                  PositionId: 0,
                  QtyMin: 0,
                  QtyMax: 0,
                  Price: 0,
                },
              ],
            }
          : portion
      ),
    }));
  };

  const handleDeletePriceRange = (
    portionIndex: number,
    priceRangeIndex: number
  ) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.map((portion, i) =>
        i === portionIndex
          ? {
              ...portion,
              MenuItemPortionPriceRanges:
                portion.MenuItemPortionPriceRanges.filter(
                  (_, j) => j !== priceRangeIndex
                ),
            }
          : portion
      ),
    }));
  };
  const [selectedAttribute, setSelectedAttribute] = useState<ILists | null>(
    null
  );

  const handleSelectAttribute = (index: number, attribute: ILists | null) => {
    setSelectedAttribute(attribute);
  };
  const attributeOptions: ILists[] = (attributeData || []).map(
    (attribute: MenuItemAttribute) => ({
      label: attribute.Name,
      value: attribute.Id,
      options: (attribute.MenuItemAttributeOptions || []).map(
        (option: MenuItemAttributeOption) => ({
          label: option.Option,
          value: option.Id,
        })
      ),
    })
  );

  const handleChangeAttributeField = (
    portionIndex: number,
    attributeIndex: number,
    field: string,
    value: any
  ) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.map((portion, i) =>
        i === portionIndex
          ? {
              ...portion,
              MenuItemPortionAttributes: portion.MenuItemPortionAttributes.map(
                (attribute, j) =>
                  j === attributeIndex
                    ? { ...attribute, [field]: value }
                    : attribute
              ),
            }
          : portion
      ),
    }));
  };

  const handleAddAttribute = (portionIndex: number) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.map((portion, i) =>
        i === portionIndex
          ? {
              ...portion,
              MenuItemPortionAttributes: [
                ...portion.MenuItemPortionAttributes,
                {
                  Id: 0,
                  Name: "",
                  Option: "",
                  PositionId: 0,
                },
              ],
            }
          : portion
      ),
    }));
  };

  const handleDeleteAttribute = (
    portionIndex: number,
    attributeIndex: number
  ) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.map((portion, i) =>
        i === portionIndex
          ? {
              ...portion,
              MenuItemPortionAttributes:
                portion.MenuItemPortionAttributes.filter(
                  (_, j) => j !== attributeIndex
                ),
            }
          : portion
      ),
    }));
  };

  const handleDeletePortion = (index: number) => {
    setFormData((prevData: ApiProduct) => ({
      ...prevData,
      MenuItemPortions: prevData.MenuItemPortions.filter((_, i) => i !== index),
    }));
  };
  return (
    <div>
      {formData.MenuItemPortions.map((portion, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Grid container spacing={1} key={index}>
            <Grid item xs={2}>
              <TextField
                label="ItemCode"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.ItemCode}
                onChange={(e) =>
                  handleChangePortionField(index, "ItemCode", e.target.value)
                }
                size="small"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Item Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.Name}
                onChange={(e) =>
                  handleChangePortionField(index, "Name", e.target.value)
                }
                size="small"
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Price"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.Price}
                onChange={(e) =>
                  handleChangePortionField(index, "Price", e.target.value)
                }
                size="small"
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Discount"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.Discount}
                onChange={(e) =>
                  handleChangePortionField(index, "Discount", e.target.value)
                }
                size="small"
              //  required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Length"
                variant="outlined"
                margin="normal"
                fullWidth
                value={portion.Length}
                onChange={(e) =>
                  handleChangePortionField(index, "Length", e.target.value)
                }
                size="small"
               // required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Height"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.Height}
                onChange={(e) =>
                  handleChangePortionField(index, "Height", e.target.value)
                }
                size="small"
              //  required
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Gross Weight"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.Gross_Weight}
                onChange={(e) =>
                  handleChangePortionField(
                    index,
                    "Gross_Weight",
                    e.target.value
                  )
                }
                size="small"
               // required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Net Weight"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.Net_Weight}
                onChange={(e) =>
                  handleChangePortionField(index, "Net_Weight", e.target.value)
                }
                size="small"
              //  required
              />
            </Grid>
            {portion.MenuItemPortionPriceRanges.map(
              (priceRange, priceRangeIndex) => (
                <React.Fragment key={priceRangeIndex}>
                  <Grid item xs={3}>
                    <TextField
                      label={`Min Quantity`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={priceRange.QtyMin}
                      onChange={(e) =>
                        handleChangePortionPriceField(
                          index,
                          "QtyMin",
                          e.target.value,
                          priceRangeIndex
                        )
                      }
                      size="small"
                     // required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label={`Max Quantity`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={priceRange.QtyMax}
                      onChange={(e) =>
                        handleChangePortionPriceField(
                          index,
                          "QtyMax",
                          e.target.value,
                          priceRangeIndex
                        )
                      }
                      size="small"
                    //  required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label={`Price`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={priceRange.Price}
                      onChange={(e) =>
                        handleChangePortionPriceField(
                          index,
                          "Price",
                          e.target.value,
                          priceRangeIndex
                        )
                      }
                      size="small"
                     // required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      color="primary"
                      onClick={() =>
                        handleDeletePriceRange(index, priceRangeIndex)
                      }
                    >
                      Clear
                    </Button>
                  </Grid>
                </React.Fragment>
              )
            )}

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                startIcon={<PlusCircleOutlined />}
                color="secondary"
                onClick={() => handleAddPriceRange(index)}
                size="small"
              >
                Add Price Range
              </Button>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="OpeningStockRate"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.OpeningStockRate}
                onChange={(e) =>
                  handleChangePortionField(
                    index,
                    "OpeningStockRate",
                    e.target.value
                  )
                }
                size="small"
               // required
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="OpeningStock"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.OpeningStock}
                onChange={(e) =>
                  handleChangePortionField(
                    index,
                    "OpeningStock",
                    e.target.value
                  )
                }
                size="small"
                //required
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="OpeningStockAmount"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.OpeningStockAmount}
                onChange={(e) =>
                  handleChangePortionField(
                    index,
                    "OpeningStockAmount",
                    e.target.value
                  )
                }
                size="small"
               // required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Stock Limit"
                variant="outlined"
                fullWidth
                margin="normal"
                value={portion.StockLimit}
                onChange={(e) =>
                  handleChangePortionField(index, "StockLimit", e.target.value)
                }
                size="small"
              //  required
              />
            </Grid>

            <Divider />
            {portion.MenuItemPortionAttributes.length === 0 ? (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="body2" color="primary">
                  No attributes available for this portion.
                </Typography>
              </Grid>
            ) : (
              portion.MenuItemPortionAttributes.map(
                (attribute, attributeIndex) => (
                  <React.Fragment key={attributeIndex}>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={attributeOptions}
                        getOptionLabel={(option: ILists) => option.label}
                        value={
                          attributeOptions.find(
                            (option) => option.label === attribute.Name
                          ) || null
                        }
                        onChange={(_, newValue) => {
                          handleChangeAttributeField(
                            index,
                            attributeIndex,
                            "Name",
                            (newValue as ILists)?.label || ""
                          );
                          handleSelectAttribute(index, newValue as ILists);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Attribute Name`}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={
                          selectedAttribute
                            ? selectedAttribute.options
                            : ([] as ILists[])
                        }
                        getOptionLabel={(option: ILists) => option.label}
                        onChange={(_, newValue) =>
                          handleChangeAttributeField(
                            index,
                            attributeIndex,
                            "Option",
                            newValue?.label || ""
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Attribute Option`}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size="small"
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        color="primary"
                        onClick={() =>
                          handleDeleteAttribute(index, attributeIndex)
                        }
                      >
                        Clear
                      </Button>
                    </Grid>
                  </React.Fragment>
                )
              )
            )}

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                startIcon={<PlusCircleOutlined />}
                color="secondary"
                onClick={() => handleAddAttribute(index)}
                size="small"
              >
                Add Attribute
              </Button>
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangePortionField(
                            index,
                            "On_Sale",
                            e.target.checked
                          )
                        }
                        checked={portion.On_Sale}
                      />
                    }
                    label="On Sale"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangePortionField(
                            index,
                            "Disable_Buy_Button",
                            e.target.checked
                          )
                        }
                        checked={portion.Disable_Buy_Button}
                      />
                    }
                    label="Disable Buy"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangePortionField(
                            index,
                            "Disable_Wishlist_Button",
                            e.target.checked
                          )
                        }
                        checked={portion.Disable_Wishlist_Button}
                      />
                    }
                    label="Disable Wishlist"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangePortionField(
                            index,
                            "BestSeller",
                            e.target.checked
                          )
                        }
                        checked={portion.BestSeller}
                      />
                    }
                    label="Best Seller"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangePortionField(
                            index,
                            "FeaturedSeller",
                            e.target.checked
                          )
                        }
                        checked={portion.FeaturedSeller}
                      />
                    }
                    label="Featured Seller"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangePortionField(
                            index,
                            "Available_For_Pre_Order",
                            e.target.checked
                          )
                        }
                        checked={portion.Available_For_Pre_Order}
                      />
                    }
                    label="Pre Order Available"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleChangePortionField(
                            index,
                            "Call_for_price",
                            e.target.checked
                          )
                        }
                        checked={portion.Call_for_price}
                      />
                    }
                    label="Call For Price"
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {formData.MenuItemPortions.length > 0 && (
                <AddProductPortionImage
                  portion={portion}
                  formData={formData}
                  setFormData={setFormData}
                />
              )}
            </Grid>
          </Grid>

          <IconButton color="error" onClick={() => handleDeletePortion(index)}>
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusCircleFilled />}
          onClick={handleAddPortion}
        >
          Add Portion
        </Button>
      </Grid>
    </div>
  );
};

export default AddProductPortion;

import { useQuery } from "react-query";
import { ErrorToast } from "../components/forms/toast";
import { getSeller } from "../services/seller";

export const useGetSellerQuery = () =>
  useQuery(["seller"], () => getSeller(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

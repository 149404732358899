import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useGetCompanyQuery } from "../../query/companyQuery";
import SubHeading from "../../components/common/subHeading";
import Heading from "../../components/common/heading";
import { useGetSellerQuery } from "../../query/sellerQuery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const ShopLists: React.FC = () => {
  const { data: vendors } = useGetSellerQuery();

  return (
    <Box sx={{ mt: 8 }}>
      <SubHeading label="Shops" />
      <Heading label="Featured Shops" />
      <Fade bottom>
        <Grid container spacing={4}>
          {vendors?.slice(0, 6).map((vendor) => (
            <Grid item key={vendor.Id} xs={12} sm={6} md={4} lg={2}>
              {!vendor.Name ? null : (
                <>
                  <Card
                    sx={{
                      height: "100%",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      transition: "box-shadow 0.3s ease-in-out",
                      position: "relative",
                      "&:hover": {
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={vendor.Name}
                      height="140"
                      image="https://www.indiafilings.com/learn/wp-content/uploads/2023/03/How-can-I-register-my-shop-and-establishment-online.jpg"
                      sx={{ objectFit: "contain", width: "100%" }}
                    />
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {vendor.Name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {`${vendor.Address}`} {`${vendor.City}` ? null : ""}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {`${vendor.Telephone}` ? null : ""}
                      </Typography>
                    </CardContent>
                    <Link
                      to={`/shops/${vendor.Id}`}
                      color="inherit"
                      style={{ textDecoration: "none" }}
                    >
                      <IconButton
                        sx={{
                          position: "absolute",
                          bottom: "0",
                          right: "4px",
                          backgroundColor: "white",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <ArrowForwardIcon
                          sx={{ fontSize: 24, color: "#D84241", }}
                        />
                      </IconButton>
                    </Link>
                  </Card>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </Fade>
    </Box>
  );
};

export default ShopLists;

import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
 } from "@mui/material";
import Countdown from "react-countdown";
import SubHeading from "../common/subHeading";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ApiProduct } from "../../types/products";
import ProductCard from "../../containers/products/productCard";

const FlashSaleSection: React.FC = () => {
  const navigate = useNavigate();
  const flashSaleItems = useSelector((state: RootState) => state.products.data);
  const flashSale = flashSaleItems?.filter(
    (product: ApiProduct) => product.FeaturedSeller
  );

  if (!flashSale || flashSale.length === 0) {
    return <div>No flash sale items found.</div>;
  }

  const firstFlashSaleItem = flashSale[0];
  if (
    !firstFlashSaleItem.MenuItemPortions ||
    firstFlashSaleItem.MenuItemPortions.length === 0
  ) {
    return <div>No flash sale portions found for the first item.</div>;
  }

  const startDate = new Date(
    firstFlashSaleItem.MenuItemPortions[0].Date_on_Sale_From
  );
  const endDate = new Date(
    firstFlashSaleItem.MenuItemPortions[0].Date_on_Sale_To
  );

  const isFlashSaleActive = startDate <= new Date() && new Date() <= endDate;

  return (
    <Box maxWidth="xl" sx={{ mt: 8 }}>
      <SubHeading label="today's" />
      <Grid container alignItems="center" justifyContent="space-between" mb={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Flash Sale
          </Typography>

          <Grid item>
            {isFlashSaleActive ? (
              <Countdown
                date={endDate}
                onComplete={() => console.log("Sale ended")}
                renderer={({ days, hours, minutes, seconds }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      background: "#D94342",
                      color: "white",
                      borderRadius: "2px",
                      paddingX: 1,
                    }}
                  >
                    <Typography variant="h5">
                      {days}:{hours}:{minutes}:{seconds}
                    </Typography>
                  </Box>
                )}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Box>

        <Grid item>
          <Button
            color="error"
            sx={{ borderRadius: "full" }}
            onClick={() => {
              navigate("/products");
            }}
          >
            View All Products
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        {flashSale.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
            <ProductCard
              product={item}
              onAddToCart={() => console.log("Add to cart")}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FlashSaleSection;

import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import {
  Person as PersonIcon,
  ShoppingBag as ShoppingBagIcon,
  Receipt as ReceiptRefundIcon,
  Message as MessageIcon,
  TrackChanges as TrackChangesIcon,
  Lock as LockIcon,
  Home as HomeIcon,
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import CategoryIcon from "@mui/icons-material/Category";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import { Divider } from "antd";
import { RootState } from "../../redux/store";
import Cookies from "js-cookie";
import { vendorLogout } from "../../redux/features/vendorSlice";

interface MenuItem {
  path: string;
  label: string;
  icon: React.ElementType;
}

const menuItems: MenuItem[] = [
  { path: "/product-list", label: "Products", icon: ShoppingBasketIcon },
  { path: "/add-product", label: "Add Product", icon: AddShoppingCartIcon },
  { path: "/category-list", label: "Category", icon: CategoryIcon },
  { path: "/attribute-list", label: "Attributes", icon: HorizontalSplitIcon },
  { path: "/orders-list", label: "Orders", icon: ShoppingBagIcon },
];

interface VendorLayoutProps {
  children: React.ReactNode;
}

const VendorLayout: React.FC<VendorLayoutProps> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const username = Cookies.get("UserName");
  const user = useSelector((state: RootState) => state.auth.username);

  const handleLogout = () => {
    dispatch(vendorLogout());
    navigate("/");
    window.location.reload();
  };

  const isMenuItemActive = (path: string) => {
    const isActive = location.pathname.includes(path);
    return {
      backgroundColor: isActive ? "" : "inherit",
      color: isActive ? "#D84241" : "inherit",
    };
  };
  const handleItemClick = (path: string) => {
    navigate(`/vendor-dashboard${path}`);
    setDrawerOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List component="nav" sx={{ background: "#fff", width: 240 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "#2b3445",
              fontWeight: "bold",
              justifyContent: "center",
            }}
          >
            {username ? `Welcome, ${username}` : "Seller Dashboard"}
          </Typography>
          <Divider />
          {menuItems.map((item) => (
            <ListItem
              key={item.path}
              button
              selected={
                isMenuItemActive(item.path).backgroundColor !== "inherit"
              }
              onClick={() => handleItemClick(item.path)}
              sx={isMenuItemActive(item.path)}
            >
              <ListItemIcon sx={isMenuItemActive(item.path)}>
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">{item.label}</Typography>}
              />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Log out</Typography>}
            />
          </ListItem>
        </List>
      </Drawer>

      <div className="hidden sm:flex">
        <List
          component="nav"
          sx={{
            background: "#fff",
            width: 240,
            height: "100vh",
            paddingTop: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "#2b3445",
              fontWeight: "bold",
              justifyContent: "center",
            }}
          >
            {username ? `Welcome, ${username}` : "Seller Dashboard"}
          </Typography>
          <Divider />

          {menuItems.map((item) => (
            <ListItem
              button
              selected={
                isMenuItemActive(item.path).backgroundColor !== "inherit"
              }
              onClick={() => handleItemClick(item.path)}
              sx={isMenuItemActive(item.path)}
            >
              <ListItemIcon sx={isMenuItemActive(item.path)}>
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">{item.label}</Typography>}
              />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Log out</Typography>}
            />
          </ListItem>
        </List>
      </div>

      <div style={{ flexGrow: 1, padding: "16px" }}>
        <IconButton
          color="inherit"
          edge="start"
          onClick={() => setDrawerOpen(true)}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        {children}
      </div>
    </div>
  );
};

export default VendorLayout;

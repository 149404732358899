import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useDeleteProduct,
  useGetProductQuery,
} from "../../query/productsQuery";
import { Person, Edit, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SucessToast } from "../../components/forms/toast";

const ProductList = () => {
  const navigate = useNavigate();
  const { data: productData } = useGetProductQuery();
  const deleteProductMutation = useDeleteProduct();
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    productId: null,
  });
  console.log(productData);

  const handleEdit = (productId: any) => {
    navigate(`/vendor-dashboard/edit-product/${productId}`);
  };
  const handleDelete = (productId: any) => {
    setDeleteConfirmation({ open: true, productId });
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation({ open: false, productId: null });
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmation.productId === null) {
      console.error("Invalid product ID for deletion");
      handleCloseDeleteConfirmation();
      return;
    }

    try {
      await deleteProductMutation.mutateAsync(deleteConfirmation.productId);
      SucessToast("Product deleted successfully");
      handleCloseDeleteConfirmation();
    } catch (error) {
      ErrorToast("Error deleting product");
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <Person sx={{ color: "#D84241" }} />
          My Products
        </Typography>
        <Button
          onClick={() => navigate("/vendor-dashboard/add-product")}
          variant="contained"
          color="error"
          sx={{
            background: "#fce9ec",
            color: "#D84241",
            boxShadow: "none",
            "&:hover": {
              background: "#fef2f2",
              color: "#D84241",
              boxShadow: "none",
            },
          }}
        >
          Add Products
        </Button>
      </Box>

      <Paper sx={{ boxShadow: "none", paddingY: 2, marginTop: 2 }}>
        <TableContainer component={Box}>
          <Table sx={{ border: "none" }}>
            <TableHead>
              <TableRow sx={{ border: "none" }}>
                <TableCell sx={{ fontWeight: 600 }}>Product Name</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Cost Price</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productData &&
                productData.map((product) => (
                  <TableRow key={product.Id}>
                    <TableCell>{product.Name}</TableCell>
                    <TableCell>Rs. {product.CostPrice}</TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleEdit(product.Id)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(product.Id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={deleteConfirmation.open}
        onClose={handleCloseDeleteConfirmation}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductList;

import React, { useState } from "react";
import {
  Chip,
  Typography,
  Container,
  Card,
  IconButton,
  Box,
} from "@mui/material";
import { ArrowForward, LocalAtm } from "@mui/icons-material";
import Pagination from "../../components/common/pagination";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Refund = () => {
  const [pageNo, setPageNo] = useState(1);
  const totalPageNumber = 4;

  const userRefunds = [
    {
      refundId: "#f0ba538b-c8f3-45cf",
      status: "Processed",
      date: "2023-03-01",
      amount: "$50.00",
    },
    {
      refundId: "#f0ba538b-f3c3-45cf",
      status: "Approved",
      date: "2023-04-01",
      amount: "$75.00",
    },
  ];

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <LocalAtm sx={{ color: "#D84241" }} />
          Refund History
        </Typography>
      </Box>

      {userRefunds.map((refund) => (
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 1,
            padding: 1,
            boxShadow: "none",
          }}
          key={refund.refundId}
        >
          <Typography sx={{ fontSize: "16px", color: "#2b3445" }}>
            {refund.refundId}
          </Typography>
          <Typography>
            <Chip label={refund.status} variant="outlined" color="success" />
          </Typography>
          <Typography>{refund.date}</Typography>
          <Typography>{refund.amount}</Typography>
          <IconButton>
            <ArrowForward />
          </IconButton>
        </Card>
      ))}
      <Pagination
        page={totalPageNumber}
        setPageNo={setPageNo}
        pageNo={pageNo}
      />
    </Container>
  );
};

export default Refund;

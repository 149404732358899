import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import { CardMedia, Chip, Dialog, DialogContent, Divider } from "@mui/material";
import { ApiProduct, MenuItemPhoto } from "../../types/products";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";

interface ProductDetailDialogProps {
  open: boolean;
  onClose: () => void;
  product: ApiProduct | null;
}

const ProductDetailDialog: React.FC<ProductDetailDialogProps> = ({
  open,
  onClose,
  product,
}) => {
  if (!product) {
    return null;
  }
  const firstPhoto: MenuItemPhoto | undefined = product?.MenuItemPhotos[0];

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Product Detail</Typography>
          <IconButton onClick={handleClose} sx={{ marginLeft: "auto" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ marginY: 2 }} />

        <Box
          sx={{
            display: "flex",
            margin: "auto",
            marginTop: 2,
            padding: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              marginRight: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
              }}
            >

              
              {firstPhoto && (
                <CardMedia
                  component="img"
                  alt="Product Image"
                  height="40%"
                  image={`data:${firstPhoto.IdentityFileType};base64, ${firstPhoto.PhoteIdentity}`}
                  style={{ objectFit: "contain" }}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              padding: 2,
            }}
          >
            <Typography variant="body1">Category</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  marginBottom: 1,
                }}
              >
                {product.Name}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  marginBottom: 1,
                }}
              >
                Rs. {product.CostPrice}
              </Typography>
            </Box>

            <Typography
              variant="body1"
              paragraph
              sx={{
                marginBottom: 1,
              }}
            >
              ⭐️ ⭐️ ⭐️ ⭐️ ⭐️(35)
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{
                marginBottom: 2,
              }}
            >
              {product.Description}
            </Typography>

            {/* <Box sx={{ marginBottom: 1 }}>
              {sizes.map((size) => (
                <Chip
                  key={size.id}
                  label={`Size: ${size.name}`}
                  color="error"
                  sx={{ marginRight: 1 }}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                marginBottom: 1,
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ marginRight: 1, alignSelf: "center" }}
              >
                Available Colors:
              </Typography>
              {colors.map((color) => (
                <Box
                  key={color.id}
                  sx={{
                    backgroundColor: color.code,
                    width: 20,
                    height: 20,
                    marginRight: 1,
                    borderRadius: "borderRadius",
                  }}
                />
              ))}
            </Box> */}
            <Chip label="In Stock" color="primary" />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <IconButton
                sx={{
                  width: 40,
                  height: 40,
                  border: "1px solid #D84241",
                  borderRadius: 1,
                }}
              >
                <RemoveIcon />
              </IconButton>
              <Typography
                sx={{
                  margin: "0 10px",
                  width: 40,
                  textAlign: "center",
                }}
              >
                1
              </Typography>
              <IconButton
                sx={{
                  width: 40,
                  height: 40,
                  border: "1px solid #D84241",
                  borderRadius: 1,
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                marginTop: 2,
                marginRight: 2,
                background: "#D84241",
              }}
            >
              Add to Cart
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              sx={{
                marginTop: 2,
                marginRight: 2,
              }}
            >
              Wishlist
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDetailDialog;

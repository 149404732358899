import React from "react";
import { Container } from "@mui/material";
import VendorLoginForm from "../../components/forms/vendor/vendorLogin";

const VendorLogin: React.FC = () => {
  return (
    <Container>
      <VendorLoginForm />
    </Container>
  );
};

export default VendorLogin;

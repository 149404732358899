import React, { useState, useMemo } from "react";
import {
  Box,
  Typography,
  TextField,
  Rating,
  Button,
  Snackbar,
  Grid,
  Divider,
  useMediaQuery,
} from "@mui/material";
import {
  useAddMenuItemPositionReviewMutation,
  useMenuItemPositionReviewsQuery,
} from "../../query/productsQuery";
import { format } from "date-fns";
import { MenuItemPositionReview } from "../../types/products";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useGetUserQuery } from "../../query/authQuery";
import { Progress } from "antd";
import theme from "../../theme/theme";

const ProductReviewForm: React.FC<{ productId: string }> = ({ productId }) => {
  const [reviewer, setReviewer] = useState("");
  const [reviewerEmail, setReviewerEmail] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState<number | null>(0);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const userId = useSelector((state: RootState) => state.auth.userId) ?? "";
  const { data: userData } = useGetUserQuery(userId);
  const username = userData?.data?.UserName;
  const email = userData?.data?.Email;

  const addMenuItemPositionReviewMutation =
    useAddMenuItemPositionReviewMutation();

  const { data: reviewsData } = useMenuItemPositionReviewsQuery();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async () => {
    try {
      const initialFormData: MenuItemPositionReview = {
        Id: 0,
        Date_Created: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        PortionId: parseInt(productId),
        Status: "",
        Reviewer: username,
        Reviewer_Email: email,
        Review: review,
        Rating: rating || 0,
        Verified: true,
      };

      const mutationPayload: MenuItemPositionReview = {
        PortionId: initialFormData.PortionId,
        Reviewer: initialFormData.Reviewer,
        Reviewer_Email: initialFormData.Reviewer_Email,
        Review: initialFormData.Review,
        Rating: initialFormData.Rating || 0,
        Verified: initialFormData.Verified,
        Id: 0,
        Date_Created: initialFormData.Date_Created,
        Status: "",
      };

      await addMenuItemPositionReviewMutation.mutateAsync(mutationPayload);

      setOpenSnackbar(true);
      setReviewer("");
      setReviewerEmail("");
      setReview("");
      setRating(null);
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const averageRating = useMemo(() => {
    if (!reviewsData || reviewsData.length === 0) return 0;

    const totalRating = reviewsData.reduce(
      (sum, review) => sum + review.Rating,
      0
    );
    return totalRating / reviewsData.length;
  }, [reviewsData]);

  const countRatings = useMemo(() => {
    if (!reviewsData || reviewsData.length === 0) return [];

    const countArray = Array(5).fill(0);
    reviewsData.forEach((review) => {
      countArray[Math.floor(review.Rating) - 1]++;
    });
    return countArray;
  }, [reviewsData]);

  return (
    <Box sx={{ background: "#fff", marginY: 4 }}>
      <Typography
        sx={{ background: "#F5F5F5", padding: 2, fontWeight: "semibold" }}
      >
        Ratings & Reviews
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: isSmallScreen ? "right" : "center",
          padding: 2,
          textAlign: "start",
        }}
      >
        <Box sx={{ width: "15%" }}>
          <Typography variant="h4">{averageRating.toFixed(1)}</Typography>
          <Rating value={averageRating} precision={0.5} size="large" readOnly />
          <Typography variant="subtitle2">Ratings</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {countRatings.map((count, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: isSmallScreen ? "start" : "center",
                flexDirection: isSmallScreen ? "column" : "row",

                gap: isSmallScreen ? 1 : 4,
              }}
            >
              <Rating
                name="text-feedback"
                value={index + 1}
                readOnly
                precision={0.5}
              />
              <Progress
                percent={reviewsData ? (count / reviewsData.length) * 100 : 0}
                steps={5}
                strokeColor={"#faaf00"}
                showInfo={false}
              />
              <Typography>{count}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />
      <Grid container sx={{ margin: 2 }} spacing={2} maxWidth={"lg"}>
        <Grid xs={6}>
          <Typography variant="h6">Reviews</Typography>
          <Box sx={{ padding: 1 }}>
            {reviewsData?.slice(0, 5).map((comment) => (
              <Box
                key={comment.Id}
                sx={{
                  marginBottom: 1,
                  borderBottom: "1px solid #f0f0f0",
                  paddingBottom: 1,
                }}
              >
                <Rating
                  value={comment.Rating}
                  precision={0.5}
                  size="small"
                  readOnly
                />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "semibold", color: "#333" }}
                >
                  {comment.Reviewer}
                </Typography>
                <Typography variant="body2" sx={{ color: "#555" }}>
                  {comment.Review}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {isAuthenticated ? (
            <>
              <Typography variant="h6">Write a Review</Typography>
              <Grid container spacing={1} sx={{ marginBottom: 4 }}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setReviewer(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setReviewerEmail(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Your Review"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Rating:</Typography>
                  <Rating
                    name="rating"
                    value={rating}
                    precision={0.5}
                    onChange={(event, newValue) => setRating(newValue)}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="error"
                sx={{ marginTop: 2, width: "100%" }}
                onClick={handleSubmit}
              >
                Submit Review
              </Button>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Review submitted successfully!"
              />
            </>
          ) : (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Please login to leave a review.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductReviewForm;

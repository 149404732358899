import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Grid,
  Checkbox,
  FormControlLabel,
  CardMedia,
} from "@mui/material";
import { useGetCompanyByIdQuery } from "../../../query/companyQuery";
import { Link, useNavigate } from "react-router-dom";
import { ErrorToast, SucessToast } from "../toast";
import axiosClient from "../../../lib/axiosInterceptor";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { login } from "../../../redux/features/authSlice";

interface RegistrationFormProps {
  onRegister: (
    firstName: string,
    lastName: string,
    userName: string,
    email: string,
    phoneNumber: string,
    password: string,
    isVendor: boolean,
    file: File | null
  ) => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onRegister }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isVendor, setIsVendor] = useState(false);
  const [shopname, setShopName] = useState("");
  const [address, setAddress] = useState("");
  const [vat, setVat] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { data: vendor } = useGetCompanyByIdQuery(1);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleRegister = async () => {
    try {
      const res = await axiosClient.post(`/api/AccountAPI/`, {
        Name: isVendor ? shopname : userName,
        Address: address,
        Telephone: phoneNumber,
        Email: email,
        PanNo: vat,
        AccountTypeId: isVendor ? 6 : 1,
      });

      SucessToast(res.data.message);

      const userRes = await axiosClient.post(
        `/api/UserAccountAPI/CreateUser/`,
        {
          FirstName: firstName,
          LastName: lastName,
          UserName: userName,
          Email: email,
          PhoneNumber: phoneNumber,
          Password: password,
          IsActive: false,
          IsVendor: false,
          IsCustomer: true,
        }
      );

      const userId = userRes.data.Id;

      const assignUserRole = async (userId: string) => {
        const rolesResponse = await axiosClient.get(`/api/HotelRole/GetRoles/`);
        const roles = rolesResponse.data;
        const roleName = isVendor ? "OnlineSeller" : "OnlineCustomer";

        let role = roles.find((r: any) => r.Name === roleName);

        if (!role) {
          const roleCreationResponse = await axiosClient.post(
            `/api/HotelRole/PostRole/`,
            {
              Name: roleName,
              Description: roleName,
              PermissionList: "Roles",
            }
          );

          role = roleCreationResponse.data;
        }

        await axiosClient.post(
          `/api/UserRoleAssign/?UserId=${userId}&RoleId=${role.Id}`
        );
      };

      assignUserRole(userId);

      const loginRes = await axiosClient.post(`/api/loginAPI`, {
        UserName: userName,
        Password: password,
      });

      const loggedInUserId = loginRes.data.Id;
      const loggedInUserName = loginRes.data.UserName;

      Cookies.set("userId", loggedInUserId);
      Cookies.set("UserName", loggedInUserName);

      SucessToast("Registration and Login Success!");
      dispatch(login({ userId: loggedInUserId, username: loggedInUserName }));
      navigate("/customer-dashboard/profile");
    } catch (error) {
      ErrorToast("An unexpected error occurred during registration or login");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
          marginY: 8,
        }}
      >
        {vendor && (
          <CardMedia
            component="img"
            alt="Company Logo"
            image={`data:${vendor.IdentityFileType};base64,${vendor.PhotoIdentity}`}
            sx={{
              width: "80px",
            }}
          />
        )}
        <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
          Create Your Account
        </Typography>
        <form
          style={{
            width: "100%",
            marginTop: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                size="small"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="userName"
                label="User Name"
                name="userName"
                autoComplete="username"
                size="small"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="tel"
                size="small"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="fileInput"
                type="file"
                onChange={handleFileInputChange}
              />
              <label htmlFor="fileInput">
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{ marginTop: 1 }}
                >
                  Upload a file
                </Button>
              </label>
              <Typography>
                {selectedFile ? selectedFile.name : "No file chosen"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isVendor}
                    onChange={() => setIsVendor(!isVendor)}
                    color="primary"
                  />
                }
                label="Sign in as Vendor?"
              />
            </Grid>
          </Grid>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="error"
            sx={{ marginTop: 2 }}
            onClick={handleRegister}
            disabled={isLoading}
          >
            {isLoading ? "Registering..." : "Register"}
          </Button>
        </form>

        <Typography
          variant="body2"
          sx={{ marginTop: 2, display: "flex", gap: "2px" }}
        >
          Already have an account?
          <Link to="/login" style={{ marginLeft: 2, color: "#D84241" }}>
            Sign in
          </Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default RegistrationForm;

import React, { useEffect, useState } from "react";
import FilterSidebar from "../../components/common/filter";
import Pagination from "../../components/common/pagination";
import { Box, Chip, Grid, Typography } from "@mui/material";
import ProductCard from "../../containers/products/productCard";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/features/productSlice";
import { ApiProduct } from "../../types/products";
import { actions as cartActions } from "../../redux/features/cartSlice";
import { Select } from "antd";

const ProductPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const products = useSelector((state: RootState) => state.products.data);
  const [chips, setChips] = useState(["Chip 1", "Chip 2", "Chip 3"]);
  const handleChange = (value: string) => {};
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleAddToCart = (product: ApiProduct) => {
    dispatch(cartActions.addToCart(product));
  };
  const [pageNo, setPageNo] = useState(1);
  const totalPageNumber = 4;
  const handleDelete = (chipToDelete: any) => () => {
    setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
  };
  return (
    <>
      <div className="flex justify-between gap-4 mt-8">
        <FilterSidebar />
        <Box>
          <Box
            sx={{
              width: "100%",
              background: "#fff",
              padding: 2,
              marginBottom: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "18px", color: "#2B3445" }}
              >
                Searching for “mobile phone”
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", color: "#7d879c" }}
              >
                {" "}
                48 results found
              </Typography>

              {/* {chips.map((chip, index) => (
                <Chip label={chip} onDelete={handleDelete(chip)} />
              ))} */}
            </Box>
            <Box>
              Sort By:{" "}
              <Select
                defaultValue="Relevence"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "relevence", label: "Relevence" },
                  { value: "date", label: "Date" },
                  { value: "pricelow", label: "Price Low To High" },
                  { value: "pricehigh", label: "High Low To Price" },
                ]}
              />{" "}
            </Box>
          </Box>
          <Grid container spacing={2}>
            {products?.map((product, index) => (
              <Grid key={index} item xs={12} sm={4} md={3} lg={3}>
                <ProductCard
                  product={product}
                  onAddToCart={() => handleAddToCart(product)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>{" "}
      </div>
      <Pagination
        page={totalPageNumber}
        setPageNo={setPageNo}
        pageNo={pageNo}
      />
    </>
  );
};

export default ProductPage;

// Profile.js

import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  Paper,
  Grid,
  Avatar,
  Button,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEditUser, useGetUserQuery } from "../../query/authQuery";
import { Person } from "@mui/icons-material";
import { EditUserData } from "../../types/users";
import { AxiosResponse } from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface ProfileProps {
  // You can define the type for props if needed
}

interface Statistic {
  value: number;
  label: string;
}

const Profile: React.FC<ProfileProps> = () => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [editedUserData, setEditedUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    password: "",
  });

  const userId = useSelector((state: RootState) => state.auth.userId) ?? "";
  const { data: userData } = useGetUserQuery(userId);

  const username = userData?.data?.UserName;
  const firstName = userData?.data?.FirstName;
  const lastName = userData?.data?.LastName;
  const email = userData?.data?.Email;
  const phone = userData?.data?.PhoneNumber;
  const joindate = userData?.data?.JoinDate;
  const password = userData?.data?.Password;

  const handleEditProfileClick = () => {
    setEditDialogOpen(true);
    setEditedUserData({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      location: "",
      password: password,
    });
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const editUserMutation = useEditUser(setEditDialogOpen);

  const handleSaveChanges = async () => {
    try {
      const editedData: EditUserData = {
        userId,
        userData: {
          firstName: editedUserData.firstName,
          lastName: editedUserData.lastName,
          email: editedUserData.email,
          PhoneNumber: editedUserData.phone,
          location: editedUserData.location,
          Password: editedUserData.password,
          userId: function (userId: any): Promise<AxiosResponse<any, any>> {
            throw new Error("Function not implemented.");
          },
          Claims: [],
          Logins: [],
          Roles: [],
          FullName: "",
          UserName: "",
          FirstName: "",
          LastName: "",
          RoleName: "",
          Level: 0,
          Email: "",
          IsActive: false,
          IsVendor: false,
          IsCustomer: false,
          ResetPassword: false,
          JoinDate: "",
          Token: null,
          EmailConfirmed: false,
          PasswordHash: null,
          SecurityStamp: null,
          PhoneNumberConfirmed: false,
          TwoFactorEnabled: false,
          LockoutEndDateUtc: null,
          LockoutEnabled: false,
          AccessFailedCount: 0,
          Id: "",
        },
      };

      await editUserMutation.mutateAsync(editedData);
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };
  const stats: Statistic[] = [
    { value: 15, label: "All Orders" },
    { value: 16, label: "Awaiting Payments" },
    { value: 12, label: "Awaiting Shipment" },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <Person sx={{ color: "#D84241" }} />
          My Profile
        </Typography>
        <Button
          onClick={handleEditProfileClick}
          variant="contained"
          color="error"
          sx={{
            background: "#fce9ec",
            color: "#D84241",
            boxShadow: "none",
            "&:hover": {
              background: "#fef2f2",
              color: "#D84241",
              boxShadow: "none",
            },
          }}
        >
          Edit Profile
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={2} sx={{ padding: 4, boxShadow: "none" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar
                  alt={`${firstName} ${lastName}`}
                  sx={{
                    width: isSmallScreen ? 40 : 60,
                    height: isSmallScreen ? 40 : 60,
                  }}
                ></Avatar>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{`${firstName} ${lastName}`}</Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "12px", color: "#7d879c" }}
                >
                  Chitwan, Nepal
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
              {stats.map((stat, index) => (
                <Grid item key={index} xs={6} sm={4}>
                  <Card sx={{ padding: 2, height: "100px", boxShadow: "none" }}>
                    <Typography variant="h5" color="#D84241">
                      {stat.value}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: "#7d879c" }}>
                      {stat.label}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Paper sx={{ boxShadow: "none", paddingY: 2, marginTop: 2 }}>
        <TableContainer component={Box}>
          <Table sx={{ border: "none" }}>
            <TableHead>
              <TableRow sx={{ border: "none" }}>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    color: "#7d879c",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  First Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    color: "#7d879c",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  Last Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    color: "#7d879c",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    color: "#7d879c",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    color: "#7d879c",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  Join date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: isSmallScreen ? "12px" : "14px",
                    color: "#2b3445",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  {firstName}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isSmallScreen ? "12px" : "14px",
                    color: "#2b3445",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  {lastName}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isSmallScreen ? "12px" : "14px",
                    color: "#2b3445",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  {email}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isSmallScreen ? "12px" : "14px",
                    color: "#2b3445",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  {phone}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isSmallScreen ? "12px" : "14px",
                    color: "#2b3445",
                    border: "none",
                    paddingY: 0,
                  }}
                >
                  {joindate}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={isEditDialogOpen}
        onClose={handleEditDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUserData.firstName}
                onChange={(e) =>
                  setEditedUserData({
                    ...editedUserData,
                    firstName: e.target.value,
                  })
                }
                size="small"
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUserData.lastName}
                onChange={(e) =>
                  setEditedUserData({
                    ...editedUserData,
                    lastName: e.target.value,
                  })
                }
                size="small"
              />
            </Grid>

            {/* Email */}
            <Grid item xs={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUserData.email}
                onChange={(e) =>
                  setEditedUserData({
                    ...editedUserData,
                    email: e.target.value,
                  })
                }
                size="small"
              />
            </Grid>

            {/* Phone */}
            <Grid item xs={6}>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUserData.phone}
                onChange={(e) =>
                  setEditedUserData({
                    ...editedUserData,
                    phone: e.target.value,
                  })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUserData.password}
                onChange={(e) =>
                  setEditedUserData({
                    ...editedUserData,
                    password: e.target.value,
                  })
                }
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityOffIcon
                          onClick={handleShowPasswordToggle}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityIcon
                          onClick={handleShowPasswordToggle}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Location */}
            <Grid item xs={6}>
              <TextField
                label="Location"
                variant="outlined"
                fullWidth
                margin="normal"
                value={editedUserData.location}
                onChange={(e) =>
                  setEditedUserData({
                    ...editedUserData,
                    location: e.target.value,
                  })
                }
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <Box sx={{ padding: 2 }}>
          <Button
            onClick={handleSaveChanges}
            color="error"
            variant="contained"
            fullWidth
          >
            Save Changes
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
};

export default Profile;

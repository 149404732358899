// EditAttributeForm.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useEditAttribute, useDeletePortionAttributeOption } from "../../query/productsQuery";
import { Delete } from "@mui/icons-material";
import { MenuItemAttribute } from "../../types/products";

interface EditAttributeFormProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void; // Replace 'any' with the actual type of your data
    editAttribute: MenuItemAttribute | null;
  }
  
  const EditAttributeForm: React.FC<EditAttributeFormProps> = ({
    open,
    onClose,
    onSubmit,
    editAttribute,
  }) => {
  const editAttributeMutation = useEditAttribute();
  const deleteAttributeMutation = useDeletePortionAttributeOption();

  const defaultAttributeOptions = [
    {
      Id: 1,
      MenuItemAttributeId: 1,
      Name: "",
      Option: "",
    },
  ];

  const [menuItemAttribute, setMenuItemAttribute] = useState
  //<MenuItemAttribute>
  (
    {
    Id: 0,
    Name: "",
    Option:"",
    Variation: false,
    Visible: true,
    MenuItemAttributeOptions: defaultAttributeOptions,
  }
  // {
  //   Id: editAttribute?.Id || 1,
  //  Name: editAttribute?.Name || "",
  //   Variation: editAttribute?.Variation || false,
  //   Visible: editAttribute?.Visible || true,
  //   MenuItemAttributeOptions:
  //     editAttribute?.MenuItemAttributeOptions?.map((option) => ({
  //       ...option,
  //     })) || defaultAttributeOptions,
  // }
);

  useEffect(() => {
    if (open && editAttribute) {
      setMenuItemAttribute((prev) => ({
        ...prev,
        ...editAttribute,
        MenuItemAttributeOptions:
          editAttribute.MenuItemAttributeOptions?.map((option) => ({
            ...option,
          })) || defaultAttributeOptions,
      }));
    }
  }, [open, editAttribute]);

  const handleAttributeChange = (
    name: keyof typeof menuItemAttribute,
    value: string | boolean
  ) => {
    setMenuItemAttribute((prev) => ({ ...prev, [name]: value }));
  };

  const handleOptionChange = (
    index: number,
    name: keyof typeof defaultAttributeOptions[0],
    value: string
  ) => {
    setMenuItemAttribute((prev) => {
      const newOptions = [...prev.MenuItemAttributeOptions];
      newOptions[index] = { ...newOptions[index], [name]: value, };
      return { ...prev, MenuItemAttributeOptions: newOptions };
    });
  };

  const handleAddOption = () => {
    setMenuItemAttribute((prev) => {
      const newOptions = [
        ...prev.MenuItemAttributeOptions,
        {
          Id: prev.MenuItemAttributeOptions.length + 1,
          MenuItemAttributeId: prev.Id,
          Name: "",
          Option: "",
        },
      ];
      return { ...prev, MenuItemAttributeOptions: newOptions };
    });
  };

  const handleDeleteOption = async (index: any) => {
    try {
      const optionIdToDelete =
        menuItemAttribute.MenuItemAttributeOptions[index].Id;
      await deleteAttributeMutation.mutateAsync(optionIdToDelete);

      setMenuItemAttribute((prev) => {
        const newOptions = [...prev.MenuItemAttributeOptions];
        newOptions.splice(index, 1);
        return { ...prev, MenuItemAttributeOptions: newOptions };
      });
    } catch (error) {
      console.error("Error deleting option:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const formattedData = {
        ...menuItemAttribute,
        MenuItemAttributeOptions: menuItemAttribute.MenuItemAttributeOptions.map((option) => ({
          ...option,
        })),
      };

      await editAttributeMutation.mutateAsync({
        id: formattedData.Id,
        data: formattedData,
      });

      onSubmit(formattedData);
      onClose();
    } catch (error) {
      console.error("Error submitting attribute:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Edit Attribute</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            background: "#fff",
            marginBottom: 2,
            width: "100%",
          }}
        >
          <TextField
            label="Attribute Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={menuItemAttribute.Name}
            onChange={(e) => handleAttributeChange("Name", e.target.value)}
            size="small"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={menuItemAttribute.Variation}
                onChange={(e) =>
                  handleAttributeChange("Variation", e.target.checked)
                }
              />
            }
            label="Variation"
          />
          {menuItemAttribute.Variation && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuItemAttribute.Visible}
                  onChange={(e) =>
                    handleAttributeChange("Visible", e.target.checked)
                  }
                />
              }
              label="Visible"
            />
          )}
          {menuItemAttribute.Variation && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {menuItemAttribute.MenuItemAttributeOptions.map((option, index) => (
                <Box
                  key={option.Id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 2,
                    width: "100%",
                    background: "#F5F5F5",
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                >
                  <TextField
                    label={`Option Name ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={option.Option}
                    onChange={(e) =>
                      handleOptionChange(index, "Option", e.target.value)
                    }
                    size="small"
                  />
                  {/* 
                  <TextField
                    label={`Option Value ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={option.Option}
                    onChange={(e) =>
                      handleOptionChange(index, "Option", e.target.value)
                    }
                    size="small"
                  /> */}
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteOption(index)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ))}
              <IconButton color="error" onClick={handleAddOption}>
                <PlusCircleOutlined />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Update Attribute
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAttributeForm;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiProduct } from "../../types/products";
import { errorMessage } from "../../components/common/messageBox/Messages";

interface CartItem {
  taxRate: number;
  MenuItemPortions: any;
  Discount: any;
  Price: any;
  product: Omit<ApiProduct, "Id"> & { Id: number };
  quantity: number;
  Id: number;
}

interface CartState {
  items: CartItem[];
}

const loadState = (): CartState => {
  try {
    const serializedState = localStorage.getItem("cart");
    if (serializedState === null) {
      return { items: [] };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return { items: [] };
  }
};

const saveState = (state: CartState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("cart", serializedState);
  } catch (err) {
    errorMessage();
  }
};

const initialState: CartState = loadState();

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action: PayloadAction<ApiProduct>) {
      const { Id, ...productWithoutId } = action.payload;

      const existingItemIndex = state.items.findIndex(
        (item) => item.product.Id === Id
      );

      const openingStock =
        action.payload.MenuItemPortions[0]?.StockLimit || 0;

      if (existingItemIndex !== -1 && openingStock > 0) {
        state.items[existingItemIndex].quantity += 1;
      } else if (openingStock > 0) {
        state.items.push({
          product: { ...productWithoutId, Id, Price: action.payload.Price },
          quantity: 1,
          Id,
          Price: undefined,
          taxRate: 0,
          MenuItemPortions: undefined,
          Discount: undefined,
        });
      } else {
        console.log(`Product "${action.payload.Name}" is out of stock.`);
      }

      saveState(state);
    },
    removeFromCart(state, action: PayloadAction<number>) {
      state.items = state.items.filter((item) => item.Id !== action.payload);
      saveState(state);
    },
    clearCart(state) {
      state.items = [];
      saveState(state);
    },
    increaseQuantity(state, action: PayloadAction<number>) {
      const itemIndex = state.items.findIndex(
        (item) => item.Id === action.payload
      );
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity += 1;
        saveState(state);
      }
    },
    decreaseQuantity(state, action: PayloadAction<number>) {
      const itemIndex = state.items.findIndex(
        (item) => item.Id === action.payload
      );
      if (itemIndex !== -1 && state.items[itemIndex].quantity > 1) {
        state.items[itemIndex].quantity -= 1;
        saveState(state);
      }
    },
  },
});

export const { reducer: cartReducer, actions } = cartSlice;
export const {
  addToCart,
  removeFromCart,
  clearCart,
  increaseQuantity,
  decreaseQuantity,
} = cartSlice.actions;

import React from "react";
import PrivacyPolicy from "../../containers/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <div>
        <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicy;

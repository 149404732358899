import { useMutation, useQuery } from "react-query";
import {
  createOrder,
  getAccount,
  getAllOrder,
  getAllOrderManagement,
  getAllPurchase,
  getFinance,
  getOrder,
} from "../services/order";
import { ErrorToast } from "../components/forms/toast";
import OrderData from "../types/order";

export const useGetOrderQuery = (orderId: number) =>
  useQuery(["order", orderId], () => getOrder(orderId), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useGetAccountQuery = () =>
  useQuery(["account"], () => getAccount(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useGetFinancialQuery = () =>
  useQuery(["finance"], () => getFinance(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useGetAllOrderQuery = (userId: string) =>
  useQuery(["allorder", userId], () => getAllOrder(userId), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useGetAllOrderManagementQuery = (
  start: string,
  end: string,
  year: string
) => {
  return useQuery(["orderManagement", start, end, year], () =>
    getAllOrderManagement(start, end, year)
  );
};

export const useOrderData = () => {
  return useQuery<OrderData>("latestOrder", async () => {
    const storedOrderData = localStorage.getItem("latestOrder");
    if (storedOrderData) {
      return JSON.parse(storedOrderData);
    }
    return null;
  });
};
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const useCreateOrderMutation = () => {
  return useMutation((orderData: OrderData) => createOrder(orderData), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });
};

export const useGetPurchaseQuery = () =>
  useQuery(["purchase"], () => getAllPurchase(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

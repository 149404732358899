import React from "react";
import Banner from "./banner";

import { useGetCategoryQuery } from "../../query/categoryQuery";

const HeroSection = () => {
  
  // const { data: categories = [] } = useGetCategoryQuery();

  return (
    <div className="flex flex-col lg:flex-row mt-8 overflow-hidden">
      {/* <CategoryBanner categories={categories} /> */}
      <Banner />
    </div>
  );
};

export default HeroSection;

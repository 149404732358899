import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  useAddProduct,
  useGetAttributeQuery,
} from "../../../query/productsQuery";
import { useNavigate } from "react-router-dom";
import { ApiProduct, MenuItemAttribute } from "../../../types/products";
import { ArrowCircleDownOutlined } from "@mui/icons-material";
import { ApiCategory } from "../../../types/category";
import { IUnitType } from "../../../types/branch";
import { useGetUnitTypeQuery } from "../../../query/branch";
import { useGetCategoryQuery } from "../../../query/categoryQuery";
import AddProductPortion from "./addProductPortion";

interface IProps {
  formData: ApiProduct;
  setFormData: Dispatch<SetStateAction<ApiProduct>>;
  onSubmit: any;
  MenuItemPortions: any[];
  MenuItemPhotos: any[];
}
interface ILists {
  label: string;
  value: any;
}

const AddProductForm: React.FC<IProps> = ({
  formData,
  onSubmit,
  setFormData,
}) => {
  const navigate = useNavigate();

  const responseCategory = useGetCategoryQuery();
  const responseUnitType = useGetUnitTypeQuery();

  const [categoryList, setCategoryList] = useState<any>([]);
  const [unittypeList, setUnittypeList] = useState<any>([]);
  const addProductMutation = useAddProduct({
    onSuccess: () => {
      navigate("/vendor-dashboard/product-list");
    },
  });

  const getCategoryList = () => {
    if (responseCategory.isSuccess) {
      setCategoryList(
        responseCategory.data.map((item: ApiCategory) => ({
          label: item.Name,
          value: item.Id,
        }))
      );
    }
  };
  
  const getUnitTypeList = () => {
    if (responseUnitType.isSuccess) {
      setUnittypeList(
        responseUnitType.data.map((item: IUnitType) => ({
          label: item.Name,
          value: item.Id,
        }))
      );
    }
  };
  

  useEffect(() => {
    getCategoryList();
    getUnitTypeList();
  }, [responseCategory.isSuccess, responseUnitType.isSuccess]);
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addProductMutation.mutate(formData);
    console.log(formData);
  };

  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ background: "#fff", padding: 4 }}>
        <Typography variant="h5" component="h2" sx={{ textAlign: "center" }}>
          Add Product
        </Typography>
        <form onSubmit={handleSubmit}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowCircleDownOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1" component="h2">
                Product Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} sx={{ marginBottom: 4 }}>
                <Grid item xs={12}>
                  <TextField
                    label="Product Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.Name}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        Name: String(e.target.value),
                      }))
                    }
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={categoryList}
                    getOptionLabel={(option: ILists) => option.label}
                    value={
                      categoryList.find(
                        (category: ILists) =>
                          category.value === formData.categoryId
                      ) || null
                    }
                    onChange={(e, newValue) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        categoryId: newValue ? newValue.value : null,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product category"
                        variant="outlined"
                        size="small"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    options={unittypeList}
                    getOptionLabel={(option: ILists) => option.label}
                    value={
                      unittypeList.find(
                        (unitType: ILists) =>
                          unitType.value === formData.UnitType
                      ) || null
                    }
                    onChange={(e, newValue) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        UnitType: newValue ? newValue.value : null,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Unit Type"
                        variant="outlined"
                        size="small"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    options={unittypeList}
                    getOptionLabel={(option: ILists) => option.label}
                    value={
                      unittypeList.find(
                        (unitTypeBase: ILists) =>
                          unitTypeBase.value === formData.UnitTypeBase
                      ) || null
                    }
                    onChange={(e, newValue) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        UnitTypeBase: newValue ? newValue.value : null,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Unit Type Base"
                        variant="outlined"
                        size="small"
                       // required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Product CostPrice"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.CostPrice}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        CostPrice: Number(e.target.value),
                      }))
                    }
                    size="small"
                    required
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    label="Product UnitDivided"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.UnitDivided}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        UnitDivided: Number(e.target.value),
                      }))
                    }
                    size="small"
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Product Barcode"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.Barcode}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        Barcode: String(e.target.value),
                      }))
                    }
                    size="small"
                   // required
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    label="Product Tag"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.Tag}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        Tag: String(e.target.value),
                      }))
                    }
                    size="small"
                   // required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Product TaxRate"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.TaxRate}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        TaxRate: Number(e.target.value),
                      }))
                    }
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Product ExciseDuty"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.ExciseDuty}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        ExciseDuty: Number(e.target.value),
                      }))
                    }
                    size="small"
                  //  required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Product MarginRate"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.MarginRate}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        MarginRate: Number(e.target.value),
                      }))
                    }
                    size="small"
                  //  required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.Description}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        Description: String(e.target.value),
                      }))
                    }
                    size="small"
                    required
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Meta Description"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.MetaDescription}
                    onChange={(e) =>
                      setFormData((prevData: any) => ({
                        ...prevData,
                        MetaDescription: String(e.target.value),
                      }))
                    }
                    size="small"
                   // required
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              setFormData((prevData: any) => ({
                                ...prevData,
                                BestSeller: e.target.checked,
                              }))
                            }
                            checked={formData.BestSeller}
                          />
                        }
                        label="Best Seller"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              setFormData((prevData: any) => ({
                                ...prevData,
                                FeaturedSeller: e.target.checked,
                              }))
                            }
                            checked={formData.FeaturedSeller}
                          />
                        }
                        label="Featured Seller"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              setFormData((prevData: any) => ({
                                ...prevData,
                                IsMenuItem: e.target.checked,
                              }))
                            }
                            checked={formData.IsMenuItem}
                          />
                        }
                        label="Menu Item"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              setFormData((prevData: any) => ({
                                ...prevData,
                                IsProduct: e.target.checked,
                              }))
                            }
                            checked={formData.IsProduct}
                          />
                        }
                        label="Is Product"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              setFormData((prevData: any) => ({
                                ...prevData,
                                IsService: e.target.checked,
                              }))
                            }
                            checked={formData.IsService}
                          />
                        }
                        label="Is Service"
                      />
                    </FormGroup>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowCircleDownOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1" component="h2">
                Product Portion
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddProductPortion
                formData={formData}
                setFormData={setFormData}
                MenuItemPortions={[]}
                onSubmit={onSubmit}
              />
            </AccordionDetails>
          </Accordion>

          <Button
            type="submit"
            variant="contained"
            color="error"
            disabled={addProductMutation.isLoading}
            sx={{ mt: 4, width: "100%" }}
          >
            {addProductMutation.isLoading ? "Adding..." : "Add Product"}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AddProductForm;

import axiosClient from "../lib/axiosInterceptor";
import { ApiCategory } from "../types/category";

export const getCategory = async (): Promise<ApiCategory[]> => {
  const res = await axiosClient.get<ApiCategory[]>("api/MenuCategoryAPI");
  return res.data;
};

export const addCategory = async (data: ApiCategory) => {
  const response = await axiosClient.post("/api/MenuCategoryAPI/", data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const editCategory = async (id: number, data: ApiCategory) => {
  const response = await axiosClient.put(
    `/api/MenuCategoryAPI/?Id=${id}`,
    data
  );
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteCategory = async (id: string) => {
  const response = await axiosClient.delete(`/api/MenuCategoryAPI/?Id=${id}`);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

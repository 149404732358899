import React, { useState } from "react";
import {
  Container,
  Box,
  CardMedia,
  Button,
  Paper,
  Grid,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  useCreateOrderMutation,
  useGetAccountQuery,
  useGetFinancialQuery,
} from "../../query/orderQuery";
import { ErrorToast, SucessToast } from "../../components/forms/toast";
import axiosClient from "../../lib/axiosInterceptor";
import CartCheckout from "../../containers/checkout/cartCheckout";
import { clearCart } from "../../redux/features/cartSlice";
import { actions as cartActions } from "../../redux/features/cartSlice";
import { useGetUserQuery } from "../../query/authQuery";

const Checkout: React.FC = () => {
  const history = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const userId = useSelector((state: RootState) => state.auth.userId) ?? "";
  const { data: userData } = useGetUserQuery(userId);

  const username = userData?.data?.UserName ?? "";
  const firstName = userData?.data?.FirstName ?? "";
  const lastName = userData?.data?.LastName ?? "";
  const email = userData?.data?.Email ?? "";
  const phone = userData?.data?.PhoneNumber ?? "";
  const { data: accountData } = useGetAccountQuery();
  const { data: financialData } = useGetFinancialQuery();
  const accountid = accountData?.data?.[0];
  const financial = financialData?.data?.[0];
  const handleClearCart = () => {
    dispatch(cartActions.clearCart());
  };

  const storedAddresses = localStorage.getItem("addresses");
  const addresses = storedAddresses ? JSON.parse(storedAddresses) : [];

  const handleSubmit = async () => {
    const subtotal = cartItems.reduce((acc, item) => {
      return acc + item.quantity * item.product.CostPrice;
    }, 0);

    const orderDetailsArray = cartItems.map((item) => {
      const quantity = item.quantity;
      const price = item.product.CostPrice;

      return {
        BranchCode: "000001",
        BranchId: 5,
        DepartmentId: 1,
        CompanyCode: "000001",
        Discount: item?.Discount,
        ItemId: item?.Id || 0,
        Qty: quantity,
        TotalAmount: subtotal,
        UnitPrice: price,
        TaxRate: item?.taxRate || 0,
        UnitType: "PCS",
        UserName: username,
        WareHouseId: 65,
      };
    });

    const currentDate = new Date();
    const WorkDueEnglishDate = currentDate.toISOString();
    const WorkDueNepaliDate = "2080.04.27";
    const NepaliDate = "2080.04.25";
    const EnglishDate = currentDate.toISOString();
    const DueEnglishDate = "2023-12-03";
    const DueNepaliDate = "2080.081";

    if (accountid && financial && message) {
      try {
        const orderResponse = await axiosClient.post(
          `/api/OrderManagementAPI`,
          {
            AccountId: accountid,
            BranchId: 5,
            DepartmentId: 1,
            CompanyCode: "000001",
            WareHouseId: 65,
            FinancialYear: `${financial}`,
            WorkDueEnglishDate: WorkDueEnglishDate,
            WorkDueNepaliDate: WorkDueNepaliDate,
            Message: message,
            MessageStatement: message,
            NepaliDate: NepaliDate,
            EnglishDate: EnglishDate,
            DueEnglishDate: DueEnglishDate,
            DueNepaliDate: DueNepaliDate,
            OrderDetails: orderDetailsArray,
          }
        );

        const data = orderResponse.data;
        SucessToast("Order successful!");
        localStorage.setItem("orderId", data.toString());
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("latestOrder", JSON.stringify([]));
        handleClearCart();
        history("/customer-dashboard/orders");
      } catch (error) {
        console.error("Error creating orders:", error);
        ErrorToast("Error placing order. Please try again.");
      }
    }
  };

  return (
    <>
      {cartItems.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: 8,
          }}
        >
          <CardMedia
            component="img"
            alt="Product Image"
            image="https://assets.materialup.com/uploads/38936b35-9823-47e1-98cc-fb8b5c1d962e/preview.jpg"
            style={{ objectFit: "cover", width: "50%" }}
          />
          <Link to="/">
            <Button variant="outlined" color="error">
              Back To Shopping!
            </Button>
          </Link>
        </Box>
      ) : (
        <Container sx={{ marginTop: 4, display: "flex", gap: 4 }} maxWidth="xl">
          <Box sx={{ flex: "1 1 40%" }}>
            <CartCheckout />
          </Box>{" "}
          <Box sx={{ flex: "1 1 60%" }}>
            <Paper elevation={0} sx={{ padding: 2 }}>
              <Typography variant="h6" fontWeight="bold" mb={4}>
                Checkout
              </Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="User"
                      variant="outlined"
                      fullWidth
                      value={username}
                      onChange={(e) => setUser(e.target.value)}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => setUser(e.target.value)}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <Typography variant="subtitle1">My Address</Typography>
                      <RadioGroup
                        row
                        aria-label="address"
                        name="address"
                        value={selectedAddress}
                        onChange={(e) => setSelectedAddress(e.target.value)}
                      >
                        {addresses.map((address: any) => (
                          <Paper
                            key={address.name}
                            sx={{
                              padding: 2,
                              backgroundColor: "#fff",
                              display: "flex",
                              marginRight: 2,
                              marginBottom: 2,
                            }}
                          >
                            <FormControlLabel
                              value={address.name}
                              control={<Radio />}
                              label={
                                <Typography>
                                  <Typography>{address.name}</Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: "12px" }}
                                  >{`${address.streetAddress}, ${address.city}, ${address.state}, ${address.country}, ${address.zipCode}`}</Typography>
                                </Typography>
                              }
                            />
                          </Paper>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <FormControl component="fieldset">
                      <Typography variant="subtitle1">
                        Payment Method
                      </Typography>
                      <RadioGroup
                        row
                        aria-label="payment-method"
                        name="payment-method"
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <FormControlLabel
                          value="credit_card"
                          control={<Radio />}
                          label="Credit Card"
                        />
                        <FormControlLabel
                          value="paypal"
                          control={<Radio />}
                          label="Paypal"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="error"
                sx={{ width: "100%" }}
              >
                Place Order
              </Button>
            </Paper>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Checkout;

import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useAddAttribute } from "../../query/productsQuery";

interface AddAttributeFormProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void; // Replace 'any' with the actual type of your data
  }

  const AddAttributeForm: React.FC<AddAttributeFormProps> = ({
    open,
    onClose,
    onSubmit,
  }) => {
  const defaultAttributeOptions = [
    {
      Id: 1,
      MenuItemAttributeId: 1,
      Name: "",
      Option: "",
    },
  ];

  const [menuItemAttribute, setMenuItemAttribute] = useState(
    {
    Id: 0,
    Name: "",
    Variation: false,
    Visible: true,
    MenuItemAttributeOptions: defaultAttributeOptions,
  }
);

  const addAttributeMutation = useAddAttribute();

  const handleAttributeChange = (
    name: keyof typeof menuItemAttribute,
    value: string | boolean
  ) => {
    setMenuItemAttribute((prev) => ({ ...prev, [name]: value }));
  };


  const handleOptionChange = (
    index: number,
    name: keyof typeof defaultAttributeOptions[0],
    value: string
  ) => {
    setMenuItemAttribute((prev) => {
      const newOptions = [...prev.MenuItemAttributeOptions];
      newOptions[index] = { ...newOptions[index], [name]: value };
      return { ...prev, MenuItemAttributeOptions: newOptions };
    });
  };

  const handleAddOption = () => {
    setMenuItemAttribute((prev) => {
      const newOptions = [
        ...prev.MenuItemAttributeOptions,
        {
          Id: prev.MenuItemAttributeOptions.length + 1,
          MenuItemAttributeId: prev.Id,
          Name: "",
          Option: "",
        },
      ];
      return { ...prev, MenuItemAttributeOptions: newOptions };
    });
  };

  const handleSubmit = async () => {
    try {
      const formattedData = {
        ...menuItemAttribute,
        MenuItemAttributeOptions: menuItemAttribute.MenuItemAttributeOptions.map((option) => ({ ...option })),
      };

      await addAttributeMutation.mutateAsync(formattedData);

      onSubmit(formattedData);
      onClose();
    } catch (error) {
      console.error("Error submitting attribute:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Add Attribute</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            background: "#fff",
            marginBottom: 2,
            width: "100%",
          }}
        >
          <TextField
            label="Attribute Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={menuItemAttribute.Name}
            onChange={(e) => handleAttributeChange("Name", e.target.value)}
            size="small"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={menuItemAttribute.Variation}
                onChange={(e) =>
                  handleAttributeChange("Variation", e.target.checked)
                }
              />
            }
            label="Variation"
          />
          {menuItemAttribute.Variation && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuItemAttribute.Visible}
                  onChange={(e) =>
                    handleAttributeChange("Visible", e.target.checked)
                  }
                />
              }
              label="Visible"
            />
          )}
          {menuItemAttribute.Variation && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {menuItemAttribute.MenuItemAttributeOptions.map((option, index) => (
                <Box
                  key={option.Id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 2,
                    width: "100%",
                    background: "#F5F5F5",
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                >
                  {/* <TextField
                    label={`Option Name ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={option.Name}
                    onChange={(e) =>
                      handleOptionChange(index, "Name", e.target.value)
                    }
                    size="small"
                  /> */}
                   <TextField
                    label={`Option Name ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={option.Option}
                    onChange={(e) =>
                      handleOptionChange(index, "Option", e.target.value)
                    }
                    size="small"
                  />

                  {/* <TextField
                    label={`Option Name ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={option.Option}
                    onChange={(e) =>
                      handleOptionChange(index, "Option", e.target.value)
                    }
                    size="small"
                  /> */}
                </Box>
              ))}
              <IconButton color="error" onClick={handleAddOption}>
                <PlusCircleOutlined />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Add Attribute
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAttributeForm;

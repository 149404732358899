// store.ts
import { configureStore } from "@reduxjs/toolkit";
import { productsReducer } from "./features/productSlice";
import { cartReducer } from "./features/cartSlice";
import { wishlistReducer } from "./features/wishlistSlice";
import { authReducer } from "./features/authSlice";
import userSlice from "./features/userSlice";
import userRoleSlice from "./features/userRoleSlice";
import remeberSlice from "./features/remeberSlice";
import companySlice from "./features/companySlice";
import { currentYearSlice } from "./features/financialSlice";
import vendorAuthReducer from "./features/vendorSlice"; // Import the correct reducer
import { defaultDateSlice } from "./features/defaultSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productsReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    user: userSlice,
    userRoles: userRoleSlice,
    remember: remeberSlice,
    company: companySlice,
    financialYear: currentYearSlice.reducer,
    vendorAuth: vendorAuthReducer,
    defaultDate: defaultDateSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

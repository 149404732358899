import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
  FormControlLabel, // Add Checkbox import
} from "@mui/material";
import { PlusCircleFilled } from "@ant-design/icons";
import {
  useAddProductPhotoDetails,
  useDeleteProductPhotoDetails,
  useEditProductPhotoDetails,
  useGetProductQuery,
} from "../../../query/productsQuery";
import { ApiProduct, MenuItemPhoto } from "../../../types/products";
import { uploadFileAPI } from "../../../services/products";
import { ErrorToast, SucessToast } from "../toast";
import Dropzone from "../Dropzone";
import { Check, Clear, Delete, Edit } from "@mui/icons-material";

interface IProps {
  MenuItemPortions: any[];
  formData: ApiProduct;
  setFormData: Dispatch<SetStateAction<ApiProduct>>;
  productId: any;
}

const AddProductPhotos: React.FC<IProps> = ({
  productId,
  formData,
  setFormData,
}) => {
  const [imageName, setImageName] = useState<string>("");
  const [sequence, setSequence] = useState<number>(0);
  const [isHomeSlide, setIsHomeSlide] = useState<boolean>(false); 
  const [refImage, setRefImage] = useState<File | undefined>();
  const { data: products, refetch } = useGetProductQuery();
  const product = products?.find((p) => p.Id.toString() === productId);
  const addProductPhotoMutation = useAddProductPhotoDetails();
  const deleteProductPhotoMutation = useDeleteProductPhotoDetails();
  const editProductPhotoMutation = useEditProductPhotoDetails();
  const [images, setImages] = useState<MenuItemPhoto[] | undefined>([]);
  const [editingPhoto, setEditingPhoto] = useState<MenuItemPhoto | null>(null);

  useEffect(() => {
    const fetchedImages = product?.MenuItemPhotos;
    setImages(fetchedImages);
    refetch();
  }, [product?.MenuItemPhotos]);

  const handleAddPhoto = async () => {
    try {
      if (!refImage) {
        ErrorToast("Please select an image.");
        return;
      }

      const photoDetails: MenuItemPhoto = {
        MenuItemPortionId: formData.Id,
        Id: 0,
        Name: imageName,
        HomeSlideId: isHomeSlide, 
        MenuItemId: productId,
        PhoteIdentity: refImage,
        IdentityFileName: imageName,
        IdentityFileType: "",
        Sequence: sequence,
      };

      const photoDetailsForBackend = {
        ...photoDetails,
        PhotoIdentity: photoDetails.PhoteIdentity,
      };

      const response = await addProductPhotoMutation.mutateAsync(
        photoDetailsForBackend
      );

      if (refImage) {
        await uploadFileAPI([refImage], "Product", response);
      }
      setRefImage(undefined);
      setImageName("");
      setSequence(0);
      setIsHomeSlide(false); 
      SucessToast("Image Added");
    } catch (err) {
      console.error("Error adding photo:", err);
      ErrorToast("Can't Add Image");
    }
  };

  const handleDelete = async (photoId: number) => {
    try {
      await deleteProductPhotoMutation.mutateAsync(photoId);
      SucessToast("Image Deleted");
    } catch (error) {
      console.error("Error deleting photo:", error);
      ErrorToast("Can't Delete Image");
    }
  };
  const handleEdit = (photo: MenuItemPhoto) => {
    setEditingPhoto({ ...photo }); 
  };

  const handleSaveEdit = async (editedPhoto: MenuItemPhoto) => {
    try {
      const editedPhotoDetails = {
        ...editedPhoto,
        PhotoIdentity: editedPhoto.PhoteIdentity,
      };

      await editProductPhotoMutation.mutateAsync({
        id: editedPhotoDetails.Id,
        data: editedPhotoDetails,
      });

      SucessToast("Image Updated");
      setEditingPhoto(null);
      refetch();
    } catch (err) {
      console.error("Error updating photo:", err);
      ErrorToast("Can't Update Image");
    }
  };

  const handleCancelEdit = () => {
    setEditingPhoto(null);
  };
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Image Name</TableCell>
              <TableCell>Sequence</TableCell>
              <TableCell>Is Home Slide</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {images?.map((image) => (
              <TableRow key={image.Id}>
                <TableCell>
                  {image?.PhoteIdentity ? (
                    <img
                      alt={image?.IdentityFileName}
                      src={`data:image/png;base64,${image?.PhoteIdentity}`}
                      height="40"
                      width="40"
                    />
                  ) : (
                    "noimg"
                  )}
                </TableCell>
                <TableCell>
                  {editingPhoto?.Id === image.Id ? (
                    <TextField
                      value={editingPhoto.Name}
                      size="small"
                      onChange={(e) =>
                        setEditingPhoto({
                          ...editingPhoto,
                          Name: e.target.value,
                        })
                      }
                    />
                  ) : (
                    image.Name
                  )}
                </TableCell>
                <TableCell>
                  {editingPhoto?.Id === image.Id ? (
                    <TextField
                      size="small"
                      value={editingPhoto.Sequence}
                      onChange={(e) =>
                        setEditingPhoto({
                          ...editingPhoto,
                          Sequence: Number(e.target.value),
                        })
                      }
                    />
                  ) : (
                    image.Sequence
                  )}
                </TableCell>
                <TableCell>
                  {editingPhoto?.Id === image.Id ? (
                    <Checkbox
                      checked={editingPhoto.HomeSlideId}
                      onChange={() =>
                        setEditingPhoto({
                          ...editingPhoto,
                          HomeSlideId: !editingPhoto.HomeSlideId,
                        })
                      }
                    />
                  ) : image.HomeSlideId ? (
                    "Yes"
                  ) : (
                    "No"
                  )}
                </TableCell>
                <TableCell>
                  {editingPhoto?.Id === image.Id ? (
                    <>
                      <IconButton
                        color="success"
                        onClick={() => handleSaveEdit(editingPhoto)}
                      >
                        <Check />
                      </IconButton>
                      <IconButton color="error" onClick={handleCancelEdit}>
                        <Clear />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(image)}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(image.Id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container spacing={2} sx={{ marginBottom: 4, marginTop: 2 }}>
        <Grid item xs={12} md={6}>
          <Dropzone onDrop={(files) => setRefImage(files[0])} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Image Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={imageName}
            onChange={(e) => setImageName(e.target.value)}
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Sequence"
            variant="outlined"
            fullWidth
            margin="normal"
            value={sequence}
            onChange={(e) => setSequence(Number(e.target.value))}
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setIsHomeSlide(!isHomeSlide)}
                checked={isHomeSlide}
              />
            }
            label="Is Home Slide"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlusCircleFilled />}
            onClick={handleAddPhoto}
          >
            Add Photo
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddProductPhotos;

import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import Box from "@mui/material/Box";
import {
  Button,
  CardMedia,
  Chip,
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Rating,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { ApiProduct, MenuItemPhoto } from "../../types/products";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import {
  addToCart,
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
} from "../../redux/features/cartSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/features/wishlistSlice";
import { Carousel, Divider, Progress } from "antd";
import SubHeading from "../../components/common/subHeading";
import Heading from "../../components/common/heading";
import ProductReviewForm from "./reviewForm";
import { getProduct } from "../../services/products";
import theme from "../../theme/theme";
import {
  useMenuItemPositionReviewsQuery,
  useSingleMenuItemPositionReviewQuery,
} from "../../query/productsQuery";

const ProductDetail: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const dispatch: AppDispatch = useDispatch();
  const products = useSelector((state: RootState) => state.products.data);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const wishlistItems = useSelector((state: RootState) => state.wishlist.items);
  const { data: reviewsData } = useMenuItemPositionReviewsQuery();
  const [product, setProduct] = useState<ApiProduct | null>(null);
  const portionId = product?.MenuItemPortions[0].Id;

  // const averageRating = useMemo(() => {
  //   if (!Array.isArray(reviewsData) || reviewsData.length === 0) return 0;
  //   const totalRating = reviewsData.reduce(
  //     (sum, review) => sum + review.Rating,
  //     0
  //   );
  //   return totalRating / reviewsData.length;
  // }, [reviewsData]);
  const averageRating = useMemo(() => {
    if (!Array.isArray(reviewsData) || reviewsData.length === 0) return 0;

    const portionReviews = reviewsData.filter(
      (review) => review.PortionId === portionId
    );

    if (portionReviews.length === 0) return 0;

    const totalRating = portionReviews.reduce(
      (sum, review) => sum + review.Rating,
      0
    );
    return totalRating / portionReviews.length;
  }, [reviewsData, portionId]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getProduct(productId || "");
        setProduct(response);
      } catch (error) {
        console.error("Error fetching product details", error);
        setProduct(null);
      }
    };

    if (productId) {
      fetchProduct();
    }
  }, [productId]);
  const firstPhoto: MenuItemPhoto | undefined = product?.MenuItemPhotos[0];
  if (!product) {
    return <div>Product not found</div>;
  }

  const isInCart = cartItems.some((item) => item.product.Id === product.Id);
  const isInWishlist = wishlistItems.some(
    (item) => item.product.Id === product.Id
  );

  const handleAddToCart = () => {
    dispatch(addToCart(product));
  };

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(product.Id));
  };

  const handleIncreaseQuantity = () => {
    dispatch(increaseQuantity(product.Id));
  };

  const handleDecreaseQuantity = () => {
    dispatch(decreaseQuantity(product.Id));
  };

  const handleToggleWishlist = () => {
    if (isInWishlist) {
      dispatch(removeFromWishlist(product.Id));
    } else {
      dispatch(addToWishlist(product));
    }
  };
  const onChange = (currentSlide: number) => {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        marginTop: 2,
        // padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            width: isSmallScreen ? "100%" : "80%",
            background: "#fff",
            paddingX: 4,
            paddingY: 4,
          }}
        >
          <Box
            sx={{
              flex: 1,
              marginRight: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: isSmallScreen ? "200px" : "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Carousel
                afterChange={onChange}
                autoplay
                dots={false}
                style={{
                  width: isSmallScreen ? "200px" : "400px",
                  height: isSmallScreen ? "200px" : "400px",
                }}
              >
                {product.MenuItemPhotos.map((photos) => (
                  <div key={photos.Id}>
                    <CardMedia
                      component="img"
                      alt="Product Image"
                      height="100%"
                      image={`data:${photos.IdentityFileType};base64, ${photos.PhoteIdentity}`}
                      style={{
                        objectFit: "contain",
                        borderRadius: "8px",
                        border: "1px solid #f1f1f1",
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </Box>
            <Box
              sx={{
                width: "60px",
                height: "60px",
                display: "flex",
                gap: 2,
                background: "#fff",
                marginTop: 4,
              }}
            >
              {product.MenuItemPortions[0].MenuItemPhotos &&
                product.MenuItemPortions[0].MenuItemPhotos.map((photos) => (
                  <CardMedia
                    component="img"
                    image={`data:${photos.IdentityFileType};base64, ${photos.PhoteIdentity}`}
                    sx={{
                      border: "1px solid red",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                ))}
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              paddingX: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  marginBottom: 1,
                }}
              >
                {product.Name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: "start",
              }}
            >
              <Rating
                value={averageRating}
                precision={0.5}
                size="large"
                readOnly
              />

              <Typography sx={{ fontSize: "12px" }} color="primary">
                2 Ratings | 10 Answered Questions
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Category
            </Typography>

            <Divider />
            <Typography
              variant="h5"
              paragraph
              sx={{
                marginBottom: 1,
                color: "#d94243",
              }}
            >
              Rs. {product.CostPrice}
            </Typography>
            <Divider />

            <div className="flex gap-2 items-center">
              {product.MenuItemPortions[0].MenuItemPortionAttributes.map(
                (attr, index) => (
                  <>
                    <Typography key={index} variant="subtitle1">
                      {attr.Name}
                    </Typography>
                    <div className="w-fit p-2 border border-gray-100 hover:border-[#d94342]  active:border-[#d94342] text-gray-500 rounded hover:shadow-sm cursor-pointer">
                      {attr.Option}
                    </div>
                  </>
                )
              )}
            </div>

            <Divider />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {isInCart && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <IconButton
                    sx={{
                      width: 40,
                      height: 40,
                      border: "1px solid #D84241",
                      borderRadius: 1,
                    }}
                    onClick={handleDecreaseQuantity}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography
                    sx={{
                      margin: "0 10px",
                      width: 40,
                      textAlign: "center",
                    }}
                  >
                    {cartItems.find((item) => item.product.Id === product.Id)
                      ?.quantity || 0}
                  </Typography>
                  <IconButton
                    sx={{
                      width: 40,
                      height: 40,
                      border: "1px solid #D84241",
                      borderRadius: 1,
                    }}
                    onClick={handleIncreaseQuantity}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
              <Box sx={{ display: "flex" }}>
                {!isInCart ? (
                  <Button
                    variant="contained"
                    size="large"
                    color="error"
                    sx={{
                      marginTop: 2,
                      marginRight: 2,
                      background: "#D84241",
                    }}
                    onClick={handleAddToCart}
                  >
                    Add to Cart
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    sx={{
                      marginTop: 2,
                      marginRight: 2,
                      background: "#D84241",
                    }}
                    onClick={handleRemoveFromCart}
                  >
                    Remove
                  </Button>
                )}

                <IconButton
                  sx={{
                    marginTop: 2,
                    marginRight: 2,
                  }}
                  onClick={handleToggleWishlist}
                >
                  {isInWishlist ? (
                    <FavoriteIcon
                      sx={{
                        color: "#D94342",
                        fontSize: 32,
                      }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      sx={{
                        color: "gray",
                        fontSize: 32,
                      }}
                    />
                  )}
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: isSmallScreen ? "100%" : "20%",
            background: "#eff0f5",
            paddingTop: 2,
            paddingX: 2,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Delivery Information
          </Typography>
          <Divider />

          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <AccessTimeIcon sx={{ marginRight: 1, color: "#757575" }} />
            Estimated Delivery:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2, marginLeft: 4 }}>
            2-3 days
          </Typography>
          <Divider />

          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <LocalShippingIcon sx={{ marginRight: 1, color: "#757575" }} />
            Free Delivery
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2, marginLeft: 4 }}>
            10 Dec - 15 Dec
          </Typography>
          <Divider />

          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <MonetizationOnIcon sx={{ marginRight: 1, color: "#757575" }} />
            Cash on Delivery Available
          </Typography>
          <Divider />

          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            Service
          </Typography>

          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <List>
              <ListItem> 7 Day Return</ListItem>
              <ListItem>Change of mind applicable</ListItem>
              <ListItem>Warranty not available</ListItem>
            </List>
          </Typography>
        </Box>
      </Box>

      {productId && <ProductReviewForm productId={productId} />}

      <Box sx={{ background: "#fff", marginY: 4 }}>
        <Typography
          sx={{ background: "#F5F5F5", padding: 2, fontWeight: "semibold" }}
        >
          Product details of {product.Name}.
        </Typography>
        <Box>
          <Typography variant="body2" sx={{ padding: 2, textAlign: "justify" }}>
            {product.Description}
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <span>&#8226;</span>
                  </ListItemIcon>
                  Barcode: {product.Barcode}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <span>&#8226;</span>
                  </ListItemIcon>
                  Tag: {product.Tag}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <span>&#8226;</span>
                  </ListItemIcon>
                  Unit Type: {product.UnitType}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <span>&#8226;</span>
                  </ListItemIcon>
                  Tax Rate: {product.TaxRate}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <span>&#8226;</span>
                  </ListItemIcon>
                  Excise Duty: {product.ExciseDuty}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <span>&#8226;</span>
                  </ListItemIcon>
                  SKU: {product.Barcode}
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Typography
            sx={{ background: "#F5F5F5", padding: 2, fontWeight: "semibold" }}
          >
            Product Variant of {product.Name}.
          </Typography>
          <Grid container spacing={2}>
            {product.MenuItemPortions.map((mportion, index) => (
              <Grid item sm={6} xs={12}>
                <ListItem>Variant: {mportion.Name}</ListItem>
                <ListItem>Stock: {mportion.OpeningStock}</ListItem>
                <List>
                  <ListItem>
                    Date on Sale From: {mportion.Date_on_Sale_From}
                  </ListItem>
                  <ListItem>
                    Date on Sale To: {mportion.Date_on_Sale_To}
                  </ListItem>
                  <ListItem>Length: {mportion.Length}</ListItem>
                  <ListItem>Height: {mportion.Height}</ListItem>
                  <ListItem>Net Weight: {mportion.Net_Weight}</ListItem>
                  <ListItem>Gross Weight: {mportion.Gross_Weight}</ListItem>
                </List>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <SubHeading label="Varients" />
      <Heading label="More Options" />
      <Grid container spacing={2}>
        {product.MenuItemPortions.map((portion) => (
          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Card
              sx={{
                position: "relative",
                width: "fit",
                backgroundColor: "white",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                transition: "transform 0.5s, box-shadow 0.5s",
                "&:hover": {
                  transform: "scale(1.01)",
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {firstPhoto && (
                <Link
                  to={`/products/${product.Id}`}
                  style={{ textDecoration: "none" }}
                >
                  <CardMedia
                    component="img"
                    alt="Product"
                    image={`data:${firstPhoto.IdentityFileType};base64, ${firstPhoto.PhoteIdentity}`}
                    sx={{
                      objectFit: "fit",
                      borderRadius: "5px 5px 0 0",
                      height: "140px",
                    }}
                  />
                </Link>
              )}

              <CardContent sx={{ padding: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Chip
                    size="small"
                    label="Brand"
                    variant="outlined"
                    color="error"
                  />
                  <Typography
                    variant="body2"
                    fontWeight="semibold"
                    sx={{
                      color: "#7D879C",
                      cursor: "auto",
                      fontSize: 16,
                    }}
                  >
                    Rs. {portion.Price}
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  fontWeight="semibold"
                  sx={{
                    color: "black",
                    textTransform: "capitalize",
                    fontSize: 16,
                    marginTop: 1,
                  }}
                >
                  {portion.Name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductDetail;

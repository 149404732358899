import { useQuery } from "react-query";
import { ErrorToast } from "../components/forms/toast";
import { getCompany, getCompanyById } from "../services/company";

export const useGetCompanyQuery = () =>
  useQuery(["company"], () => getCompany(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useGetCompanyByIdQuery = (companyId: number) =>
  useQuery(["company", companyId], () => getCompanyById(companyId), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

import React from "react";
import ShopLists from "../../containers/shop/shops";

const SellerPage = () => {
  return (
    <div>
      <ShopLists />
    </div>
  );
};

export default SellerPage;

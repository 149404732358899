import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { ApiCategory } from "../../types/category";
import { Link } from "react-router-dom";

interface CategoryCardProps {
  category: ApiCategory;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
  return (
    <Link to={`/category/${category.Id}`}>
      <Card
        sx={{
          borderRadius: "2px",
          marginRight: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          paddingY: "14px",
          marginY: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "border-color 0.3s, background-color 0.3s, color 0.3s",
          "&:hover": {
            backgroundColor: "#D94342",
            color: "#fff",
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "16px", fontWeight: "semibold" }}
        >
          {category.Name}
        </Typography>
      </Card>
    </Link>
  );
};

export default CategoryCard;

import axiosClient from "../lib/axiosInterceptor";
import { IBranch, IDepartment, IUnitType, IWarehouse } from "../types/branch";

export const getBranch = async (): Promise<IBranch[]> => {
  const res = await axiosClient.get<IBranch[]>("api/BranchAPI");
  return res.data;
};

export const getDepartment = async (): Promise<IDepartment[]> => {
  const res = await axiosClient.get<IDepartment[]>("api/DepartmentAPI");
  return res.data;
};

export const getWarehouse = async (): Promise<IWarehouse[]> => {
  const res = await axiosClient.get<IWarehouse[]>("api/WarehouseAPI");
  return res.data;
};

export const getUnitType = async (): Promise<IUnitType[]> => {
  const res = await axiosClient.get<IUnitType[]>("api/UnitType");
  return res.data;
};

export const getEnglishDate = async (Ndate: string) => {
  const response = await axiosClient.get(
    `/api/NepaliMonthAPI/NepaliMonthAPI/?NDate=${Ndate}`
  );
  try {
    return response.data;
  } catch {
    return -1;
  }
};
export const getNepaliDate = async (Ndate: string) => {
  const response = await axiosClient.get(
    `/api/NepaliMonthAPI/NepaliMonthAPI/?NDate=${Ndate}`
  );
  try {
    return response.data;
  } catch {
    return -1;
  }
};

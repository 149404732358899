import React, { useState } from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  TextField,
  Button,
  Box,
} from "@mui/material";

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = () => {
    // Add your logic to handle password change here
    // You can perform validation, make an API call, etc.
    console.log("Password change requested");
  };

  return (
    <Container>
      <Card sx={{ width: "100%", maxWidth: 400, margin: "0 auto" }}>
        <CardContent>
          <TextField
            label="Current Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            sx={{ marginBottom: 2 }}
            size="small"
          />
          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{ marginBottom: 2 }}
            size="small"
          />
          <TextField
            label="Confirm New Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ marginBottom: 2 }}
            size="small"
          />
          <Button
            variant="contained"
            color="error"
            onClick={handleChangePassword}
            sx={{ marginTop: 2, width: "100%" }}
          >
            Change Password
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ChangePasswordForm;

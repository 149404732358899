import React from "react";
import RegistrationForm from "../../components/forms/auth/signupForm";

const Register = () => {
  const handleSignup = (username: string, password: string) => {
    // Implement your login logic here
    console.log("Logging in with:", username, password);
  };
  return (
    <div>
      <RegistrationForm onRegister={handleSignup} />
    </div>
  );
};

export default Register;

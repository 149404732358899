// authQuery.ts

import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorToast, SucessToast } from "../components/forms/toast";
import { editUser, getAllRoles, getUser } from "../services/auth";
import { EditUserData, EditableUserSubset, UserData } from "../types/users";
import {
  verifyUser as apiVerifyUser,
  getUserApi as apiGetUser,
  getRolesApi as apiGetRoles,
  getAllUsers as apiGetAllUsers,
  addUser as apiAddUser,
  editUser as apiEditUser,
  deleteUser as apiDeleteUser,
  getAssignRoles,
  getAssignRole,
  updateAssignRole,
} from "../services/auth";
import { IUser, IUserLogin } from "../types/users";

export const useGetUserQuery = (userId: string) =>
  useQuery(["users"], () => getUser(userId), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useEditUser = (setIsOpen: (newValue: boolean) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: EditUserData) => {
      const editedSubset: EditableUserSubset = {
        firstName: payload.userData.firstName,
        lastName: payload.userData.lastName,
        email: payload.userData.email,
        phone: payload.userData.PhoneNumber,
        location: payload.userData.location,
      };
      return editUser(payload.userId, editedSubset as UserData);
    },
    onSuccess: (data) => {
      SucessToast(data.data.message);
      queryClient.invalidateQueries(["users"]);
      setIsOpen(false);
    },
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });
};

const useUserQuery = (id: string) => {
  return useQuery<IUser, Error>(["user", id], async () => {
    return apiGetUser(id);
  });
};

const useRolesQuery = (id: string) => {
  return useQuery<any, Error>(["roles", id], async () => {
    return apiGetRoles(id);
  });
};

const useAllRoleQuery = () => {
  return useQuery<IUser[], Error>("allRoles", async () => {
    return getAllRoles();
  });
};

const useAllUsersQuery = () => {
  return useQuery<IUser[], Error>("allUsers", async () => {
    return apiGetAllUsers();
  });
};

const useAddUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: IUser) => {
      return apiAddUser(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allUsers");
      },
    }
  );
};

// const useEditUserMutation = () => {
//   const queryClient = useQueryClient();

//   return useMutation(
//     async (params: { id: string; data: IUser }) => {
//       return apiEditUser(params.id, params.data);
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries("allUsers");
//       },
//     }
//   );
// };

const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      return apiDeleteUser(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allUsers");
      },
    }
  );
};

const useVerifyUserMutation = () => {
  return useMutation(async (credentials: IUserLogin) => {
    return apiVerifyUser(credentials);
  });
};

const useAssignRolesQuery = () => {
  return useQuery("assignRoles", async () => {
    return getAssignRoles();
  });
};

const useAssignRoleQuery = (id: string) => {
  return useQuery(["assignRole", id], async () => {
    return getAssignRole(id);
  });
};

const useUpdateAssignRoleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: { id: string; roleId: string }) => {
      return updateAssignRole(params.id, params.roleId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("assignRoles");
      },
    }
  );
};

export {
  useUserQuery,
  useRolesQuery,
  useAllUsersQuery,
  useAddUserMutation,
  // useEditUserMutation,
  useDeleteUserMutation,
  useVerifyUserMutation,
  useAssignRolesQuery,
  useAssignRoleQuery,
  useUpdateAssignRoleMutation,
  useAllRoleQuery,
};

// VendorRegister.tsx
import React from "react";
import VendorRegistrationForm from "../../components/forms/vendor/vendorRegister";

const VendorRegister = () => {
  const handleRegistration = (
    firstName: string,
    lastName: string,
    userName: string,
    email: string,
    phoneNumber: string,
    password: string,
    shopName: string,
    address: string,
    vat: string,
    file: File | null
  ) => {
    console.log("Registration details:", {
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      password,
      shopName,
      address,
      vat,
      file,
    });
  };

  return (
    <div>
      <VendorRegistrationForm onRegister={handleRegistration} />
    </div>
  );
};

export default VendorRegister;

// vendorSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface VendorAuthState {
  isLoggedIn: boolean;
}

const storedVendorAuthData = localStorage.getItem("vendorAuthData");

const initialState: VendorAuthState = {
  isLoggedIn: storedVendorAuthData ? true : false,
};

const vendorAuthSlice = createSlice({
  name: "vendorAuth",
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
      localStorage.setItem("vendorAuthData", JSON.stringify({ isLoggedIn: true }));
    },
    vendorLogout: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem("vendorAuthData");
    },
  },
});

export const { login, vendorLogout } = vendorAuthSlice.actions;
export const selectIsVendorLoggedIn = (state: RootState) => state.vendorAuth.isLoggedIn;

export default vendorAuthSlice.reducer;

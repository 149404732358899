import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Link, Box } from "@mui/material";
import WishlistItem from "./wishlistItem";
import { RootState, AppDispatch } from "../../redux/store";
import { actions as wishlistActions } from "../../redux/features/wishlistSlice";

const WishlistContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const wishlistItems = useSelector((state: RootState) => state.wishlist.items);

  const handleRemove = (productId: number) => {
    dispatch(wishlistActions.removeFromWishlist(productId));
  };

  return (
    <Box sx={{ p: 2, width: "400px" }}>
      {wishlistItems.length === 0 ? (
        <Typography sx={{ textAlign: "center" }}>
          Your wishlist is empty.
        </Typography>
      ) : (
        <Box>
          {wishlistItems.map((item: any) => (
            <WishlistItem
              key={item.product.Id}
              product={item.product}
              onRemove={() => handleRemove(item.product.Id)}
            />
          ))}
        </Box>
      )}
      <Typography sx={{ mt: 2, textAlign: "center" }} color="error">
          View All
      </Typography>
    </Box>
  );
};

export default WishlistContainer;

// AttributeList.jsx
import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Chip,
} from "@mui/material";
import { Person, Edit, Delete } from "@mui/icons-material";
import {
  useGetAttributeQuery,
  useDeleteAttribute,
} from "../../query/productsQuery";
import AddAttributeForm from "./AddAttributeForm";
import EditAttributeForm from "./EditAttributeForm";
import { ErrorToast } from "../../components/forms/toast";
import { MenuItemAttribute } from "../../types/products";

const AttributeList = () => {
  const { data: attributeData } = useGetAttributeQuery();
  const deleteAttributeMutation = useDeleteAttribute();

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    attributeId: null,
  });

  const [editAttribute, setEditAttribute] = useState<
    MenuItemAttribute | null
  >(null);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  const handleEdit = (attributeId: number) => {
    if (attributeData) {
      const attribute = attributeData.find((attr) => attr.Id === attributeId);

      if (attribute) {
        setEditAttribute({
          ...attribute,
          MenuItemAttributeOptions: attribute.MenuItemAttributeOptions || [],
        });
        setEditDialogOpen(true);
      } else {
        console.error(`Attribute with ID ${attributeId} not found`);
      }
    } else {
      console.error("Attribute data is undefined");
    }
  };

  const handleDelete = (attributeId: any) => {
    setDeleteConfirmation({ open: true, attributeId });
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation({ open: false, attributeId: null });
  };

  const handleConfirmDelete = async () => {
    try {
      const attributeId = deleteConfirmation.attributeId;
      if (attributeId !== null) {
        await deleteAttributeMutation.mutateAsync(attributeId);
        await deleteAttributeMutation.reset();
      } else {
        console.error("Attribute ID is null");
      }
    } catch (error) {
      ErrorToast("Error deleting attribute");
    } finally {
      handleCloseDeleteConfirmation();
    }
  };

  const handleSubmit = (data: MenuItemAttribute) => {
    console.log("Form submitted with data:", data);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <Person sx={{ color: "#D84241" }} />
          Product Attributes
        </Typography>
        <Button
          onClick={() => setAddDialogOpen(true)}
          variant="contained"
          color="error"
          sx={{
            background: "#fce9ec",
            color: "#D84241",
            boxShadow: "none",
            "&:hover": {
              background: "#fef2f2",
              color: "#D84241",
              boxShadow: "none",
            },
          }}
        >
          Add Attributes
        </Button>
      </Box>

      <Paper sx={{ boxShadow: "none", marginTop: 2 }}>
        <TableContainer component={Box}>
          <Table sx={{ border: "none" }}>
            <TableHead>
              <TableRow sx={{ border: "none" }}>
                <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Option</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributeData &&
                attributeData.map((attribute) => (
                  <TableRow key={attribute.Id}>
                    <TableCell>{attribute.Name}</TableCell>
                    <TableCell>
                      {attribute.MenuItemAttributeOptions &&
                      attribute.MenuItemAttributeOptions.length > 0 ? (
                        attribute.MenuItemAttributeOptions.map((option) => (
                          <Chip
                            key={option.Id}
                            variant="outlined"
                            label={option.Option}
                            size="small"
                            sx={{ marginRight: "2px" }}
                          />
                        ))
                      ) : (
                        <Chip
                          variant="outlined"
                          label={"No options"}
                          size="small"
                          color="error"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(attribute.Id)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(attribute.Id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={deleteConfirmation.open}
        onClose={handleCloseDeleteConfirmation}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this attribute?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AddAttributeForm
        open={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleSubmit}
      />
      <EditAttributeForm
        open={isEditDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        editAttribute={editAttribute}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default AttributeList;

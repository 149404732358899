// wishlistSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiProduct } from "../../types/products";

interface WishlistItem {
  product: Omit<ApiProduct, "Id"> & { Id: number }; 
}

interface WishlistState {
  items: WishlistItem[];
}

const loadState = (): WishlistState => {
  try {
    const serializedState = localStorage.getItem("wishlist");
    if (serializedState === null) {
      return { items: [] };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return { items: [] };
  }
};

const saveState = (state: WishlistState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("wishlist", serializedState);
  } catch (err) {
  }
};

const initialState: WishlistState = loadState();

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist(state, action: PayloadAction<ApiProduct>) {
      const { Id, ...productWithoutId } = action.payload;

      const existingItemIndex = state.items.findIndex(
        (item) => item.product.Id === Id
      );

      if (existingItemIndex === -1) {
        state.items.push({ product: { ...productWithoutId, Id } });
        saveState(state);
      }
    },
    removeFromWishlist(state, action: PayloadAction<number>) {
      state.items = state.items.filter((item) => item.product.Id !== action.payload);
      saveState(state);
    },
    clearWishlist(state) {
      state.items = [];
      saveState(state);
    },
  },
});

export const { reducer: wishlistReducer, actions } = wishlistSlice;
export const { addToWishlist, removeFromWishlist, clearWishlist } = wishlistSlice.actions;


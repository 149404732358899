import React, { useState, useEffect } from "react";
import {
  Chip,
  Typography,
  Container,
  Card,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { Delete, Edit, LocationCitySharp } from "@mui/icons-material";

interface AddressType {
  name: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  streetAddress: string;
}

const Address = () => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [addresses, setAddresses] = useState<Array<AddressType>>([
    {
      name: "Home",
      country: "Nepal",
      state: "Gandaki",
      city: "Pokhara",
      zipCode: "12345",
      streetAddress: "location-02",
    },
  ]);
  const [newAddress, setNewAddress] = useState<AddressType>({
    name: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    streetAddress: "",
  });
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    const storedAddresses = localStorage.getItem("addresses");
    if (storedAddresses) {
      setAddresses(JSON.parse(storedAddresses));
    }
  }, []);

  const saveAddressesToLocalStorage = (newAddresses: any) => {
    localStorage.setItem("addresses", JSON.stringify(newAddresses));
  };

  const handleAddAddressClick = () => {
    setEditDialogOpen(true);
    setSelectedAddressIndex(null);
  };

  const handleEditClick = (index: any) => {
    setEditDialogOpen(true);
    setSelectedAddressIndex(index);
    setNewAddress(addresses[index]);
  };

  const handleDeleteClick = (index: any) => {
    const updatedAddresses = [...addresses];
    updatedAddresses.splice(index, 1);
    setAddresses(updatedAddresses);
    saveAddressesToLocalStorage(updatedAddresses);
  };

  const handleAddDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedAddressIndex(null);
    setNewAddress({
      name: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      streetAddress: "",
    });
  };

  const handleSaveChanges = () => {
    if (selectedAddressIndex !== null) {
      // Edit existing address
      const updatedAddresses = [...addresses];
      updatedAddresses[selectedAddressIndex] = newAddress;
      setAddresses(updatedAddresses);
      saveAddressesToLocalStorage(updatedAddresses);
    } else {
      // Add new address
      const newAddresses = [...addresses, newAddress];
      setAddresses(newAddresses);
      saveAddressesToLocalStorage(newAddresses);
    }

    handleAddDialogClose();
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <LocationCitySharp sx={{ color: "#D84241" }} />
          My Addresses
        </Typography>
        <Button
          onClick={handleAddAddressClick}
          variant="contained"
          color="error"
          sx={{
            background: "#fce9ec",
            color: "#D84241",
            boxShadow: "none",
            "&:hover": {
              background: "#fef2f2",
              color: "#D84241",
              boxShadow: "none",
            },
          }}
        >
          Add New Address
        </Button>
      </Box>

      {addresses.map((address, index) => (
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 1,
            paddingY: 1,
            paddingX: 2,
            boxShadow: "none",
          }}
          key={address.name}
        >
          <Typography sx={{ fontSize: "14px", color: "#2b3445" }}>
            {address.name}
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#2b3445" }}>
            {address.country}, {address.state}, {address.city},{" "}
            {address.zipCode}
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#2b3445" }}>
            {address.streetAddress}
          </Typography>
          <Box>
            <IconButton onClick={() => handleEditClick(index)}>
              <Edit sx={{ fontSize: "18px" }} />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(index)}>
              <Delete sx={{ fontSize: "18px" }} />
            </IconButton>
          </Box>
        </Card>
      ))}

      <Dialog
        open={isEditDialogOpen}
        onClose={handleAddDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedAddressIndex !== null ? "Edit Address" : "Add Address"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newAddress.name}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, name: e.target.value })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Country"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newAddress.country}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, country: e.target.value })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newAddress.state}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, state: e.target.value })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newAddress.city}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, city: e.target.value })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Zip Code"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newAddress.zipCode}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, zipCode: e.target.value })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Street Address"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newAddress.streetAddress}
                onChange={(e) =>
                  setNewAddress({
                    ...newAddress,
                    streetAddress: e.target.value,
                  })
                }
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <Box sx={{ padding: 2 }}>
          <Button
            onClick={handleSaveChanges}
            color="error"
            variant="contained"
            fullWidth
          >
            Save Changes
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
};

export default Address;

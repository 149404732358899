import React, { useState } from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  Search,
  CheckCircle,
  HourglassEmpty,
  ErrorOutline,
  TrackChangesOutlined,
} from "@mui/icons-material";
import { Button } from "antd";

const TrackOrderPage = () => {
  const [orderId, setOrderId] = useState<string>("");
  const [orderStatus, setOrderStatus] = useState<string | null>(null);
  const [trackingInfo, setTrackingInfo] = useState<{
    date: string;
    description: string;
    time: string;
    location: string;
    status: string;
  } | null>(null);

  const fetchTrackingInfo = async (orderId: string) => {
    // Simulating an API call with static data
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Static tracking information
    const staticTrackingInfo = {
      date: "2023-12-10",
      description: "Out for delivery",
      time: "14:30",
      location: "Local Distribution Center",
      status: orderStatus || "Shipped", // Use selected status or default to "Shipped"
    };
    return staticTrackingInfo;
  };

  const handleTrackOrder = async () => {
    try {
      const result = await fetchTrackingInfo(orderId);
      setTrackingInfo(result);
    } catch (error) {
      console.error("Error fetching tracking information:", error);
    }
  };

  const steps = [
    { label: "Processing", icon: <HourglassEmpty /> },
    { label: "Shipped", icon: <CheckCircle /> },
    { label: "Delivered", icon: <CheckCircle /> },
  ];

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <TrackChangesOutlined sx={{ color: "#D84241" }} />
          Track Order
        </Typography>
      </Box>

      <Card sx={{ width: "100%" }}>
        <CardContent
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            label="Order ID"
            variant="outlined"
            fullWidth
            margin="normal"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            size="small"
          />
          <Button danger onClick={handleTrackOrder}>
            Track Order
          </Button>
        </CardContent>
      </Card>
      {trackingInfo && (
        <Box sx={{ marginTop: 3 }}>
          <Stepper activeStep={1} alternativeLabel sx={{ marginBottom: 4 }}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  icon={<span style={{ color: "#D84241" }}>{step.icon}</span>}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Paper sx={{ boxShadow: "none", paddingY: 2, marginTop: 2 }}>
            <TableContainer component={Box}>
              <Table sx={{ border: "none" }}>
                <TableHead>
                  <TableRow sx={{ border: "none" }}>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        color: "#7d879c",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        color: "#7d879c",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        color: "#7d879c",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      Time
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        color: "#7d879c",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      Location
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        color: "#2b3445",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      {trackingInfo.date}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        color: "#2b3445",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      {trackingInfo.description}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        color: "#2b3445",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      {trackingInfo.time}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        color: "#2b3445",
                        border: "none",
                        paddingY: 0,
                      }}
                    >
                      {trackingInfo.location}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
    </Container>
  );
};

export default TrackOrderPage;

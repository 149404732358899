import { createTheme } from "@mui/material/styles";

const customColors = {
  primary: {
    main: "#da4443",
    light: "#64b5f6",
    dark: "#1976d2",
    contrastText: "#fff",
  },
  secondary: {
    main: "#1e40af",
    light: "#ff79b0",
    dark: "#c60055",
    contrastText: "#fff",
  },
  error: {
    main: "#b91c1c",
    light: "#ff79b0",
    dark: "#c60055",
    contrastText: "#fff",
  },
};

// Create the theme
const theme = createTheme({
  palette: {
    primary: customColors.primary,
    secondary: customColors.secondary,
    error: customColors.error,
  },
});

export default theme;

import React, { useState } from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import { Email, ArrowForward } from "@mui/icons-material";
import Pagination from "../../components/common/pagination";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const InboxPage = () => {
  const [pageNo, setPageNo] = useState(1);
  const totalPageNumber = 4;
  // const userId = useSelector((state: RootState) => state.auth.userId) ?? "";

  // const { data: inboxData } = useGetInboxQuery(userId);
  // console.log(inboxData);

  const userInbox = [
    {
      messageId: "#f0ba538b-c8f3-45cd",
      sender: "John Doe",
      subject: "Meeting Tomorrow",
      content: "Hey, just a reminder about our meeting tomorrow at 10 AM.",
      date: "2023-05-01",
    },
    {
      messageId: "#f0ba538b-f3c3-45cd",
      sender: "Jane Doe",
      subject: "Project Update",
      content: "The latest project update is ready for your review.",
      date: "2023-05-02",
    },
  ];

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <Email sx={{ color: "#D84241", marginRight: "8px" }} />
          Inbox
        </Typography>
      </Box>

      {userInbox.map((message) => (
        <Card
          key={message.messageId}
          sx={{
            marginBottom: 1,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#2b3445",
                  fontWeight: "bold",
                }}
              >
                {message.sender}
              </Typography>
              <Typography sx={{ color: "#666" }}>{message.date}</Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            <Typography
              variant="h6"
              sx={{
                color: "#2b3445",
                marginTop: 1,
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              {message.subject}
            </Typography>
            <Typography sx={{ color: "#666", marginTop: 1 }}>
              {message.content}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <IconButton>
                <ArrowForward />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      ))}
      <Pagination
        page={totalPageNumber}
        setPageNo={setPageNo}
        pageNo={pageNo}
      />
    </Container>
  );
};

export default InboxPage;

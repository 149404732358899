import axiosClient from "../lib/axiosInterceptor";
import { UserData } from "../types/users";
import { IUser, IUserLogin } from "../types/users";

export const getUser = (userId: string) => {
  return axiosClient.get(`/api/UserAccountAPI/GetUser/?id=${userId}`);
};

export const editUser = (userId: string, userData: UserData) => {
  return axiosClient.put(
    `/api/UserAccountAPI/EditUser/?id=${userId}`,
    userData
  );
};

export const verifyUser = async ({ UserName, Password }: IUserLogin) => {
  const response = await axiosClient.post("/api/loginAPI", {
    UserName: UserName,
    Password: Password,
  });
  return response;
};

export const getUserApi = async (id: string) => {
  const response = await axiosClient.get(
    `/api/UserAccountAPI/GetUser/?id=${id}`
  );
  try {
    return response.data;
  } catch {
    return -1;
  }
};
export const getAllRoles = async () => {
  const res = await axiosClient.get(`/api/HotelRole/GetRoles/`);

  try {
    return res.data;
  } catch (error) {
    return -1;
  }
};
export const getRolesApi = async (id: string) => {
  const response = await axiosClient.get(`/api/HotelRole/GetRole/?id=${id}`);
  return response.data;
};
export const addRole = async (id: string) => {
  const res = await axiosClient.get(`/api/HotelRole/GetRole/?id=${id}`);
  try {
    return res.data[0];
  } catch (error) {
    return -1;
  }
};

export const getAllUsers = async () => {
  const response = await axiosClient.get("/api/UserAccountAPI/GetUsers/");
  return response.data;
};
export const addUser = async (data: IUser) => {
  const response = await axiosClient.post(
    `/api/UserAccountAPI/CreateUser/`,
    data
  );
  try {
    return response.data;
  } catch {
    return -1;
  }
};

export const editUsers = async (id: string, data: IUser) => {
  const res = await axiosClient.put(
    `/api/UserAccountAPI/EditUser/?id=${id}`,
    data
  );
  try {
    return res.data;
  } catch (error) {
    return -1;
  }
};

export const deleteUser = async (id: string) => {
  const response = await axiosClient.delete(
    `/api/UserAccountAPI/DeleteUser/?id=${id}`
  );
  try {
    return response.data;
  } catch {
    return -1;
  }
};
export const getAssignRoles = async () => {
  const res = await axiosClient.get(`/api/UserAccountAPI/GetUsers/`);
  try {
    return res.data;
  } catch (error) {
    return -1;
  }
};
export const getAssignRole = async (id: string) => {
  const res = await axiosClient.get(`/api/UserAccountAPI/GetUser/?id=${id}`);
  try {
    return res.data;
  } catch (error) {
    return -1;
  }
};

export const updateAssignRole = async (id: string, roleId: string) => {
  const res = await axiosClient.post(
    `/api/UserRoleAssign/?UserId=${id}&RoleId=${roleId}`
  );
  try {
    return res.data;
  } catch (error) {
    return -1;
  }
};

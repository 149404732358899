import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ApiCategory } from "../../types/category";
import {
  useAddCategoryQuery,
  useEditCategoryQuery,
} from "../../query/categoryQuery";

interface CategoryFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ApiCategory) => void;
  editCategory?: ApiCategory | null;
}

const CategoryForm: React.FC<CategoryFormProps> = ({
  open,
  onClose,
  onSubmit,
  editCategory,
}) => {
  const [menuItemCategory, setMenuItemCategory] = useState<ApiCategory>({
    Id: editCategory?.Id || 1,
    Name: editCategory?.Name || "",
    subCategories: editCategory?.subCategories || [],
  });

  useEffect(() => {
    if (open) {
      setMenuItemCategory({
        Id: editCategory?.Id || 1,
        Name: editCategory?.Name || "",
        subCategories: editCategory?.subCategories || [],
      });
    }
  }, [open, editCategory]);

  const handleCategoryChange = (name: keyof ApiCategory, value: string) => {
    setMenuItemCategory((prev) => ({ ...prev, [name]: value }));
  };

  const editCategoryMutation = useEditCategoryQuery();
  const addCategoryMutation = useAddCategoryQuery();

  const handleSubmit = async () => {
    try {
      if (editCategory) {
        await editCategoryMutation.mutateAsync({
          Id: menuItemCategory.Id,
          Name: menuItemCategory.Name,
          subCategories: menuItemCategory.subCategories,
        });
      } else {
        await addCategoryMutation.mutateAsync({ ...menuItemCategory });
      }

      onSubmit(menuItemCategory);
      onClose();
    } catch (error) {
      console.error("Error submitting category:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>{editCategory ? "Edit" : "Add"} Category</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            background: "#fff",
            padding: 1,
            marginBottom: 2,
            width: "100%",
          }}
        >
          <TextField
            label="Category Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={menuItemCategory.Name}
            onChange={(e) => handleCategoryChange("Name", e.target.value)}
            size="small"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {editCategory ? "Update" : "Add"} Category
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryForm;

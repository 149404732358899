import { ErrorToast, SucessToast } from "../../forms/toast";

export const LoginSuccess = () => SucessToast("Successfully Logged In");
export const LoginFailed = () => ErrorToast("Sorry, invalid credentials");
export const successMessage = (message?: string) =>
  SucessToast(message ? message : "Successfully added");
export const editMessage = () => SucessToast("Successfully updated");
export const deleteMessage = () => SucessToast("Successfully deleted");
export const errorMessage = (message?: string) => {
  ErrorToast();
  ErrorToast(message ? message : "Something went wrong");
};

export const deleteRowMessage = () => SucessToast("Item deleted successfully");
export const deletePRMessage = () =>
  SucessToast("Price Range deleted successfully");

import {
  useQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import * as product from "../services/products";
import {
  ApiProduct,
  MenuItemAttribute,
  MenuItemAttributeOption,
  MenuItemPhoto,
  MenuItemPortionAttribute,
  MenuItemPositionReview,
} from "../types/products";

// Queries
export const useGetProductQuery = () => {
  return useQuery<ApiProduct[]>("products", product.getProducts);
};

export const useGetProductsByCategory = (categoryId: number) => {
  return useQuery<ApiProduct[]>(["productsByCategory", categoryId], () =>
    product.getProductsByCategory(categoryId)
  );
};

export const useGetAllProducts = () => {
  return useQuery<ApiProduct[]>("allProducts", product.getAllProducts);
};

export const useGetProduct = (productId: string) => {
  return useQuery<ApiProduct>("product", () => product.getProduct(productId));
};

export const useAddProduct = (
  options?: UseMutationOptions<number, Error, ApiProduct>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, ApiProduct>(
    async (newProduct) => {
      const result = await product.addProduct(newProduct);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
        queryClient.invalidateQueries("allProducts");
      },
      ...options,
    }
  );
};

export const useAddProductPhotoDetails = (
  options?: UseMutationOptions<number, Error, MenuItemPhoto>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, MenuItemPhoto>(
    product.addProductPhotoDetails,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries("allProducts");
        queryClient.invalidateQueries("products");
        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useEditProduct = (
  options?: UseMutationOptions<number, Error, { id: string; data: ApiProduct }>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, { id: string; data: ApiProduct }>(
    async (variables) => {
      const response = await product.editProduct(variables.id, variables.data);
      return response; 
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
        queryClient.invalidateQueries("allProducts");
      },
      ...options,
    }
  );
};
export const useDeleteProduct = (
  options?: UseMutationOptions<number, Error, number>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(product.deleteProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      queryClient.invalidateQueries("allProducts");
    },
    ...options,
  });
};

export const useDeletePriceRange = (
  options?: UseMutationOptions<number, Error, number>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(product.deletePriceRange, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
    ...options,
  });
};

export const useDeleteProductItem = (
  options?: UseMutationOptions<number, Error, number>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(product.deleteProductItem, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
    ...options,
  });
};

export const useDeleteProductPhotoDetails = (
  options?: UseMutationOptions<number, Error, number>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(product.deleteProductPhotoDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
    ...options,
  });
};

export const useEditProductPhotoDetails = (
  options?: UseMutationOptions<
    number,
    Error,
    { id: number; data: MenuItemPhoto }
  >
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, { id: number; data: MenuItemPhoto }>(
    (variables) =>
      product.editProductPhotoDetails(variables.id, variables.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allProducts");
        queryClient.invalidateQueries("products");
      },
      ...options,
    }
  );
};

export const useGetAttributeQuery = () => {
  return useQuery<MenuItemAttribute[]>("attributes", product.getAttributes);
};

export const useGetSingleAttributeQuery = (id: number) => {
  return useQuery<MenuItemAttribute>("product", () =>
    product.getSingleAttribute(id)
  );
};

export const useAddAttribute = (
  options?: UseMutationOptions<number, Error, MenuItemAttribute>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, MenuItemAttribute>(
    (attribute) =>
      product.addAttribute({
        ...attribute,
        MenuItemAttributeOptions: attribute.MenuItemAttributeOptions,
      }),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries("attributes");
      },
      ...options,
    }
  );
};

export const useEditAttribute = (
  options?: UseMutationOptions<number, Error, { id: number; data: MenuItemAttribute }>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, { id: number; data: MenuItemAttribute }>(
    async (variables) => product.editAttribute(variables.id, variables.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('attributes');
      },
      ...options,
    }
  );
};

export const useDeleteAttribute = (
  options?: UseMutationOptions<number, Error, number>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(product.deleteAttribute, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("attributes");
    },
  });
};

export const useGetPortionAttributeQuery = () => {
  return useQuery<MenuItemPortionAttribute[]>(
    "portionAttributes",
    product.getPortionAttributes
  );
};

export const useEditPortionAttribute = (
  options?: UseMutationOptions<
    number,
    Error,
    { id: number; data: MenuItemPortionAttribute }
  >
) => {
  return useMutation<
    number,
    Error,
    { id: number; data: MenuItemPortionAttribute }
  >(
    (variables) => product.editPortionAttribute(variables.id, variables.data),
    options
  );
};

export const useDeletePortionAttribute = (
  options?: UseMutationOptions<number, Error, number>
) => {
  return useMutation<number, Error, number>(product.deleteAttribute, options);
};

export const useDeletePortionAttributeOption = (
  options?: UseMutationOptions<number, Error, number>
) => {
  return useMutation<number, Error, number>(
    product.deleteAttributeOption,
    options
  );
};
// export const useEditMenuItemPortion = (
//   options?: UseMutationOptions<
//     number,
//     Error,
//     { id: number; qty: number; price: number }
//   >
// ) => {
//   return useMutation<number, Error, { id: number; qty: number; price: number }>(
//     product.editMenuItemPortion,
//     options
//   );
// };

// export const useEditMenuItemPortionPriceRange = (
//   options?: UseMutationOptions<
//     number,
//     Error,
//     { id: number; minQty: number; maxQty: number; price: number }
//   >
// ) => {
//   return useMutation<
//     number,
//     Error,
//     { id: number; minQty: number; maxQty: number; price: number }
//   >(product.editMenuItemPortionPriceRange, options);
// };

export const useMenuItemPositionReviewsQuery = () => {
  return useQuery<MenuItemPositionReview[], Error>(
    "menuItemPositionReviews",
    product.getMenuItemPositionReviews
  );
};

export const useSingleMenuItemPositionReviewQuery = (productId: string) => {
  return useQuery<MenuItemPositionReview, Error>(
    ["menuItemPositionReview", productId],
    () => product.getSingleMenuItemPositionReview(productId)
  );
};

export const useAddMenuItemPositionReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, MenuItemPositionReview>(
    product.addMenuItemPositionReview,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("menuItemPositionReviews");
      },
    }
  );
};

export const useEditMenuItemPositionReviewMutation = (
  options?: UseMutationOptions<
    number,
    Error,
    { id: number; data: MenuItemPositionReview }
  >
) => {
  const queryClient = useQueryClient();

  return useMutation<
    number,
    Error,
    { id: number; data: MenuItemPositionReview }
  >(
    async (variables) =>
      product.editMenuItemPositionReview(variables.id, variables.data),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries("attributes");
      },
      ...options,
    }
  );
};
export const useDeleteMenuItemPositionReviewMutation = () => {
  return useMutation<void, Error, number>(product.deleteMenuItemPositionReview);
};

import React, { useState } from "react";
import AddProductForm from "../../components/forms/createProducts/addProduct";
import { Box, Container, List, ListItem } from "@mui/material";
import { ApiProduct } from "../../types/products";
import { useAddProduct } from "../../query/productsQuery";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const [formData, setFormData] = useState<ApiProduct>({
    Id: 0,
    Name: "",
    categoryId: 0,
    Barcode: "",
    Tag: "",
    UnitTypeBase: "",
    UnitDivided: 0.0,
    UnitType: "",
    DepartmentId: 1,
    WareHouseId: 1,
    BranchId: 1,
    IsProduct: false,
    IsService: false,
    IsMenuItem: false,
    Description: "",
    MetaDescription: "",
    PhoteIdentity: null,
    IdentityFileName: "",
    IdentityFileType: "",
    TaxRate: 13,
    ExciseDuty: 0.0,
    MarginRate: 0.0,
    CostPrice: 0.0,
    BestSeller: false,
    FeaturedSeller: false,
    Discount: null,
    Price: null,
    MenuItemPortions: [
      {
        Id: 0,
        Name: "",
        BestSeller: false,
        FeaturedSeller: false,
        Description: "",
        MetaDescription: "",
        ShortDescription: "",
        MenuItemPortionId: 0,
        Multiplier: 0,
        Price: 0.0,
        Discount: 0.0,
        OpeningStockRate: 0.0,
        OpeningStock: 0.0,
        OpeningStockAmount: 0.0,
        StockLimit: 0.0,
        ItemCode: "",
        Date_on_Sale_From: "",
        Date_on_Sale_To: "",
        On_Sale: false,
        Length: "",
        Height: "",
        Net_Weight: "",
        Gross_Weight: "",
        Disable_Buy_Button: false,
        Disable_Wishlist_Button: false,
        Available_For_Pre_Order: false,
        Call_for_price: false,
        MenuItemPortionPriceRanges: [
          {
            Id: 0,
            PositionId: 0,
            QtyMin: 0,
            QtyMax: 0,
            Price: 0,
          },
        ],
        MenuItemPortionAttributes: [
          { Id: 0, Name: "", Option: "", PositionId: 0 },
        ],
        MenuItemPhotos: [
          {
            Id: 0,
            Name: "",
            HomeSlideId: false,
            MenuItemId: 0,
            MenuItemPortionId: 0,
            PhoteIdentity: null,
            IdentityFileName: "",
            IdentityFileType: "",
            Sequence: 0,
          },
        ],
      },
    ],

    MenuItemPhotos: [
      {
        Id: 0,
        Name: "",
        HomeSlideId: false,
        MenuItemId: 0,
        MenuItemPortionId: 0,
        PhoteIdentity: null,
        IdentityFileName: "",
        IdentityFileType: "",
        Sequence: 0,
      },
    ],
  });
  const navigate = useNavigate();

  const addProductMutation = useAddProduct({
    onSuccess: () => {
      navigate("/vendor-dashboard/products");
    },
  });
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addProductMutation.mutate(formData);
  };
  return (
    <Container sx={{ display: "flex", alignItems: "start" }}>
      <AddProductForm
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        MenuItemPortions={[]}
        MenuItemPhotos={[]}
      />
    </Container>
  );
};

export default AddProduct;

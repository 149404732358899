import React from "react";
import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Clear } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { actions as cartActions } from "../../redux/features/cartSlice";

const CartCheckout: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);

  const handleIncrease = (productId: number) => {
    dispatch(cartActions.increaseQuantity(productId));
  };

  const handleDecrease = (productId: number) => {
    dispatch(cartActions.decreaseQuantity(productId));
  };

  const handleRemove = (productId: number) => {
    dispatch(cartActions.removeFromCart(productId));
  };

  return (
    <div>
      {" "}
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems.map((item: any) => (
              <TableRow key={item.product.Id}>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="body1">
                        {item.product.Name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          gap: 1,
                        }}
                      >
                        <Typography variant="body2">
                          Rs. {item.product.CostPrice} X {item.quantity}
                        </Typography>
                        <Typography variant="body2" color="error">
                          Rs.{" "}
                          {(item.quantity * item.product.CostPrice).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <IconButton
                      onClick={() => handleDecrease(item.product.Id)}
                      sx={{
                        width: { xs: 20, md: 30 },
                        height: { xs: 20, md: 30 },
                        border: "1px solid #D84241",
                        borderRadius: 1,
                      }}
                    >
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      sx={{
                        margin: { xs: "0 0px", md: "0" },
                        width: { xs: 20, md: 30 },
                        textAlign: "center",
                      }}
                    >
                      {item.quantity}
                    </Typography>
                    <IconButton
                      onClick={() => handleIncrease(item.product.Id)}
                      sx={{
                        width: { xs: 20, md: 30 },
                        height: { xs: 20, md: 30 },
                        border: "1px solid #D84241",
                        borderRadius: 1,
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleRemove(item.product.Id)}
                    sx={{
                      width: { xs: 20, md: 40 },
                      height: { xs: 20, md: 40 },
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default CartCheckout;

import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  CardMedia,
} from "@mui/material";
import { useGetCompanyByIdQuery } from "../../../query/companyQuery";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosClient from "../../../lib/axiosInterceptor";
import { ErrorToast, SucessToast } from "../toast";
import { useDispatch } from "react-redux";
import { login, GetUserName } from "../../../redux/features/authSlice";
import { useGetUserQuery } from "../../../query/authQuery";

interface LoginFormProps {
  onLogin: (username: string, password: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { data: vendor } = useGetCompanyByIdQuery(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const dispatch = useDispatch();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await axiosClient.post(`/api/loginAPI`, {
        UserName: username,
        Password: password,
      });

      const userId = res.data.Id;
      const UserName = res.data.UserName;

      const assignUserRole = async (userId: any) => {
        const rolesResponse = await axiosClient.get(`/api/HotelRole/GetRoles/`);
        const roles = rolesResponse.data;
        let roleName = "OnlineCustomer";

        let role = roles.find((r: any) => r.Name === roleName);

        if (!role) {
          const roleCreationResponse = await axiosClient.post(
            `/api/HotelRole/PostRole/`,
            {
              Name: roleName,
              Description: roleName,
              PermissionList: "Roles",
            }
          );

          role = roleCreationResponse.data;
        }

        await axiosClient.post(
          `/api/UserRoleAssign/?UserId=${userId}&RoleId=${role.Id}`
        );
      };

      assignUserRole(userId);

      Cookies.set("userId", res.data.Id);
      Cookies.set("UserName", res.data.UserName);
      console.log(userId);
      SucessToast("Login Success!");
      dispatch(login({ userId, username: UserName }));
      dispatch(GetUserName(UserName));
      navigate("/");
    } catch (err) {
      setError(err);
      ErrorToast("An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "full",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
          marginY: 8,
        }}
      >
        {vendor && (
          <CardMedia
            component="img"
            alt="Company Logo"
            image={`data:${vendor.IdentityFileType};base64,${vendor.PhotoIdentity}`}
            sx={{
              width: "80px",
            }}
          />
        )}
        <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
          Login to your account
        </Typography>
        <form
          style={{
            width: "100%",
            marginTop: 1,
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            size="small"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginTop: 1 }}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    color="primary"
                  />
                }
                label="Remember me"
                sx={{ marginRight: 0 }}
              />
            </Grid>
            <Grid item>
              <Link to="/">Forgot password?</Link>
            </Grid>
          </Grid>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="error"
            sx={{ marginTop: 2 }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Logging In..." : "Login"}
          </Button>
        </form>
        {error && (
          <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
            {error.message}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{ marginTop: 2, display: "flex", gap: "2px" }}
        >
          Don't have any account?
          <Link to="/signup" style={{ marginLeft: 2, color: "#D84241" }}>
            Sign Up
          </Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default LoginForm;
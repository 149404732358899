import React from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useGetSellerQuery } from "../../query/sellerQuery";

const ShopDetail: React.FC = () => {
  const { shopId } = useParams<{ shopId: string }>();
  const { data: vendors } = useGetSellerQuery();
  const filteredSeller = vendors
    ? vendors.filter((seller) => seller.Id === Number(shopId))
    : [];
  interface Statistic {
    value: number;
    label: string;
  }
  const stats: Statistic[] = [
    { value: 15, label: "All Orders" },
    { value: 16, label: "Awaiting Payments" },
    { value: 12, label: "Awaiting Shipment" },
  ];
  return (
    <Box sx={{ mt: 6 }}>
      {filteredSeller.length > 0 ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper elevation={2} sx={{ padding: 4, boxShadow: "none" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar
                      alt={filteredSeller[0].Name}
                      src="https://www.indiafilings.com/learn/wp-content/uploads/2023/03/How-can-I-register-my-shop-and-establishment-online.jpg"
                      sx={{
                        width: 60,
                        height: 60,
                      }}
                    ></Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      {filteredSeller[0].Name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "#7d879c" }}
                    >
                      {filteredSeller[0].Address}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "#7d879c" }}
                    >
                      {filteredSeller[0].PanNo}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Grid container spacing={2} sx={{ textAlign: "center" }}>
                  {stats.map((stat, index) => (
                    <Grid item key={index} xs={6} sm={4}>
                      <Card
                        sx={{ padding: 2, height: "100px", boxShadow: "none" }}
                      >
                        <Typography variant="h5" color="#D84241">
                          {stat.value}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "#7d879c" }}
                        >
                          {stat.label}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="subtitle1">Vendor not found</Typography>
      )}
    </Box>
  );
};

export default ShopDetail;

import React from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ProductCard from "../../containers/products/productCard";
import { ApiProduct } from "../../types/products";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { actions as cartActions } from "../../redux/features/cartSlice";
import { useGetProductQuery } from "../../query/productsQuery";
import { useGetCategoryQuery } from "../../query/categoryQuery";
import SubHeading from "../../components/common/subHeading";
import Heading from "../../components/common/heading";

const CategoryPage = () => {
  const { categoryId } = useParams();
  const { data: allProducts } = useGetProductQuery();
  const dispatch: AppDispatch = useDispatch();

  const { data: categoryName } = useGetCategoryQuery();

  const handleAddToCart = (product: ApiProduct) => {
    dispatch(cartActions.addToCart(product));
  };

  const filteredProducts = allProducts
    ? allProducts.filter((product) => product.categoryId === Number(categoryId))
    : [];
  const filteredCategory = categoryName
    ? categoryName.filter((category) => category.Id === Number(categoryId))
    : [];
const Name = filteredCategory.length > 0 ? filteredCategory[0].Name : "Default Name";
return (
    <Box sx={{ marginTop: 4 }}>
      <SubHeading label="Category" />
      
      <Heading label={`${Name} (${filteredProducts.length}) `} />
      {filteredProducts.length > 0 ? (
        <Grid container spacing={2}>
          {filteredProducts.map((product, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={2} xl={2}>
              <ProductCard
                product={product}
                onAddToCart={() => handleAddToCart(product)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6">
          {filteredCategory.length > 0
            ? `No products in ${filteredCategory[0].Name} category`
            : "Category not found"}
        </Typography>
      )}
    </Box>
  );
};

export default CategoryPage;

// services/products.ts
import axiosClient from "../lib/axiosInterceptor";
import { ApiCompanyInfo } from "../types/company";

export const getCompany = async (): Promise<ApiCompanyInfo[]> => {
  const res = await axiosClient.get<ApiCompanyInfo[]>("/api/company");
  return res.data;
};

export const getCompanyById = async (
  companyId: number
): Promise<ApiCompanyInfo> => {
  const res = await axiosClient.get<ApiCompanyInfo>(
    `/api/company/${companyId}`
  );
  return res.data;
};

export const addCompanyApi = async (data: ApiCompanyInfo) => {
  const response = await axiosClient.post("/api/company", data);
  return response.data[0];
};

export const getAllFinancialYearApi = async () => {
  const response = await axiosClient.get("/api/FinancialYearAPI/");
  try {
    return response.data;
  } catch {
    return -1;
  }
};

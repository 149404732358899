import axios, { HeadersDefaults } from 'axios';
import Cookies from 'js-cookie';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = 'http://account.dcubeitsolution.com';

export const BASE_URL = 'http://account.dcubeitsolution.com';



export default axiosClient;

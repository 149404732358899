import axiosClient from "../lib/axiosInterceptor";
import {
  ApiProduct,
  MenuItemAttribute,
  MenuItemAttributeOption,
  MenuItemPortionAttribute,
  MenuItemPositionReview,
} from "../types/products";

export const getProducts = async (): Promise<ApiProduct[]> => {
  const res = await axiosClient.get<ApiProduct[]>("/api/MenuItemAPI");
  return res.data;
};

export const getProductsByCategory = async (
  categoryId: number
): Promise<ApiProduct[]> => {
  const res = await axiosClient.get<ApiProduct[]>(
    `/api/MenuItemAPI?categoryId=${categoryId}`
  );
  return res.data;
};

export const getAllProducts = async (): Promise<ApiProduct[]> => {
  const response = await axiosClient.get<ApiProduct[]>("/api/MenuItemAPI/");
  return response.data;
};

export const getProduct = async (productId: string): Promise<ApiProduct> => {
  const response = await axiosClient.get<ApiProduct[]>(
    `/api/MenuItemAPI/?Id=${productId}`
  );
  return response.data[0];
};

export const addProduct = async (data: ApiProduct): Promise<number> => {
  const response = await axiosClient.post<number>("/api/MenuItemAPI/", data);
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const addProductPhotoDetails = async (data: any): Promise<number> => {
  const response = await axiosClient.post<number>(
    "/api/MenuItemPhotoAPI",
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};
export const deleteProductPhotoDetails = async (
  id: number
): Promise<number> => {
  const response = await axiosClient.delete<number>(
    `/api/MenuItemPhotoAPI?Id=${id}`
  );
  if (response.data === -1) {
    throw new Error("Unable to delete product photo details");
  }
  return response.data;
};

export const editProductPhotoDetails = async (
  id: number,
  data: any
): Promise<number> => {
  const response = await axiosClient.put<number>(
    `/api/MenuItemPhotoAPI?Id=${id}`,
    data
  );
  if (response.data === -1) {
    throw new Error("Unable to update product photo details");
  }
  return response.data;
};

export const uploadFileAPI = async (
  files: File[],
  moduleName: string,
  id: number
) => {
  const formData = new FormData();
  formData.append("newFileItem", files[0], files[0].name);
  return await axiosClient.post(
    `/api/FileUploadAPI/?moduleName=${moduleName}&id=${id}`,
    formData
  );
};

export const editProduct = async (
  id: string,
  data: ApiProduct
): Promise<number> => {
  const response = await axiosClient.put<number>(
    `/api/MenuItemAPI/${id}`,
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const deleteProduct = async (id: number): Promise<number> => {
  const response = await axiosClient.delete<number>(
    `/api/MenuItemAPI/?Id=${id}`
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const deletePriceRange = async (id: number): Promise<number> => {
  const response = await axiosClient.delete<number>(
    `/api/MenuItemPortionPriceRangeAPI/?Id=${id}`
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const deleteProductItem = async (id: number): Promise<number> => {
  const response = await axiosClient.delete<number>(
    `/api/MenuItemPortionAPI/?Id=${id}`
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};


export const editMenuItemPortion = async (
  id: number,
  qty: number,
  price: number
): Promise<number> => {
  const response = await axiosClient.put<number>(
    `/api/MenuItemPortionAPI?id=${id}&Qty=${qty}&QPrice=${price}`
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const editMenuItemPortionPriceRange = async (
  id: number,
  minQty: number,
  maxQty: number,
  price: number
): Promise<number> => {
  const response = await axiosClient.put<number>(
    `/api/MenuItemPortionPriceRangeAPI?id=${id}&MinQty=${minQty}&MaxQty=${maxQty}&QPrice=${price}`
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export function editProductService(
  id: string,
  data: ApiProduct
): Promise<number> {
  throw new Error("Function not implemented.");
}

export const getAttributes = async (): Promise<MenuItemAttribute[]> => {
  const response = await axiosClient.get<MenuItemAttribute[]>(
    "/api/MenuItemAttribute"
  );
  return response.data;
};

export const getSingleAttribute = async (
  id: number
): Promise<MenuItemAttribute> => {
  const response = await axiosClient.get<MenuItemAttribute[]>(
    `/api/MenuItemAttribute/${id}`
  );
  return response.data[0];
};
export const addAttribute = async (
  data: MenuItemAttribute
): Promise<number> => {
  const response = await axiosClient.post<number>(
    "/api/MenuItemAttribute",
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const editAttribute = async (
  id: number,
  data: MenuItemAttribute
): Promise<number> => {
  const response = await axiosClient.put<number>(
    `/api/MenuItemAttribute/${id}`,
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const deleteAttribute = async (id: number): Promise<number> => {
  const response = await axiosClient.delete<number>(
    `/api/MenuItemAttribute/${id}`
  );
  return response.data;
};

export const getPortionAttributes = async (): Promise<
  MenuItemPortionAttribute[]
> => {
  const response = await axiosClient.get<MenuItemPortionAttribute[]>(
    "/api/MenuItemPortionAttribute"
  );
  return response.data;
};

export const addPortionAttribute = async (
  data: MenuItemPortionAttribute
): Promise<number> => {
  const response = await axiosClient.post<number>(
    "/api/MenuItemPortionAttribute",
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const editPortionAttribute = async (
  id: number,
  data: MenuItemPortionAttribute
): Promise<number> => {
  const response = await axiosClient.put<number>(
    `/api/MenuItemPortionAttribute/${id}`,
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const deletePortionAttribute = async (id: number): Promise<number> => {
  const response = await axiosClient.delete<number>(
    `/api/MenuItemPortionAttribute/${id}`
  );
  return response.data;
};

export const deleteAttributeOption = async (id: number): Promise<number> => {
  const response = await axiosClient.delete<number>(
    `/api/MenuItemAttributeOption/${id}`
  );
  return response.data;
};

export const getMenuItemPositionReviews = async (): Promise<
  MenuItemPositionReview[]
> => {
  const response = await axiosClient.get<MenuItemPositionReview[]>(
    "/api/MenuItemPositionReview"
  );
  return response.data;
};

export const getSingleMenuItemPositionReview = async (
  productId: string
): Promise<MenuItemPositionReview> => {
  const response = await axiosClient.get<MenuItemPositionReview>(
    `/api/MenuItemPositionReview/${productId}`
  );
  return response.data;
};

export const addMenuItemPositionReview = async (
  data: MenuItemPositionReview
): Promise<number> => {
  const response = await axiosClient.post<number>(
    "/api/MenuItemPositionReview",
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const editMenuItemPositionReview = async (
  id: number,
  data: MenuItemPositionReview
): Promise<number> => {
  const response = await axiosClient.put<number>(
    `/api/MenuItemPositionReview/${id}`,
    data
  );
  if (response.data === -1) {
    throw new Error("Invalid data");
  }
  return response.data;
};

export const deleteMenuItemPositionReview = async (
  id: number
): Promise<void> => {
  await axiosClient.delete(`/api/MenuItemPositionReview/${id}`);
};

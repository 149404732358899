import React from "react";
import { RootState } from "../redux/store";
import { Box, Container } from "@mui/system";
import { CssBaseline, FormControlLabel, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/footer";

const PrivacyPolicy: React.FC = () => {
    const company = useSelector((state: RootState) => state.company.data);
    return (
        <Container
        component="main"
        maxWidth="sm"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper
          elevation={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 3,
            marginY: 8,
          }}
        >
          <Header />
            <Grid container spacing={6}>
              <Grid item xs={12}>
              <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          padding: 1,
        }}
      >
              <img
          src={
            company?.PhotoIdentity
              ? "data:image/png;base64," + company.PhotoIdentity
              : "/Assets/logo.png"
          }
          style={{ width: "60px" }}
          alt="D"
        />

        </Box>                

        <Typography variant="h5" sx={{ mt: 2 }}>
          {company.NameEnglish}
        </Typography>

              </Grid>
            </Grid>
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
          Privacy Policy
          </Typography>
          <form
            style={{
              width: "100%",
              marginTop: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
This Privacy Policy explains how and why company collects, uses, and shares personal information when you interact with or use our Mobile App, Site or Service. It also includes any information company collects offline in connection with the Service, which we may combine with information from the Mobile App, Site and Service.
              </Grid>
            </Grid>
            <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            <br></br>
            Definitions
          </Typography>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <text>
                <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-word', overflowWrap: 'break-word', fontSize:16 }}>
                For the purposes of this Privacy Policy:
    </div>                   
                <br></br>
                </text>
              
<text>
    <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
    Account means a unique account created for You to access our Service or parts of our Service.
    </div>    
    <br></br>
</text>

<strong>Application </strong>
 refers to D Cube ERP System, the software program provided by the Company.
<br></br><br></br>
<strong>Company </strong>
(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to D. Cube I.T. Solution Pvt. Ltd. Phewa Marg, Pokhara-8.
<br></br><br></br>
<strong>Country </strong>
 refers to: Nepal
<br></br><br></br>
<strong>Device </strong>
 means any device that can access the Service such as a computer, a cellphone or a digital tablet.
<br></br><br></br>
<strong>Personal </strong>
Personal Datais any information that relates to an identified or identifiable individual.
<br></br><br></br>
<strong>Service </strong>
 refers to the Application.
<br></br><br></br>
<strong>Service Provider </strong>
 means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
<br></br><br></br>
<strong>Usage Data </strong>
Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
<br></br><br></br>
You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </Grid>
            </Grid>
            <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            <br></br><br></br>
            Changes to this Privacy Policy
          </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <br></br>
              We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
              <br></br><br></br>
We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
<br></br><br></br>
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
<br></br><br></br>
              </Grid>
              <Grid item xs={12}>
              <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            
            Contact Us
          </Typography>
              
If you have any questions about this Privacy Policy, You can contact us:
<br></br><br></br>
By email: yngautam@gmail.com
<br></br><br></br>
By phone number: +977-9860485920
              </Grid>              
            </Grid>
          </form>
          <Footer />
          <Typography
            variant="body2"
            sx={{ marginTop: 2, display: "flex", gap: "2px" }}
          >
            {/* <Link to="/seller-login" style={{ marginLeft: 2, color: "#D84241" }}>
              Sign in
            </Link> */}
          </Typography>
        </Paper>
      </Container>    
  );
};
export default PrivacyPolicy;
import { Box } from "@mui/material";
import React from "react";
import HeroSection from "../../components/homepage/heroSection";
import FlashSaleSection from "../../components/homepage/flashSale";
import Categories from "../../containers/category/category";
import BestSeller from "../../containers/products/bestSeller";
import ShopLists from "../../containers/shop/shops";
import Products from "../../containers/products/products";
import BrandSection from "../../components/homepage/brands";

const Homepage = () => {
  return (
    <>
      <HeroSection />
      <FlashSaleSection />
      <Categories />
      <BestSeller />
      <ShopLists />
      <Products />
      <BrandSection />
    </>
  );
};

export default Homepage;

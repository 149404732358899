import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { AxiosResponse } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { IUser, IUserLogin } from "../../../types/users";
import { IFinancialYear } from "../../../types/company";
import { getAllUsers, verifyUser } from "../../../services/auth";
import { useGetFinancialQuery } from "../../../query/orderQuery";
import { useGetCompanyQuery } from "../../../query/companyQuery";
import { setUserAction } from "../../../redux/features/userSlice";
import {
  clearRememberUserAction,
  setRememberUserAction,
} from "../../../redux/features/remeberSlice";
import { financialYearAction } from "../../../redux/features/financialSlice";
import { LoginFailed, LoginSuccess } from "../../common/messageBox/Messages";
import { Autocomplete, LinearProgress } from "@mui/material";
import CreateCompany from "./vendorRegister";
import { getAllFinancialYearApi, getCompany } from "../../../services/company";
import { setCompanyAction } from "../../../redux/features/companySlice";
import Cookies from "js-cookie";
import { login } from "../../../redux/features/vendorSlice";
import VendorRegister from "../../../pages/auth/vendorRegister";

const Login = () => {
  const company = useSelector((state: RootState) => state.company.data);
  const loginedUserId = useSelector((state: RootState) => state.user.data.Id);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [userDetails, setUserDetails] = useState<IUser[]>([]);
  const isLoggedIn = useSelector(
    (state: RootState) => state.vendorAuth.isLoggedIn
  );

  if (loginedUserId.length > 0) {
    history("/vendor-dashboard/product-list");
  }
  const [isAllDataLoaded, setIsAllDataLoaded] = useState<boolean>(true);
  const [financialYear, setFinancialYear] = useState<IFinancialYear[]>([]);

  const [loginData, setLoginData] = useState<IUserLogin>({
    UserName: "",
    Password: "",
    RememberMe: false,
    showPassword: false,
    financialYearValue: 0,
  });

  const getAllDetails = async () => {
    setIsAllDataLoaded(true);
    const response = await getAllUsers();
    if (response !== -1) {
      setUserDetails(response);
    }

    const response1 = await getAllFinancialYearApi();
    if (response1 !== -1) {
      setFinancialYear(response1);
    }
    const data = await getCompany();
    if (data.length > 0) {
      dispatch(setCompanyAction(data[0]));
    }

    setIsAllDataLoaded(false);
  };

  useEffect(() => {
    getAllDetails();
  }, []);

  const setAuthentication = async ({
    UserName,
    Password,
    financialYearValue,
    RememberMe,
  }: IUserLogin) => {
    try {
      const response: AxiosResponse<any, any> = await verifyUser({
        UserName: UserName,
        Password: Password,
      });

      Cookies.set("userId", response.data.Id);
      Cookies.set("UserName", response.data.UserName);
      Cookies.set("financialYearValue", String(financialYearValue));

      if (RememberMe) {
        Cookies.set("RememberMe", "true", { expires: 365 });
        Cookies.set("financialYearValue", String(financialYearValue));
      } else {
        Cookies.remove("RememberMe");
        Cookies.remove("financialYearValue");
      }

      dispatch(setUserAction(response.data));

      const currentFinancialYear = financialYear.find(
        (data) => data.Id === financialYearValue
      );
      dispatch(
        financialYearAction({
          EndDate: currentFinancialYear ? currentFinancialYear.EndDate : "",
          Id: currentFinancialYear ? currentFinancialYear.Id : 0,
          Name: currentFinancialYear ? currentFinancialYear.Name : "",
          NepaliEndDate: currentFinancialYear
            ? currentFinancialYear.NepaliEndDate
            : "",
          NepaliStartDate: currentFinancialYear
            ? currentFinancialYear.NepaliStartDate
            : "",
          StartDate: currentFinancialYear ? currentFinancialYear.StartDate : "",
        })
      );

      dispatch(login());
      history("/vendor-dashboard/product-list");
      return true;
    } catch {
      LoginFailed();
      dispatch(clearRememberUserAction());
    }
  };
  if (isLoggedIn) {
    history("/vendor-dashboard/product-list");
  }
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    localStorage.clear();
    const response = await setAuthentication({ ...loginData });
    if (response === true) {
      LoginSuccess();
    }
  };

  if (isAllDataLoaded) {
    return <LinearProgress />;
  } else if (company === undefined || company?.Id === 0) {
    return (
      <>
        <VendorRegister />
      </>
    );
  }
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          padding: 2,
        }}
      >
        <img
          src={
            company?.PhotoIdentity
              ? "data:image/png;base64," + company.PhotoIdentity
              : "/Assets/logo.png"
          }
          style={{ width: "60px" }}
          alt="D"
        />

        <Typography variant="h5" sx={{ mt: 2 }}>
          {company.NameEnglish}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          autoComplete="off"
          sx={{ mt: 1 }}
        >
          <TextField
            size="small"
            margin="normal"
            required
            fullWidth
            label="Username"
            helperText="Enter Username"
            autoFocus
            value={loginData.UserName}
            onChange={(e) =>
              setLoginData({ ...loginData, UserName: e.target.value })
            }
            error={loginData.UserName === undefined}
          />
          <TextField
            size="small"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            helperText="Enter Password"
            autoComplete="new-password"
            type={loginData.showPassword ? "text" : "password"}
            value={loginData.Password}
            error={loginData.Password === undefined}
            onChange={(e) =>
              setLoginData({ ...loginData, Password: e.target.value })
            }
          />
          <Autocomplete
            options={
              financialYear &&
              financialYear.map((data) => {
                return {
                  label: `Financial Year - ${data.Name}`,
                  value: data.Id,
                };
              })
            }
            onChange={(e, v) =>
              setLoginData({ ...loginData, financialYearValue: v.value })
            }
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                label="Financial Year "
                variant="outlined"
                helperText="Select Financial Year"
                required
                size="small"
                error={loginData.financialYearValue === undefined}
                fullWidth
                margin="normal"
              />
            )}
          />

          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={loginData.showPassword}
                onChange={(e) =>
                  setLoginData({
                    ...loginData,
                    showPassword: !loginData.showPassword,
                  })
                }
              />
            }
            label="Show Password"
          />
          <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            checked={loginData.RememberMe}
            onChange={() =>
              setLoginData({
                ...loginData,
                RememberMe: !loginData.RememberMe,
              })
            }
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
        <Typography
          variant="body2"
          sx={{ marginTop: 2, display: "flex", gap: "2px" }}
        >
          Don't have any account?
          <Link to="/seller-signup" style={{ marginLeft: 2, color: "#D84241" }}>
            Sign Up
          </Link>
        </Typography>
        <Typography sx={{ mt: 5, color: "grey" }}>
          © D. Cube I.T. Solution Pvt. Ltd, &nbsp;
          {"2014-" + new Date().getFullYear()}
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;

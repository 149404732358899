import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { ApiProduct } from "../../types/products";
import { actions as cartActions } from "../../redux/features/cartSlice";
import SubHeading from "../../components/common/subHeading";
import Heading from "../../components/common/heading";
import { Box, Grid } from "@mui/material";
import ProductCard from "./productCard";

const BestSeller: React.FC = () => {
  const bestSellerProducts = useSelector(
    (state: RootState) => state.products.data
  );
  const dispatch: AppDispatch = useDispatch();

  const handleAddToCart = (product: ApiProduct) => {
    dispatch(cartActions.addToCart(product));
  };

  const bestSellers = bestSellerProducts?.filter(
    (product: ApiProduct) => product.BestSeller
  );

  return (
    <Box sx={{ mt: 8 }}>
      <SubHeading label="Best Seller" />
      <Heading label="Our Best Selling" />
      <Grid container spacing={2}>
        {bestSellers?.map((product: ApiProduct, index: number) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
            <ProductCard
              product={product}
              onAddToCart={() => handleAddToCart(product)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BestSeller;

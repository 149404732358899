import React, { useEffect, useRef, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { Upload } from "@mui/icons-material";

interface InputRefProps extends HTMLInputElement {
  acceptedFiles: File[];
}

interface IFileExtend extends File {
  preview: string;
}

interface IDropzoneProps {
  onDrop: (files: File[]) => void;
  previewFiles?: File[];
}

const Dropzone: React.FC<IDropzoneProps> = ({ onDrop, previewFiles }) => {
  const inputRef = useRef<InputRefProps>(null);
  const [acceptedFiles, setAcceptedFiles] = useState<IFileExtend[]>(
    previewFiles?.map((file) => ({
      ...file,
      preview: URL.createObjectURL(file),
    })) || []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*" as unknown as Accept,
    maxFiles: 1,
    onDrop: (onDropAcceptedFiles) => {
      if (inputRef.current) {
        inputRef.current.acceptedFiles = onDropAcceptedFiles;
        onDrop(inputRef.current.acceptedFiles);
        setAcceptedFiles(
          onDropAcceptedFiles.map((file) => ({
            ...file,
            preview: URL.createObjectURL(file),
          }))
        );
        inputRef.current.value = '';
      }
    },
  });

  const Preview = acceptedFiles.map((file) => (
    <Box
      key={file.name}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        borderRadius: 2,
        width: 150,
        height: 150,
        boxSizing: "border-box",
        marginBottom: 1,
        marginRight: 1,
      }}
    >
      <img
        style={{ display: "block", width: "auto", height: "100%" }}
        alt={file.name}
        src={file.preview}
      />
    </Box>
  ));

  useEffect(() => {
    setAcceptedFiles(
      previewFiles?.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      })) || []
    );
  }, [previewFiles]);

  return (
    <Box
      sx={{
        border: "1px dashed",
        padding: "20px 30px",
        borderRadius: "10px",
        textAlign: "center",
        "&:hover": { borderColor: "primary.main" },
      }}
      {...getRootProps()}
      onClick={() => inputRef.current?.click()}
    >
      <input {...getInputProps()} accept="image/*" ref={inputRef} />

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {acceptedFiles.length !== 0 ? (
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {Preview}
          </Box>
        ) : (
          <>
            {isDragActive ? (
              <Typography sx={{ color: "primary.main" }}>
                Drop the product image here ...
              </Typography>
            ) : (
              <div className="flex flex-col items-center">
                <Upload />
                <Typography>
                  Drag drop the product image here, or click to select product
                  image
                </Typography>
              </div>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Dropzone;

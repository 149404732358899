import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Box,
  FormGroup,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditProduct,
  useGetAttributeQuery,
  useGetProduct,
} from "../../query/productsQuery";
import { ApiProduct, MenuItemAttribute } from "../../types/products";
import { ArrowCircleDownOutlined } from "@mui/icons-material";
import AddProductPortion from "../../components/forms/createProducts/addProductPortion";
import { Checkbox as AntdCheckbox, Divider } from "antd";
import { IUnitType } from "../../types/branch";
import { ApiCategory } from "../../types/category";
import { useGetCategoryQuery } from "../../query/categoryQuery";
import { useGetUnitTypeQuery } from "../../query/branch";
import AddProductPhotos from "../../components/forms/createProducts/imageAdd";
import { errorMessage } from "../../components/common/messageBox/Messages";
import AddProductPortionImage from "../../components/forms/createProducts/portionImage";
interface ILists {
  label: string;
  value: any;
}
const EditProductForm: React.FC = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const getProductQuery = useGetProduct(productId || "");

  const editProductMutation = useEditProduct();

  const [formData, setFormData] = useState<ApiProduct>({
    Id: 0,
    Name: "",
    categoryId: 0,
    Barcode: "",
    Tag: "",
    UnitTypeBase: "",
    UnitDivided: 0.0,
    UnitType: "",
    DepartmentId: 1,
    WareHouseId: 1,
    BranchId: 1,
    IsProduct: false,
    IsService: false,
    IsMenuItem: false,
    Description: "",
    MetaDescription: "",
    PhoteIdentity: null,
    IdentityFileName: "",
    IdentityFileType: "",
    TaxRate: 13,
    ExciseDuty: 0.0,
    MarginRate: 0.0,
    CostPrice: 0.0,
    BestSeller: false,
    FeaturedSeller: false,
    Discount: null,
    Price: null,
    MenuItemPortions: [
      {
        Id: 0,
        Name: "",
        BestSeller: false,
        FeaturedSeller: false,
        Description: "",
        MetaDescription: "",
        ShortDescription: "",
        MenuItemPortionId: 0,
        Multiplier: 0,
        Price: 0.0,
        Discount: 0.0,
        OpeningStockRate: 0.0,
        OpeningStock: 0.0,
        OpeningStockAmount: 0.0,
        StockLimit: 0.0,
        ItemCode: "",
        Date_on_Sale_From: "",
        Date_on_Sale_To: "",
        On_Sale: false,
        Length: "",
        Height: "",
        Net_Weight: "",
        Gross_Weight: "",
        Disable_Buy_Button: false,
        Disable_Wishlist_Button: false,
        Available_For_Pre_Order: false,
        Call_for_price: false,
        MenuItemPortionPriceRanges: [
          {
            Id: 0,
            PositionId: 0,
            QtyMin: 0,
            QtyMax: 0,
            Price: 0,
          },
        ],
        MenuItemPortionAttributes: [
          { Id: 0, Name: "", Option: "", PositionId: 0 },
        ],
        MenuItemPhotos: [
          {
            Id: 0,
            Name: "",
            HomeSlideId: false,
            MenuItemId: 0,
            MenuItemPortionId: 0,
            PhoteIdentity: null,
            IdentityFileName: "",
            IdentityFileType: "",
            Sequence: 0,
          },
        ],
      },
    ],

    MenuItemPhotos: [
      {
        Id: 0,
        Name: "",
        HomeSlideId: false,
        MenuItemId: 0,
        MenuItemPortionId: 0,
        PhoteIdentity: null,
        IdentityFileName: "",
        IdentityFileType: "",
        Sequence: 0,
      },
    ],
  });
  const responseCategory = useGetCategoryQuery();
  const responseUnitType = useGetUnitTypeQuery();
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [unittypeList, setUnittypeList] = useState<any>([]);
  const { refetch: refetchProduct } = useGetProduct(productId || "");

  useEffect(() => {
    const fetchData = async () => {
      if (responseCategory.isSuccess) {
        setCategoryList(
          responseCategory.data.map((item: ApiCategory) => ({
            label: item.Name,
            value: item.Id,
          }))
        );
      }

      if (responseUnitType.isSuccess) {
        setUnittypeList(
          responseUnitType.data.map((item: IUnitType) => ({
            label: item.Name,
            value: String(item.Id),
          }))
        );
      }

      await refetchProduct();

      if (getProductQuery.isSuccess) {
        console.log(getProductQuery.data);
        setFormData(getProductQuery.data || {});
      }
      setLoading(false);
    };

    fetchData();
  }, [
    responseCategory.isSuccess,
    responseUnitType.isSuccess,
    getProductQuery.isSuccess,
    refetchProduct,
    getProductQuery.data,
  ]);

  const handleInputChange = (name: keyof ApiProduct, value: any) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await editProductMutation.mutateAsync({
        id: productId!,
        data: formData,
      });
      console.log("API Response:", response);
      navigate("/vendor-dashboard/product-list");
    } catch (error) {
      console.error("Error editing product:", error);
      errorMessage();
    }
  };

  return (
    <Container>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Edit Product
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <form onSubmit={handleSubmit}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowCircleDownOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" component="h2">
              Product Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} sx={{ marginBottom: 4 }}>
              <Grid item xs={12}>
                <TextField
                  label="Product Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData?.Name || ""}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      Name: String(e.target.value),
                    }))
                  }
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={categoryList}
                  getOptionLabel={(option: ILists) => option.label}
                  value={
                    categoryList.find(
                      (category: ILists) =>
                        category?.value === formData?.categoryId
                    ) || null
                  }
                  onChange={(e, newValue) => {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      categoryId: newValue ? newValue.value : null,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product category"
                      variant="outlined"
                      size="small"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={unittypeList}
                  getOptionLabel={(option: ILists) => option.label}
                  value={
                    unittypeList.find(
                      (unitType: ILists) => unitType.value === formData.UnitType
                    ) || null
                  }
                  onChange={(e, newValue) => {
                    handleInputChange(
                      "UnitType",
                      newValue ? newValue.value : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unit Type"
                      variant="outlined"
                      size="small"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={unittypeList}
                  getOptionLabel={(option: ILists) => option.label}
                  value={
                    unittypeList.find(
                      (UnitTypeBase: ILists) =>
                        UnitTypeBase.value === formData.UnitTypeBase
                    ) || null
                  }
                  onChange={(e, newValue) => {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      UnitTypeBase: newValue ? newValue.value : null,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unit Type Base"
                      variant="outlined"
                      size="small"
                     // required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Product CostPrice"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.CostPrice}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      CostPrice: Number(e.target.value),
                    }))
                  }
                  size="small"
                  required
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Product UnitDivided"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.UnitDivided}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      UnitDivided: Number(e.target.value),
                    }))
                  }
                  size="small"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Product Barcode"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.Barcode}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      Barcode: String(e.target.value),
                    }))
                  }
                  size="small"
                  //required
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Product Tag"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.Tag}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      Tag: String(e.target.value),
                    }))
                  }
                  size="small"
                  //required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Product TaxRate"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.TaxRate}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      TaxRate: Number(e.target.value),
                    }))
                  }
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Product ExciseDuty"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.ExciseDuty}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      ExciseDuty: Number(e.target.value),
                    }))
                  }
                  size="small"
                 // required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Product MarginRate"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.MarginRate}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      MarginRate: Number(e.target.value),
                    }))
                  }
                  size="small"
                  //required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.Description}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      Description: String(e.target.value),
                    }))
                  }
                  size="small"
                  required
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Meta Description"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.MetaDescription}
                  onChange={(e) =>
                    setFormData((prevData: any) => ({
                      ...prevData,
                      MetaDescription: String(e.target.value),
                    }))
                  }
                  size="small"
                 // required
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            setFormData((prevData: any) => ({
                              ...prevData,
                              BestSeller: String(e.target.value),
                            }))
                          }
                          checked={formData.BestSeller}
                        />
                      }
                      label="Best Seller"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            setFormData((prevData: any) => ({
                              ...prevData,
                              FeaturedSeller: String(e.target.value),
                            }))
                          }
                          checked={formData.FeaturedSeller}
                        />
                      }
                      label="Featured Seller"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            setFormData((prevData: any) => ({
                              ...prevData,
                              IsMenuItem: String(e.target.value),
                            }))
                          }
                          checked={formData.IsMenuItem}
                        />
                      }
                      label="Menu Item"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            setFormData((prevData: any) => ({
                              ...prevData,
                              IsProduct: String(e.target.value),
                            }))
                          }
                          checked={formData.IsProduct}
                        />
                      }
                      label="Is Product "
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            setFormData((prevData: any) => ({
                              ...prevData,
                              IsService: String(e.target.value),
                            }))
                          }
                          checked={formData.IsService}
                        />
                      }
                      label="Is Service"
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowCircleDownOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" component="h2">
              Product Portion
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddProductPortion
              formData={formData}
              setFormData={setFormData}
              MenuItemPortions={[]}
              onSubmit={onsubmit}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowCircleDownOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" component="h2">
              Product Images
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddProductPhotos
              MenuItemPortions={[]}
              formData={formData}
              setFormData={setFormData}
              productId={productId}
            />
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Button
          type="submit"
          variant="contained"
          color="error"
          disabled={editProductMutation.isLoading}
          sx={{ mt: 4, width: "100%" }}
        >
          {editProductMutation.isLoading ? "Adding..." : "Update"}
        </Button>
      </form>
    </Container>
  );
};

export default EditProductForm;

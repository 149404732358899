import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { ErrorToast } from "../components/forms/toast";
import { addCategory, deleteCategory, editCategory, getCategory } from "../services/categories";
import { ApiCategory } from "../types/category";

export const useGetCategoryQuery = () =>
  useQuery(["category"], () => getCategory(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useAddCategoryQuery = (
  options?: UseMutationOptions<number, Error, ApiCategory>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, ApiCategory>(
    async (newCategory) => {
      const result = await addCategory(newCategory);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("category");
      },
      ...options,
    }
  );
};

export const useEditCategoryQuery = (
  options?: UseMutationOptions<number, Error, ApiCategory>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, ApiCategory>(
    async (editedCategory) => {
      const result = await editCategory(editedCategory.Id, editedCategory);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("category");
      },
      ...options,
    }
  );
};

export const useDeleteCategoryQuery = (
  options?: UseMutationOptions<number, Error, string>
) => {
  const queryClient = useQueryClient();

  return useMutation<number, Error, string>(
    async (categoryId) => {
      const result = await deleteCategory(categoryId);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("category");
      },
      ...options,
    }
  );
};
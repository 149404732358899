import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ApiProduct } from "../../types/products";

const WishlistItem: React.FC<{
  product: ApiProduct;
  onRemove: () => void;
}> = ({ product, onRemove }) => {
  const { Name, CostPrice, MenuItemPhotos } = product;
  const firstPhoto = MenuItemPhotos && MenuItemPhotos[0];

  return (
      <div className="mx-auto max-w-2xl px-4">
        <div role="list" className="border-b">
          <li className="flex items-center py-6">
            {firstPhoto && (
              <img
                src={`data:${firstPhoto.IdentityFileType};base64, ${firstPhoto.PhoteIdentity}`}
                alt={Name}
                className="h-16 w-16 flex-none rounded-md border border-gray-200"
              />
            )}
            <div className="ml-4 flex-auto">
              <h3 className="font-medium text-gray-900">{Name}</h3>
              <p className="text-gray-500">Rs. {CostPrice}</p>
            </div>
            <div className="ml-auto">
              <IconButton onClick={onRemove}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </div>
          </li>
        </div>
      </div>
  );
};

export default WishlistItem;

import axiosClient from "../lib/axiosInterceptor";
import OrderData from "../types/order";

export const getOrder = (orderId: number) => {
  return axiosClient.get<OrderData[]>(`/api/OrderManagementAPI/?id=${orderId}`);
};

export const getAccount = () => {
  return axiosClient.get(`/api/AccountAPI/getAll`);
};

export const getFinance = () => {
  return axiosClient.get(`/api/FinancialYearAPI`);
};

export const getAllOrder = (userId: string) => {
  return axiosClient.get<OrderData[]>(`/order/get-all-orders/${userId}`);
};

export const getAllOrderManagement = async (
  start: string,
  end: string,
  year: string
) => {
  try {
    const res = await axiosClient.get(
      `/api/OrderManagementAPI/?dFrom=${start}&dTo=${end}&FinancialYear=${year}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllPurchase = async () => {
  const response = await axiosClient.get("/api/MenuCategoryItemAPI/");
  try {
    return response.data;
  } catch {
    return -1;
  }
};


export const createOrder = async (orderData: OrderData) => {
  try {
    const response = await axiosClient.post(
      "/api/OrderManagementAPI",
      orderData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating orders:", error);
    throw error;
  }
};

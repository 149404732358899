import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import MobileMenu from "./components/layouts/mobilenav";
import AppRouter from "./routes/AppRouter";
import Toast from "./components/forms/toast";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        retryDelay: 5000,
        staleTime: 60000 * 5,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <MobileMenu />
      <Toast />
    </QueryClientProvider>
  );
}

export default App;

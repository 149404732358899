import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  createTheme,
  ThemeProvider,
  Drawer,
  Popover,
  Box,
  CardMedia,
  Avatar,
} from "@mui/material";
import {
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
  Close as CloseIcon,
  FavoriteBorder,
  ArrowDownwardOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import CartContainer from "../../containers/cart/cartContainer";
import WishlistContainer from "../../containers/wishlist/wishlist";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Profile from "../../containers/profile/profile";
import { useGetCompanyByIdQuery } from "../../query/companyQuery";
import { Link, useLocation } from "react-router-dom";
import { navItems } from "../../constants/data";
import { Button, Dropdown, Menu, Space } from "antd";
import { useGetCategoryQuery } from "../../query/categoryQuery";
import { selectIsVendorLoggedIn } from "../../redux/features/vendorSlice";

const theme = createTheme();

const Header: React.FC = () => {
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [wishlistOpen, setWishlistOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isLoggedIn = useSelector(selectIsVendorLoggedIn);

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const username = useSelector((state: RootState) => state.auth.username);

  const dispatch: AppDispatch = useDispatch();

  const cartItems = useSelector((state: RootState) => state.cart.items);
  const wishlistItems = useSelector((state: RootState) => state.wishlist.items);

  const handleViewCart = () => {
    setCartDrawerOpen(true);
  };

  const handleCartDrawerClose = () => {
    setCartDrawerOpen(false);
  };

  const handleWishlistClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setWishlistOpen(true);
  };

  const handleWishlistClose = () => {
    setWishlistOpen(false);
  };

  const handleProfile = () => {
    setProfileOpen(true);
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  const { data: vendor } = useGetCompanyByIdQuery(1);
  const { data: categoryData } = useGetCategoryQuery();

  const location = useLocation();
  const isNavItemActive = (url: string): boolean => {
    return location.pathname === url;
  };
  const menu = (
    <Menu>
      {categoryData &&
        categoryData.map((category) => (
          <Menu.Item key={category.Id}>
            <Link to={`/category/${category.Id}`}>{category.Name} </Link>
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
          backgroundColor: "#da4443",
          color: "white",
          flexDirection: "row",
          gap: 2,
          paddingX: 2,
          paddingY: 1.5,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Become a Seller
          </Box>
          <Box
            sx={{
              fontSize: "14px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Payments & Recharge
          </Box>
          <Box
            sx={{
              fontSize: "14px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Help & Support
          </Box>
        </Box>

        <Box>
          {isLoggedIn ? (
            <Link to="/vendor-dashboard/product-list">
              <Box
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  background: "white",
                  alignItems: "flex-end",
                  color: "#da4443",
                  paddingX: 2,
                  borderRadius: "2px",
                }}
              >
                Dashboard
              </Box>
            </Link>
          ) : (
            <Link to="/seller-login">
              <Box
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  background: "white",
                  alignItems: "flex-end",
                  color: "#da4443",
                  paddingX: 2,
                  borderRadius: "2px",
                }}
              >
                Seller Login
              </Box>
            </Link>
          )}
        </Box>
      </Box>
      <AppBar
        position="sticky"
        sx={{
          top: 0,
          zIndex: 999,
          borderBottom: "1px solid #e0e0e0",
          width: "100%",
          display: { xs: "none", sm: "none", md: "none", lg: "flex" },
        }}
        elevation={0}
       >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            backgroundColor: "white",
            paddingY: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              gap: 4,
            }}
          >
            {vendor && (
              <Link to="/">
                <CardMedia
                  component="img"
                  alt="Company Logo"
                  image={`data:${vendor.IdentityFileType};base64,${vendor.PhotoIdentity}`}
                  sx={{
                    width: "80px",
                  }}
                />
              </Link>
            )}

            <Dropdown trigger={["click"]} overlay={menu}>
              <Button onClick={(e) => e.preventDefault()} danger>
                <Space>All Categories</Space>
              </Button>
            </Dropdown>
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.url}
                style={{
                  textDecoration: "none",
                  color: isNavItemActive(item.url) ? "#D84241" : "inherit",
                  borderBottom: isNavItemActive(item.url)
                    ? "2px solid #D84241"
                    : "2px solid #fff",
                }}
              >
                {item.title}
              </Link>
            ))}
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", spaceX: 6, gap: 2 }}
          >
            <Box
              sx={{
                display: { md: "flex" },
                alignItems: "center",
                bgcolor: "#ffffff",
                paddingX: 2,
                borderRadius: "5px",
                border: "1px solid #da4443",
                width: "500px",
              }}
            >
              <IconButton sx={{ padding: 1 }}>
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="Search..."
                inputProps={{ "aria-label": "search" }}
                sx={{ flexGrow: 1 }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                spaceX: 4,
                gap: 2,
              }}
            >
              <IconButton onClick={handleWishlistClick}>
                <Badge badgeContent={wishlistItems.length} color="error">
                  <FavoriteBorder />
                </Badge>
              </IconButton>

              <IconButton onClick={handleViewCart}>
                <Badge
                  badgeContent={cartItems.length === 0 ? 0 : cartItems.length}
                  color="error"
                >
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </IconButton>

              {isAuthenticated ? (
                <>
                  <Avatar
                    onClick={handleProfile}
                    sx={{
                      backgroundColor: "#D94342",
                      cursor: "pointer",
                    }}
                  >
                    {username && username.charAt(0).toUpperCase()}
                  </Avatar>
                </>
              ) : (
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <IconButton
                    sx={{
                      backgroundColor: "#D94342",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#D94342",
                      },
                    }}
                  >
                    <PersonOutlinedIcon />
                  </IconButton>
                </Link>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <AppBar
        position="sticky"
        sx={{
          top: 0,
          zIndex: 999,
          borderBottom: "1px solid #e0e0e0",
          width: "100%",
          display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            backgroundColor: "white",
            paddingY: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {vendor && (
              <Link to="/">
                <CardMedia
                  component="img"
                  alt="Company Logo"
                  image={`data:${vendor.IdentityFileType};base64,${vendor.PhotoIdentity}`}
                  sx={{
                    width: "80px",
                  }}
                />
              </Link>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", spaceX: 4 }}>
            <IconButton onClick={handleViewCart}>
              <Badge badgeContent={cartItems.length} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {wishlistOpen && (
        <Popover
          open={wishlistOpen}
          anchorEl={anchorEl}
          onClose={handleWishlistClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <WishlistContainer />
        </Popover>
      )}

      {cartDrawerOpen && (
        <Drawer
          anchor="right"
          open={cartDrawerOpen}
          onClose={handleCartDrawerClose}
        >
          <Box sx={{ width: "320px", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Typography variant="h6">Shopping Cart</Typography>
              <IconButton onClick={handleCartDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            {cartItems.length === 0 ? (
              <Typography>Your cart is empty.</Typography>
            ) : (
              <CartContainer />
            )}
          </Box>
        </Drawer>
      )}
      {profileOpen && (
        <Popover
          open={profileOpen}
          anchorEl={anchorEl}
          onClose={handleProfileClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            marginTop: "40px",
          }}
        >
          <Profile />
        </Popover>
      )}
    </ThemeProvider>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Input,
  Checkbox,
  Rate,
  Divider,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Box } from "@mui/material";
import { useGetCategoryQuery } from "../../query/categoryQuery";
import { useGetProductQuery } from "../../query/productsQuery";

const FilterSidebar: React.FC = (onCategoryChange) => {
  const productsQuery = useGetProductQuery();
  const allProducts = productsQuery.data || [];
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 100]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedRatings, setSelectedRatings] = useState<number | null>(null);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  const { data: categories } = useGetCategoryQuery();
  const brands = ["Brand A", "Brand B", "Brand C"];
  const sizes = ["XXL", "XL", "LG", "MD", "SM", "XS"];
  const colors = [
    "#000",
    "#c1c1c1",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#2B2A4C",
    "#EA906C",
    "#B31312",
  ];

  const handlePriceChange =
    (type: "min" | "max") => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value);
      setPriceRange((prevRange) =>
        type === "min" ? [value, prevRange[1]] : [prevRange[0], value]
      );
    };

  const handleCheckboxChange =
    (value: string) => (event: CheckboxChangeEvent) => {
      setSelectedCategories((prev) =>
        event.target.checked
          ? [...prev, value]
          : prev.filter((v) => v !== value)
      );
    };

  const handleRatingsChange = (value: number) => {
    setSelectedRatings(value);
  };

  return (
    <Box
      sx={{
        width: 300,
        height: "",
        paddingY: 3,
        paddingX: 3,
        borderRight: "1px solid #f0f0f0",
        top: 0,
        background: "#fff",
        fontSize: 14,
        borderRadius: 2,
        boxShadow: "none",
      }}
    >
      <Typography.Title level={5} style={{ marginBottom: 8, color: "#666" }}>
        Price Range
      </Typography.Title>
      <Box style={{ display: "flex", marginBottom: 8, gap: 2 }}>
        <Input
          placeholder="min"
          value={priceRange[0]}
          onChange={handlePriceChange("min")}
        />
        <Input
          placeholder="max"
          value={priceRange[1]}
          onChange={handlePriceChange("max")}
        />
      </Box>
      <Divider />

      <Typography.Title level={5} style={{ marginBottom: 8, color: "#666" }}>
        Category
      </Typography.Title>
      <Checkbox.Group
        value={selectedCategories}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {categories?.map((category: any) => (
          <Checkbox
            key={category.Id}
            value={category.Id}
            onChange={handleCheckboxChange(category.Id)}
            style={{
              fontSize: 16,
              marginLeft: 5,
            }}
          >
            {category.Name}
          </Checkbox>
        ))}
      </Checkbox.Group>
      <Divider />

      <Typography.Title
        level={5}
        style={{ marginTop: 8, marginBottom: 8, color: "#666" }}
      >
        Ratings
      </Typography.Title>
      <Rate value={selectedRatings || 0} onChange={handleRatingsChange} />
      <Divider />

      <Typography.Title level={5} style={{ marginBottom: 8, color: "#666" }}>
        Size
      </Typography.Title>
      <div className="flex gap-2 ">
        {sizes.map((size, index) => (
          <div
            className="w-fit p-2 border rounded hover:shadow-sm cursor-pointer"
            key={size}
          >
            {size}
          </div>
        ))}
      </div>
      <Divider />

      <Typography.Title level={5} style={{ marginBottom: 8, color: "#666" }}>
        Brand
      </Typography.Title>
      <Checkbox.Group
        value={selectedBrands}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {brands.map((brand) => (
          <Checkbox
            key={brand}
            value={brand}
            onChange={() => handleCheckboxChange(brand)}
          >
            {brand}
          </Checkbox>
        ))}
      </Checkbox.Group>
      <Divider />

      <Typography.Title
        level={5}
        style={{ marginTop: 8, marginBottom: 8, color: "#666" }}
      >
        Colors
      </Typography.Title>
      {colors.map((color, index) => (
        <span
          key={index}
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            display: "inline-block",
            backgroundColor: `${color}`,
            marginRight: 5,
          }}
        ></span>
      ))}
    </Box>
  );
};

export default FilterSidebar;

import React from "react";
import Typography from "@mui/material/Typography";

interface SubHeadingProps {
  label: string;
}

const SubHeading: React.FC<SubHeadingProps> = ({ label }) => {
  return (
    <div className="flex gap-2 items-center mb-2">
      <div className="bg-primary h-8 w-4 rounded"></div>
      <Typography variant="subtitle1" className="text-primary">
        {label}
      </Typography>
    </div>
  );
};

export default SubHeading;
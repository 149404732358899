import React, { useEffect, useState } from "react";
import {
  Chip,
  Typography,
  Container,
  Card,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ArrowForward, ShoppingBag } from "@mui/icons-material";
import Pagination from "../../components/common/pagination";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useGetOrderQuery } from "../../query/orderQuery";
import OrderData from "../../types/order";
import { EyeOutlined } from "@ant-design/icons";
import { useGetCompanyByIdQuery } from "../../query/companyQuery";

const OrderHistoryTable = () => {
  const [pageNo, setPageNo] = useState(1);
  const totalPageNumber = 4;
  const userId = useSelector((state: RootState) => state.auth.userId) ?? "";
  const username = useSelector((state: RootState) => state.auth.username) ?? "";
  const [orderId, setOrderId] = useState(localStorage.getItem("orderId") || 0);

  useEffect(() => {
    localStorage.setItem("orderId", orderId.toString());
  }, [orderId]);

  const { data: orderData } = useGetOrderQuery(
    typeof orderId === "string" ? parseInt(orderId) : orderId
  );

  const userOrders: OrderData[] | undefined = Array.isArray(orderData?.data)
    ? orderData?.data
    : orderData?.data
    ? [orderData?.data]
    : undefined;

  const { data: vendor } = useGetCompanyByIdQuery(1);
  const shopName = vendor?.NameEnglish;
  const vat = vendor?.Pan_Vat;
  const Phone = vendor?.Phone;
  const Email = vendor?.Email;
  const City = vendor?.City;
  const Address = vendor?.Address;

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderData | undefined>(
    undefined
  );
  const calculateTotalAmount = (orderDetails: any) => {
    return orderDetails.reduce(
      (total: any, product: any) => total + product.TotalAmount,
      0
    );
  };
  const handleArrowClick = (order: OrderData | undefined) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <ShoppingBag sx={{ color: "#D84241" }} />
          My Orders
        </Typography>
      </Box>

      {userOrders &&
        userOrders.map((displayOrder) => (
          <Card
            key={displayOrder.Id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 1,
              padding: 1,
              boxShadow: "none",
            }}
          >
            <Typography sx={{ fontSize: "16px", color: "#2b3445" }}>
              #{displayOrder.Id}
            </Typography>
            <Typography>
              <Chip label="pending" variant="outlined" color="warning" />
            </Typography>
            <Typography>{displayOrder.Message}</Typography>
            <Typography>
              {displayOrder.WorkDueNepaliDate.split("T")[0]}
            </Typography>
            <IconButton onClick={() => handleArrowClick(displayOrder)}>
              <EyeOutlined />
            </IconButton>
          </Card>
        ))}

      <Pagination
        page={totalPageNumber}
        setPageNo={setPageNo}
        pageNo={pageNo}
      />

      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xl"
      >
        <DialogContent sx={{ width: "800px" }}>
          {selectedOrder && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">{shopName}</Typography>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <Typography>
                    {Address}, {City}
                  </Typography>
                  <Typography>VAT: {vat}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <Typography>Phone: {Phone}</Typography>
                  <Typography>Email: {Email}</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography>Customer: {username}</Typography>
                  <Typography>Order Id: #{selectedOrder.Id}</Typography>
                </Box>
                <Box>
                  <Typography>
                    Order Date: {selectedOrder.EnglishDate.split("T")[0]}
                  </Typography>
                  <Typography>
                    Expiry Date: {selectedOrder.DueEnglishDate.split("T")[0]}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" sx={{ marginTop: 3 }}>
                Products
              </Typography>
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item ID</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total Amount (rs.)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedOrder.OrderDetails.map((product) => (
                      <TableRow key={product.Id}>
                        <TableCell>{product.ItemId}</TableCell>
                        <TableCell>Product {product.ItemId}</TableCell>
                        <TableCell>{product.Qty}</TableCell>
                        <TableCell>{product.TotalAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Message: {selectedOrder.Message}</Typography>
                <Typography variant="h6">
                  Total Amount: Rs.
                  {calculateTotalAmount(selectedOrder.OrderDetails)}
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </Container>
  );
};

export default OrderHistoryTable;

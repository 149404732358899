import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { ApiProduct } from "../../types/products";

const CartItem: React.FC<{
  product: ApiProduct;
  quantity: number;
  onIncrease: () => void;
  onDecrease: () => void;
  onRemove: () => void;
}> = ({ product, quantity, onIncrease, onDecrease, onRemove }) => {
  const { Name, CostPrice, MenuItemPhotos } = product;
  const firstPhoto = MenuItemPhotos && MenuItemPhotos[0];

  return (
    <div>
      <li className="flex py-2 border-b border-gray-200">
        <div className="h-[60px] w-[60px] flex-shrink-0 overflow-hidden rounded-md border p-[3px] border-gray-200">
          {firstPhoto && (
            <img
              src={`data:${firstPhoto.IdentityFileType};base64, ${firstPhoto.PhoteIdentity}`}
              alt=""
            />
          )}
        </div>
        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between items-center text-base font-medium text-gray-900">
              <h3>{Name}</h3>
              <p className="ml-4 text-sm text-gray-600">Rs. {CostPrice}</p>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <div className="flex">
              <button
                type="button"
                className="p-1 border border-gray-300 w-6 h-6 rounded-md flex justify-center items-center hover:bg-gray-200"
                onClick={onDecrease}
              >
                <span className="sr-only">Reduce quantity</span>
                <RemoveIcon className="text-gray-600"/>
              </button>
              <span className="mx-2 border border-gray-300 rounded-md text-center flex justify-center items-center w-6 h-6">
                {quantity}
              </span>
              <button
                type="button"
                className="p-1 border border-gray-300 w-6 h-6 rounded-md flex justify-center items-center hover:bg-gray-200"
                onClick={onIncrease}
              >
                <span className="sr-only">Increase quantity</span>
                <AddIcon className="text-gray-600"/>
              </button>
            </div>
            <div className="flex text-primary">
              <button
                type="button"
                onClick={onRemove}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </li>
    </div>
  );
};

export default CartItem;

import {
  Box,
  CardMedia,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useGetPurchaseQuery } from "../../query/orderQuery";
import { useGetCompanyByIdQuery } from "../../query/companyQuery";
const OrderDetails = (order: any) => {
  const products = useSelector((state: RootState) => state.products.data);
  const { data: productPurchase } = useGetPurchaseQuery();
  const displayOrder = order.order;
  const displayOrderProduct = order.order.OrderDetails;
  const { data: vendor } = useGetCompanyByIdQuery(1);
  console.log(vendor);
  const totalAmount = displayOrderProduct.reduce(
    (accumulator: any, item: any) => accumulator + item.TotalAmount,
    0
  );
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {vendor && (
          <CardMedia
            component="img"
            alt="Company Logo"
            image={`data:${vendor.IdentityFileType};base64,${vendor.PhotoIdentity}`}
            sx={{
              width: "80px",
            }}
          />
        )}
        <Typography>{vendor?.NameEnglish}</Typography>
        <Typography variant="body2">{vendor?.Address}</Typography>
        <Typography variant="body2">PAN: {vendor?.Pan_Vat}</Typography>
        <Typography variant="body2">Phone: {vendor?.Phone}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              mt: 1,
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              mt: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Order No. :
            </Typography>
            <Typography variant="body2">
              &nbsp;{displayOrder.OrderNumber}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              mt: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Order date :
            </Typography>
            <Typography variant="body2">
              &nbsp;
              {displayOrder.NepaliDate &&
                displayOrder.NepaliDate.substring(0, 10)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Expire date :
            </Typography>
            <Typography variant="body2">
              &nbsp;
              {displayOrder.DueNepaliDate &&
                displayOrder.DueNepaliDate.substring(0, 10)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          mx: "auto",
        }}
      >
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow component="th">
                <TableCell align="left">S.N</TableCell>
                <TableCell colSpan={3} align="center">
                  Product/Service
                </TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="right">Unit Price(Rs.)</TableCell>
                <TableCell align="right">Total(Rs.)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayOrderProduct.map((item: any, i: number) => {
                const product =
                  productPurchase &&
                  productPurchase.find((p: any) => p.Id === item.ItemId);

                return (
                  <>
                    <TableRow component="th">
                      <TableCell align="left">{i + 1}</TableCell>
                      <TableCell colSpan={3} align="center">
                        {product?.Name}
                      </TableCell>
                      <TableCell align="center">{item.Qty}</TableCell>
                      <TableCell align="right">{item.UnitPrice}</TableCell>
                      <TableCell align="right">{item.TotalAmount}</TableCell>
                    </TableRow>
                  </>
                );
              })}

              <TableRow>
                <TableCell colSpan={6} align="left" sx={{ fontWeight: "bold" }}>
                  Total
                </TableCell>
                <TableCell align="right">{totalAmount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          Message : {displayOrder?.Message}
        </Typography>
      </Box>
    </Container>
  );
};

export default OrderDetails;

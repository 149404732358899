import React, { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from "@mui/material";
import {
  Home as HomeIcon,
  Favorite as FavoriteIcon,
  AccountCircle as AccountCircleIcon,
  Shop,
} from "@mui/icons-material";

const MobileBottomNav: React.FC = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const menuItems = [
    { icon: <HomeIcon />, text: "Home", },
    { icon: <Shop />, text: "Products" },
    { icon: <FavoriteIcon />, text: "Favorites" },
    { icon: <AccountCircleIcon />, text: "Profile" },
  ];

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      }}
    >
      {menuItems.map((item, index) => (
        <BottomNavigationAction
          key={index}
          label={item.text}
          icon={item.icon}
        />
      ))}
    </BottomNavigation>
  );
};

export default MobileBottomNav;

import React from "react";
import { Typography, Box } from "@mui/material";
import { Carousel, Button } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner: React.FC = () => {
  const carouselItems = [
    {
      id: 1,
      image:
        "https://similux-furniture.myshopify.com/cdn/shop/files/s-3_8e108c54-2739-42f7-93ad-626a43a3ae4b_2048x.jpg?v=1676449992",
      tagline: "Brand New Electronic Collection",
      buttonText: "Shop Now",
    },
    {
      id: 2,
      image:
        "https://similux-vinovatheme.myshopify.com/cdn/shop/files/s-2-1_3b10ef68-da51-4320-9758-7f3d58ba0a15_2048x.jpg?v=1673401623",
      tagline: "Trendy Footwear Collection",
      buttonText: "Discover More",
    },
    {
      id: 3,
      image:
        "https://miona-vinovatheme.myshopify.com/cdn/shop/files/slideshow-2_3000x.jpg?v=1689148884",
      tagline: "Elegant Accessories",
      buttonText: "Explore Now",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    nextArrow: <div className="slick-next">Next</div>,
    prevArrow: <div className="slick-prev">Prev</div>,
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "70vh",
        overflow: "hidden",
        margin: 0,
        padding: 0,
        position: "relative",
        "& .carousel-item": {
          position: "relative",
          height: "70vh",
        },
        "& img": {
          width: "100vw",
          height: "70vh",
          objectFit: "cover",
        },
        "& .carousel-overlay": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "#fff",
          width: "80%",
        },
        "& .tagline": {
          fontSize: "52px",
          fontWeight: "bold",
          letterSpacing: 6,
        },
        "& .slick-next, & .slick-prev": {
          color: "#fff",
          zIndex: 1,
        },
        "& .slick-prev": {
          left: "10px",
        },
        "& .slick-next": {
          right: "10px",
        },
        "body, html": {
          margin: 0,
          padding: 0,
          width: "100vw",
          height: "100vh",
        },
      }}
    >
      <Carousel {...settings}>
        {carouselItems.map((item) => (
          <div key={item.id} className="carousel-item">
            <img src={item.image} alt={`Carousel ${item.id}`} />
            <Box className="carousel-overlay">
              <Typography className="tagline">{item.tagline}</Typography>
              <Button danger className="action-button" size="large">
                {item.buttonText}
              </Button>
            </Box>
          </div>
        ))}
      </Carousel>
    </Box>
  );
};

export default Banner;

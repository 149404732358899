import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { actions as wishlistActions } from "../../redux/features/wishlistSlice";
import { ApiProduct, MenuItemPhoto } from "../../types/products";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Chip, Container, Dialog } from "@mui/material";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import ProductDetailDialog from "./productDetail";
import Fade from "react-reveal/Fade";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { Link } from "react-router-dom";
import { useGetCategoryQuery } from "../../query/categoryQuery";
import { Tooltip } from "antd";

interface ProductCardProps {
  product: ApiProduct;
  onAddToCart: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, onAddToCart }) => {
  const [isCardHovered, setIsCardHovered] = useState(false);

  const firstPhoto: MenuItemPhoto | undefined = product?.MenuItemPhotos[0];
  const placeholderImage =
    "https://tabletop.events/tte1/img/merchplaceholder.png";
  const { data: categoryData } = useGetCategoryQuery();
  const categoryId = product.categoryId;

  const categoryName = categoryData
    ? categoryData.filter((category) => category.Id === Number(categoryId))
    : [];

  const categoryNameValue =
    categoryName.length > 0 ? categoryName[0].Name : "Loading...";

  const dispatch: AppDispatch = useDispatch();
  const wishlistItems = useSelector((state: RootState) => state.wishlist.items);

  const [isWishlistItem, setIsWishlistItem] = useState(
    wishlistItems.some((item: any) => item.product.Id === product.Id)
  );

  useEffect(() => {
    setIsWishlistItem(
      wishlistItems.some((item: any) => item.product.Id === product.Id)
    );
  }, [wishlistItems, product.Id]);

  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);

  const handleToggleWishlist = () => {
    if (isWishlistItem) {
      dispatch(wishlistActions.removeFromWishlist(product.Id));
    } else {
      dispatch(wishlistActions.addToWishlist(product));
    }
    setIsWishlistItem(!isWishlistItem);
  };

  const handleOpenDetailView = () => {
    setIsDetailViewOpen(true);
  };

  const handleCloseDetailView = () => {
    setIsDetailViewOpen(false);
  };

  return (
    <Fade bottom>
      <Card
        onMouseEnter={() => setIsCardHovered(true)}
        onMouseLeave={() => setIsCardHovered(false)}
        sx={{
          position: "relative",
          width: "fit",
          backgroundColor: "white",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          transition: "transform 0.5s, box-shadow 0.5s",
          "&:hover": {
            transform: "scale(1.01)",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {firstPhoto ? (
          <Link
            to={`/products/${product.Id}`}
            style={{ textDecoration: "none" }}
          >
            <CardMedia
              component="img"
              alt="Product"
              image={`data:${firstPhoto.IdentityFileType};base64, ${firstPhoto.PhoteIdentity}`}
              sx={{
                objectFit: "fit",
                borderRadius: "5px 5px 0 0",
                height: "140px",
              }}
            />
          </Link>
        ) : (
          <Link
            to={`/products/${product.Id}`}
            style={{ textDecoration: "none" }}
            replace
          >
            <CardMedia
              component="img"
              alt="Placeholder"
              image={placeholderImage}
              sx={{
                objectFit: "fit",
                borderRadius: "5px 5px 0 0",
                height: "140px",
              }}
            />
          </Link>
        )}
        {isCardHovered && (
          <Box
            sx={{
              position: "absolute",
              top: 1,
              right: 10,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: "white",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={handleToggleWishlist}
            >
              {isWishlistItem ? (
                <FavoriteIcon
                  sx={{
                    color: "#D94342",
                  }}
                />
              ) : (
                <FavoriteBorderIcon
                  sx={{
                    color: "gray",
                  }}
                />
              )}
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: "white",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={handleOpenDetailView}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
          </Box>
        )}

        <CardContent sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Chip
              size="small"
              label={categoryNameValue}
              variant="outlined"
              color="error"
            />
            <Typography
              variant="body2"
              fontWeight="semibold"
              sx={{
                color: "#7D879C",
                cursor: "auto",
                fontSize: 16,
              }}
            >
              Rs. {product.CostPrice}
            </Typography>
          </Box>
          <Typography
            variant="h6"
            fontWeight="semibold"
            sx={{
              color: "black",
              textTransform: "capitalize",
              fontSize: 16,
              marginTop: 1,
            }}
          >
            {product.Name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>⭐️⭐️⭐️⭐️⭐️</Typography>
            <Tooltip title="Add To Cart">
              <IconButton
                sx={{
                  backgroundColor: "#fff",
                  color: "#D84341",
                  border: "1px solid #D84341",
                  marginLeft: "auto",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
                onClick={onAddToCart}
              >
                <ShoppingBagOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
        <Dialog open={isDetailViewOpen} onClose={handleCloseDetailView}>
          <Container maxWidth="xl">
            <ProductDetailDialog
              open={isDetailViewOpen}
              onClose={handleCloseDetailView}
              product={product}
            />
          </Container>
        </Dialog>
      </Card>
    </Fade>
  );
};

export default ProductCard;

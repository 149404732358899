import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

// Sample data for brands
const brands = [
  {
    id: 2,
    name: "Microsoft",
    logo: "https://adoption.microsoft.com/wp-content/uploads/2022/10/microsoft-logo.png",
  },
  {
    id: 2,
    name: "Microsoft",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/640px-Google_2015_logo.svg.png",
  },
  {
    id: 2,
    name: "Microsoft",
    logo: "https://p7.hiclipart.com/preview/180/516/952/apple-logo-computer-icons-clip-art-iphone-apple.jpg",
  },
  {
    id: 2,
    name: "Microsoft",
    logo: "https://w7.pngwing.com/pngs/639/845/png-transparent-asus-flat-brand-logo-icon.png",
  },
  {
    id: 2,
    name: "Microsoft",
    logo: "https://pngimg.com/d/amazon_PNG13.png",
  },
  {
    id: 2,
    name: "Microsoft",
    logo: "https://adoption.microsoft.com/wp-content/uploads/2022/10/microsoft-logo.png",
  },
];

const BrandSection: React.FC = () => {
  return (
    <Grid container sx={{
        marginY: "35px"
    }}>
      {brands.map((brand) => (
        <Grid item xs={6} md={3} lg={2} key={brand.id}>
          <Paper
            elevation={0}
            sx={{
              textAlign: "center",
              padding: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#f2f2f2",
            }}
          >
            <img
              src={brand.logo}
              alt={brand.name}
              style={{ width: "100%", height: "90px", marginBottom: "8px", objectFit: "contain" }}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default BrandSection;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { fetchProducts } from "../../redux/features/productSlice";
import { actions as cartActions } from "../../redux/features/cartSlice";
import { Box, Grid } from "@mui/material";
import ProductCard from "./productCard";
import Heading from "../../components/common/heading";
import SubHeading from "../../components/common/subHeading";
import { ApiProduct } from "../../types/products";
import { errorMessage } from "../../components/common/messageBox/Messages";

const Products: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const products = useSelector((state: RootState) => state.products.data);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleAddToCart = (product: ApiProduct) => {
    if (product.MenuItemPortions[0].OpeningStock > 0) {
      dispatch(cartActions.addToCart(product));
    } else {
      errorMessage(`"${product.Name}" is out of stock.`);
    }
  };

  return (
    <Box sx={{ mt: 8 }}>
      <SubHeading label="Products" />
      <Heading label="Just For You" />
      <Grid container spacing={2}>
        {products?.map((product, index) => (
          <Grid key={index} item xs={12} sm={4} md={3} lg={2}>
            <ProductCard
              product={product}
              onAddToCart={() => handleAddToCart(product)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Products;

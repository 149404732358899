import React from "react";
import { Grid, Box, Button } from "@mui/material";
import SubHeading from "../../components/common/subHeading";
import Heading from "../../components/common/heading";
import CategoryCard from "./categoryCard";
import { Carousel } from "antd";
import { useGetCategoryQuery } from "../../query/categoryQuery";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const CustomCarousel = styled(Carousel)(({ theme }) => ({
  '& .slick-prev, & .slick-next': {
    color: '#888888', 
    zIndex: 1,
  },
  '& .slick-prev:hover, & .slick-next:hover': {
    color: '#555555', 
  },
}));

const Categories = () => {
  const { data: categories } = useGetCategoryQuery();
  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 8 }}>
      <SubHeading label="Categories" />
      <Grid container justifyContent="space-between" alignItems="center">
        <Heading label="Browse by category" />
        <Grid item>
          <Button
            color="error"
            sx={{ borderRadius: "full" }}
            onClick={() => {
              navigate("/products");
            }}
          >
            View All Products
          </Button>
        </Grid>
      </Grid>

      <CustomCarousel 
        slidesToShow={6}  
        autoplay 
        autoplaySpeed={2000} 
        dots={false} 
        arrows 
      >
        {categories?.map((category, index) => (
          <CategoryCard key={index} category={category} />
        ))}
      </CustomCarousel>
    </Box>
  );
};

export default Categories;
import { useQuery } from "react-query";
import { getBranch, getDepartment, getUnitType, getWarehouse } from "../services/branch";
import { ErrorToast } from "../components/forms/toast";

export const useGetBranchQuery = () =>
  useQuery(["branch"], () => getBranch(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useGetDepartmentQuery = () =>
  useQuery(["department"], () => getDepartment(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

export const useGetWarehouseQuery = () =>
  useQuery(["warehouse"], () => getWarehouse(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

  export const useGetUnitTypeQuery = () =>
  useQuery(["unittype"], () => getUnitType(), {
    onError: (err: { message: string }) => {
      ErrorToast(err.message);
    },
  });

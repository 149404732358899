import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CancelIcon from "@mui/icons-material/Cancel";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { logout } from "../../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../redux/features/cartSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    navigate("/");
    window.location.reload();
  };

  return (
    <Box maxWidth="sm">
      <List>
        <ListItem button>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Manage My Account"
            onClick={() => navigate("/customer-dashboard/profile")}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>

          <ListItemText
            primary="My Order"
            onClick={() => navigate("/customer-dashboard/orders")}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <RateReviewIcon />
          </ListItemIcon>
          <ListItemText primary="My Reviews" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" onClick={handleLogout} />
        </ListItem>
      </List>
    </Box>
  );
};

export default Profile;

import React from "react";
import { Typography, Box, Container, CardMedia } from "@mui/material";
import {
  Facebook,
  Instagram,
  Reddit,
  Twitter,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import { useGetCompanyByIdQuery } from "../../query/companyQuery";
import { Link } from "react-router-dom";

const SITEMAP = [
  {
    title: "Company",
    links: ["About Us", "Careers", "Our Blog", "Store Locations", "Reviews"],
  },
  {
    title: "Shop",
    links: [
      "Game & Video",
      "Phone &Tablets",
      "Computers & Laptop",
      "Sport Watches",
      "Electronics",
    ],
  },
  {
    title: "Support",
    links: ["FAQ", "Reviews", "Contact Us", "Shipping", "Live chat"],
  },
];

const currentYear = new Date().getFullYear();

const Footer = () => {
  const { data: vendor } = useGetCompanyByIdQuery(1);

  return (
    <Box sx={{ backgroundColor: "#212935", py: 3, mt: 10 }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mb: 4,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {vendor && (
                <Link to="/">
                  <CardMedia
                    component="img"
                    alt="Company Logo"
                    image={`data:${vendor.IdentityFileType};base64,${vendor.PhotoIdentity}`}
                    sx={{
                      width: "100px",
                    }}
                  />
                </Link>
              )}
            </Box>
            {SITEMAP.map(({ title, links }, key) => (
              <Box key={key} sx={{ mb: 3, mr: 4 }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  {title}
                </Typography>
                <Box sx={{ listStyleType: "none", m: 0, p: 0 }}>
                  {links.map((link, key) => (
                    <Typography
                      key={key}
                      component="li"
                      color="textSecondary"
                      sx={{
                        display: "block",
                        py: 0.4,
                        color: "#9197a2",
                        transition: "transform 0.3s",
                        "&:hover": { transform: "scale(1.05)" },
                      }}
                    >
                      {link}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              borderTop: "1px solid #E5E7EB",
              pt: 4,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: { xs: "center", md: "space-between" },
              width: "100%",
            }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              mb={{ xs: 2, md: 0 }}
              textAlign={{ xs: "center", md: "left" }}
              display="flex"
              gap="2px"
              sx={{
                color: "#9197a2",
              }}
            >
              &copy; {currentYear}
              <Typography variant="body2" fontWeight="bold">
                D. Cube I.T. Solution Pvt. Ltd.
              </Typography>
              . All Rights Reserved.
            </Typography>
        <Box
              sx={{
                fontSize:14,
                display: "flex",
                gap: 2,
                color: "#ffffff",
                justifyContent: "center",
                "& a": {
                  opacity: 0.8,
                  transition: "opacity 0.3s",
                  "&:hover": { opacity: 1 },
                },
              }}
            >
            <Link to="/Privacy-Policy">
              <Box
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  background: "white",
                  alignItems: "flex-end",
                  color: "#da4443",
                  paddingX: 2,
                  borderRadius: "2px",
                }}
              >
                Privacy Policy
              </Box>
            </Link>              
            </Box>        
            <Box
              sx={{
                display: "flex",
                gap: 2,
                color: "#9197a2",
                justifyContent: "center",
                "& a": {
                  opacity: 0.8,
                  transition: "opacity 0.3s",
                  "&:hover": { opacity: 1 },
                },
              }}
            >
              <Facebook />
              <Instagram />
              <YouTube />
              <Twitter />
              <Reddit />
              <WhatsApp />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

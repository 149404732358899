import React from "react";
import LoginForm from "../../components/forms/auth/loginForm";
import { Container } from "@mui/material";

const LoginContainer: React.FC = () => {
  const handleLogin = (username: string, password: string) => {
  };

  return (
    <Container>
      <LoginForm onLogin={handleLogin} />
    </Container>
  );
};

export default LoginContainer;

// OrderList.tsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Chip,
  IconButton,
  Typography,
  Backdrop,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import OrderData from "../../types/order";
import {
  useGetAllOrderManagementQuery,
  useGetFinancialQuery,
} from "../../query/orderQuery";
import Cookies from "js-cookie";
import { EyeOutlined } from "@ant-design/icons";
import { ShoppingBag } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectIsVendorLoggedIn } from "../../redux/features/vendorSlice";
import Pagination from "../../components/common/pagination";
import OrderDetails from "./OrderDetails";

const OrderList: React.FC = () => {
  const userId = Cookies.get("userId") ?? "";
  const { data: financeYear } = useGetFinancialQuery();
  const isLoggedIn = useSelector(selectIsVendorLoggedIn);

  const startDate = financeYear?.data[0].StartDate || "";
  const endDate = financeYear?.data[0].EndDate || "";
  const year = financeYear?.data[0].Name || "";

  const {
    data: orders,
    isLoading,
    isError,
    refetch,
  } = useGetAllOrderManagementQuery(startDate, endDate, year);
  console.log(orders);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderData | null>(null);

  const handleEyeIconClick = (order: OrderData) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  useEffect(() => {
    refetch();
  }, [userId, refetch, startDate, endDate]);

  if (isError) {
    return <Typography>Error loading orders</Typography>;
  }
  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#2b3445",
            fontWeight: "bold",
          }}
        >
          <ShoppingBag sx={{ color: "#D84241" }} />
          Orders
        </Typography>
      </Box>
      {orders && orders && orders.length > 0 ? (
        <>
          {orders.slice(0, 10).map((displayOrder: OrderData) => (
            <Card
              key={displayOrder.Id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 1,
                padding: 1,
                boxShadow: "none",
              }}
            >
              <Typography sx={{ fontSize: "16px", color: "#2b3445" }}>
                #{displayOrder.Id}
              </Typography>
              <Typography>
                {/* <Chip label="pending" variant="outlined" color="warning" /> */}
                <Typography>{displayOrder.Message}</Typography>
              </Typography>
              {/* <Typography>{displayOrder.Message}</Typography> */}
              <Typography>
                {displayOrder.WorkDueNepaliDate.split("T")[0]}
              </Typography>
              <IconButton color="secondary" sx={{fontSize: "20px"}} onClick={() => handleEyeIconClick(displayOrder)}>
                <EyeOutlined />
              </IconButton>
            </Card>
          ))}
        </>
      ) : (
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 1,
            padding: 1,
            boxShadow: "none",
          }}
        >
          <Typography sx={{ fontSize: "16px", color: "#2b3445" }}>
            No orders found
          </Typography>
        </Card>
      )}

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeAfterTransition
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              {selectedOrder
                ? `Order No: #${selectedOrder.Id}`
                : "No Order Selected"}
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <OrderDetails order={selectedOrder} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsModalOpen(false)}
            color="secondary"
            size="small"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            onClick={handlePrint}
            color="primary"
            size="small"
            variant="outlined"
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderList;

import React from "react";

interface HeadingProps {
  label: string;
}

const Heading: React.FC<HeadingProps> = ({ label }) => {
  return <h1 className="mb-6 text-[30px]">{label}</h1>;
};

export default Heading;
